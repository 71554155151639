import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "flex-direction": "column",
    "border": "1px solid #333",
    "padding": "5px",
    "height": "100%"
  }
};
const _hoisted_2 = {
  key: 0,
  style: {
    "display": "flex",
    "flex-direction": "column",
    "max-width": "8vw",
    "max-height": "20vh",
    "overflow": "scroll scroll",
    "height": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox");

  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString($props.title), 1), $props.multiple ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_checkbox, {
    modelValue: $data.checkAll,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.checkAll = $event),
    indeterminate: $data.part,
    label: "全选",
    disabled: $props.disabled,
    onChange: $options.changeCheckedAll
  }, null, 8, ["modelValue", "indeterminate", "disabled", "onChange"]), _createVNode(_component_el_checkbox_group, {
    modelValue: $data.checked,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.checked = $event),
    style: {
      "display": "flex",
      "flex-direction": "column"
    },
    disabled: $props.disabled,
    onChange: $options.changeChecked
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, _option => {
      return _openBlock(), _createBlock(_component_el_checkbox, {
        label: JSON.stringify(_option),
        style: {
          "margin": "3px 0"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(_option.name), 1)])]),
        _: 2
      }, 1032, ["label"]);
    }), 256))]),
    _: 1
  }, 8, ["modelValue", "disabled", "onChange"])])) : _createCommentVNode("", true)]);
}