import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterPanel2 = _resolveComponent("FilterPanel2");

  const _component_el_row = _resolveComponent("el-row");

  return _openBlock(), _createBlock(_component_el_row, {
    type: "flex",
    justify: "start",
    align: "top",
    style: {
      "padding": "0 8px"
    }
  }, {
    default: _withCtx(() => [$props.productionEnableStatusShow ? (_openBlock(), _createBlock(_component_FilterPanel2, {
      key: 0,
      selected: $data.productionStatusSelected,
      "onUpdate:selected": _cache[0] || (_cache[0] = $event => $data.productionStatusSelected = $event),
      options: $data.productionStatusOptions,
      value: 'key',
      label: "name",
      title: "Product Status"
    }, null, 8, ["selected", "options"])) : _createCommentVNode("", true), $props.typeSelectedShow ? (_openBlock(), _createBlock(_component_FilterPanel2, {
      key: 1,
      selected: $data.typeSelected,
      "onUpdate:selected": _cache[1] || (_cache[1] = $event => $data.typeSelected = $event),
      options: $options.typeOptions,
      label: "name",
      title: "Product Category",
      style: {
        "width": "159px"
      }
    }, null, 8, ["selected", "options"])) : _createCommentVNode("", true), $props.productionArrayShow ? (_openBlock(), _createBlock(_component_FilterPanel2, {
      key: 2,
      selected: $data.productionSelected,
      "onUpdate:selected": _cache[2] || (_cache[2] = $event => $data.productionSelected = $event),
      options: $options.productionOptions,
      label: "name",
      title: "Main Product",
      onCheckAll: $options.checkProductionAll,
      style: {
        "width": "165px"
      }
    }, null, 8, ["selected", "options", "onCheckAll"])) : _createCommentVNode("", true)]),
    _: 1
  });
}