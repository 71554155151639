
module.exports = {
    backServer:()=>{
      return "http://127.0.0.1:8288/"
    },
    storeServer: () => {
      var STORE_SERVER;
      try {
          STORE_SERVER = window.STORE_SERVER;
      } catch (error) {
          STORE_SERVER = process.env.STORE_SERVER;
      }
      return STORE_SERVER;
    },
    ipfsServer:()=>{
      return `http://${process.env.IPFS_SERVER}/ipfs/`
    },
    ipfsShopServer:()=>{
      return `http://${process.env.IPFS_SERVER}/ipns/k51qzi5uqu5dibljz9zcyqhivzhajo5rr59jly3yj8tdocg2lnz7xt63cz9pjl/upload/`
    },
    mobilePath: () => {
      return '/mobileview'
    },
    helpUrl: (isPC) => {
      var STORE_SERVER;
      var FAQ_WEB;
      try {
          STORE_SERVER = window.STORE_SERVER;
          FAQ_WEB = window.FAQ_WEB;
      } catch (error) {
          STORE_SERVER = process.env.STORE_SERVER;
          FAQ_WEB = process.env.FAQ_WEB;
      }
      return isPC ? `${STORE_SERVER}/view/help.html` : `${FAQ_WEB}/m/user/knowledge`
    },
    chatUrl: (isPC) => {
      var STORE_SERVER;
      var FAQ_WEB;
      try {
          STORE_SERVER = window.STORE_SERVER;
          FAQ_WEB = window.FAQ_WEB;
      } catch (error) {
          STORE_SERVER = process.env.STORE_SERVER;
          FAQ_WEB = process.env.FAQ_WEB;
      }
      return isPC ? `${STORE_SERVER}/view/custservi.html` : `${FAQ_WEB}/m/user/chat`
    },
    javaServer: (isPC) => {
      return process.env.JAVA_SERVER
    }
}
