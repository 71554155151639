import templateRadio from './templateRadio.vue';
import templateTextArea from './templateTextArea.vue';
import templateUpload from './templateUpload.vue';
export default {
  name: "templateInit",
  components: {
    templateRadio,
    templateTextArea,
    templateUpload
  },
  props: {
    tempItemContentList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: () => {
        return '';
      }
    },
    languageType: {
      type: String,
      default: () => {
        return '';
      }
    },
    statusName: {
      type: String,
      default: () => {
        return '';
      }
    },
    damageRTextL: {
      type: Object,
      default: () => {
        return {};
      }
    },
    sortTemplateData: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {};
  },

  watch: {},
  computed: {},
  methods: {},

  created() {},

  mounted() {// setTimeout(()=>{
    //     debugger
    //     this.tempItemContentList
    // },3000)
  }

};