export default {
  components: {},
  props: {
    listIndex: {
      type: Number,
      default: null
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: () => {
        return '';
      }
    },
    languageType: {
      type: String,
      default: () => {
        return '';
      }
    },
    statusName: {
      type: String,
      default: () => {
        return '';
      }
    },
    dataInfos: {
      type: Object,
      default: {}
    },
    damageRTextL: {
      type: Object,
      default: () => {
        return {};
      }
    },
    sortTemplateData: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      sortIndex: 'index',
      dataInfo: {},
      answerObj: {}
    };
  },

  watch: {
    'dataInfo.sortIndex': {
      handler(nv, ov) {
        this.sortTemplateData();
      },

      deep: true
    }
  },
  computed: {},
  methods: {
    //根据状态返回不同的class
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    },

    saveData(reView) {
      this.answerObj.id = this.dataInfo.answerVo.id;
      this.answerObj.content = this.dataInfo.content;
      this.dataInfo.answerObj = this.answerObj;
    }

  },

  created() {
    this.dataInfos.sortIndex = 'index';
    this.dataInfos.answerObj = {};
    this.dataInfo = this.dataInfos; // this.sortTemplateData()

    if (this.dataInfo.isEssential) {
      this.dataInfo.isEssential = true;
    } else {
      this.dataInfo.isEssential = false;
    }

    if (this.status) {
      //判断用户是否填写 未填写不必回显
      if (this.dataInfo.answerVo.content && this.dataInfo.answerVo.content != 'null') {
        this.dataInfo.content = this.dataInfo.answerVo.content;
      }
    }
  },

  mounted() {
    //从新填报的时候
    if (this.status == '3' || this.status == '1' && !this.view) {
      this.saveData(true);
    }
  },

  unmounted() {
    delete this.dataInfo.answerObj;
    delete this.dataInfo.sortIndex;
    this.sortTemplateData();
  }

};