import { createRouter, createWebHistory } from 'vue-router'
import adminKnowledge from '../components/admin/KnowledgeBase/Base.vue'
import adminChat from '../components/admin/ChatBase/Base.vue'
import userKnowledge from '../components/user/KnowledgeBase/Base2.vue'
import userChat from '../components/user/ChatBase/Base2.vue'
import moblieUserKnowledge from '../moblieComponents/user/KnowledgeBase/Base.vue'
import moblieUserChat from '../moblieComponents/user/ChatBase/Base.vue'
import Catalogue from '../components/admin/Sop/Control/Catalogue.vue'
import SopAdministration from '../components/admin/Sop/SopAdministration.vue'
import SopForeground from '../components/admin/Sop/SopForeground.vue'
import templateDemoViewHeader from '../control/templateDemoViewHeader.vue'
import templateDemo from '../control/templateDemo.vue'
import templateTicket from '../control/templateTicket.vue'
import templateDemoEdit from '../control/templateDemoEdit.vue'
import templateDemoEditM from '../control/MoblieTemplateDemo/templateDemoEdit.vue'

const routes = [
  {
    path: '/',
    component: moblieUserKnowledge,
    // redirect: '/admin/chat',
    component: userKnowledge
  },
  {
    path: '/admin/knowledge',
    component: adminKnowledge
  },
  {
    path: '/admin/chat',
    component: adminChat
  },
  {
    path: '/user/knowledge',
    component: userKnowledge
  },
  {
    path: '/user/chat',
    component: userChat
  },
  {
    path: '/m/user/knowledge',
    component: moblieUserKnowledge
  },
  {
    path: '/m/user/chat',
    component: moblieUserChat
  },
  { // Sop管理界面
    path: '/admin/Sop/SopAdministration',
    component: SopAdministration
  },
  { // Sop 前台界面
    path: '/admin/Sop/SopForeground',
    component: SopForeground
  },

  ////测试路由

  { 
    path: '/templateDemoViewHeader',
    component: templateDemoViewHeader
  },
  { 
    path: '/templateDemo',
    component: templateDemo
  },
  { 
    path: '/templateTicket',
    component: templateTicket
  },
  { 
    path: '/templateDemoEdit',
    component: templateDemoEdit
  },
  { 
    path: '/m/templateDemo',
    component: templateDemoEditM
  },



    ////测试路由


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
