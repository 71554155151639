import templateUploadPreview from '../control/templateDemeUploadEdit.vue';
import templateRadioPreview from '../control/templateDemeRadioEdit.vue';
import templateTextAreaPreview from '../control/templateDemeTextAreaEdit.vue';
import templateInit from '../control/templateInitAbout/templateInit.vue'; // import { ElMessage } from 'element-plus'

import { ElNotification } from 'element-plus';
export default {
  components: {
    templateUploadPreview,
    templateRadioPreview,
    templateTextAreaPreview,
    templateInit
  },
  props: {
    // templateDemeData: {
    //     type: Object,
    //     default: () => {
    //         return {}
    //     }
    // },
    dpt: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userView: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    // damageReportType: {
    //     type: Array,
    //     default: ()=>{
    //         return []
    //     }
    // },
    close: {
      types: Function,
      default: () => {}
    },
    languageDir: {
      type: Array,
      default: () => {
        return [];
      }
    },
    functionTemple: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      sortTimer: null,
      templateIndexBase: 1,
      //预设填报提示信息
      damageRTextL: {},
      damageReportDir: [],
      checkConclusionDir: [],
      warrantyTypeDir: [],
      purchasingDir: [],
      userIsView: false,
      submitForm: []
    };
  },

  watch: {},
  computed: {},
  methods: {
    //时间戳转化为 年月日 时分秒 格式化
    dataTimeFormatter(timeStamp) {
      if (timeStamp === 'null' || !timeStamp) {
        return 'null';
      }

      timeStamp = Number(timeStamp);
      let time = new Date(timeStamp);
      let y = time.getFullYear(); // 年份

      let M = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      ; // 月份（注意月份是从0开始的，所以需要+1）

      let d = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate(); // 日期

      let h = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours(); // 小时

      let m = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes(); // 分钟

      let s = time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds(); // 秒数

      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },

    //[FAQ]报损单表单
    async damageR(params) {
      const res = await this.$axios.post('/javaplus/user/form/damage', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //获取数据字典
    async getDictListByDictType(params) {
      const res = await this.$axios.post('/api/user/damageReport/getDictListByDictType', params);

      if (res.data.errno != 0) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //根据status返回不一样的文本
    getByStatus(status, dir, type) {
      for (let d of dir) {
        if (status == d.dictValue) {
          return d[`${type}`];
        }
      }
    },

    //根据status返回不一样的文本
    getTextByStatus(status) {
      if (status == '1') {
        return 'Waiting to Fill Out';
      } else if (status == '2') {
        return 'Under Review';
      } else if (status == '3') {
        return 'Waiting for Additional Information';
      } else if (status == '4') {
        return 'Review Completed';
      } else if (status == '5') {
        return 'Voided';
      }
    },

    async addUserFistEntryTime(params) {
      const res = await this.$axios.post('/api/user/damageReport/addUserFistEntryTime', params);

      if (res.data.errno != 0) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //teleport 的剪辑报损单填报项目传送
    teleport() {
      // this.$refs.ticketFormHeader
      // console.log(this.$refs.ticketFormBody.scrollHeight-this.$refs.ticketFormHeader.scrollHeight)
      // console.log(this.$refs.ticketFormHeader.scrollHeight)
      // console.log(this.$refs.ticketFormHeader.scrollTop)
      this.$nextTick(() => {
        // let h = this.$refs.ticketFormBody.scrollHeight-this.$refs.ticketFormHeader.scrollHeight
        this.$refs.ticketFormBody.scrollTop = this.$refs.ticketFormHeader.scrollHeight; // debugger
      }); // this.this.$refs.ticketFormBody.scrollTop = this.$refs.ticketFormBody.scrollHeight-this.$refs.ticketFormHeader.scrollHeight
      // this.$refs.dptEdit.scrollIntoView({
      //     behavior: 'smooth',  // 平滑滚动
      //     block: 'start'       // 滚动到元素的顶部
      // })
    },

    ///[SHOP]填写报损单问题答案
    async editDamageReport(params) {
      const res = await this.$axios.post('/api/user/damageReport/editDamageReport', params);

      if (res.data.errno != 0) {
        ElNotification({
          // title: 'error',
          message: res.data.errmsg,
          type: 'error'
        });
      } else {
        this.functionTemple.returnPreView(); // ElNotification({
        //     title: 'success',
        //     message: "Submission successful",
        //     type: 'success',
        // });
      }

      return res;
    },

    //客服查看完报损单的返回事件
    returnPreView() {
      this.functionTemple.returnPreView();
    },

    // // 添加报损单
    // async addDamageReport(params) {
    //     const res = await this.$axios.post('/java/afterSale/damageReport/addDamageReport', params)
    //     if (res.data.code != 200) {
    //         ElNotification({
    //             title: '错误',
    //             message: res.data.message,
    //             type: 'error',
    //         });
    //     }
    //     return res
    // },
    cancel() {
      this.functionTemple.returnPreView();
    },

    nextStep() {
      // console.log(this.submitForm, 'submitFormsubmitForm');
      this.$nextTick(() => {
        let formIndex = 0; // debugger
        // for (let i of this.submitForm) {
        //     if (!this.submitForm[formIndex]) {
        //         if (Number(this.dpt.tempItemContentList[formIndex].questionType) < 20) {
        //             this.$message.error(`${this._t('请选择')}` + this.dpt.tempItemContentList[formIndex].title)
        //             return
        //         } else if (Number(this.dpt.tempItemContentList[formIndex].questionType) < 30) {
        //             this.$message.error(this._t('请输入') + this.dpt.tempItemContentList[formIndex].title)
        //             return
        //         } else if (Number(this.dpt.tempItemContentList[formIndex].questionType) < 40) {
        //             this.$message.error(this._t('请') + this._t(`${this.dpt.tempItemContentList[formIndex].questionTypeName}`) + this.dpt.tempItemContentList[formIndex].itemList[0].name)
        //             return
        //         }
        //     } else {
        //         if (30 << Number(this.dpt.tempItemContentList[formIndex].questionType) < 40) {
        //             let list_1 = JSON.parse(this.submitForm[formIndex].url).split(';').slice(0, -1)
        //             for (let item of this.dpt.tempItemContentList[formIndex].itemList) {
        //                 for (let urlItem of list_1) {
        //                     let urlId = urlItem.split(':')
        //                     if (urlId[1] == item.id) {
        //                         if (urlId[0]) {
        //                         } else {
        //                             this.$message.error(this._t('请') + this._t(`${this.dpt.tempItemContentList[formIndex].questionTypeName}`) + item.name)
        //                             return
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     formIndex++
        // }
        // this.functionTemple.returnPreView()

        this.submitForm = [];
        this.findTemplateData(this.dpt.tempItemContentList, this.getTemplateData); // let index = 0;
        // this.findTemplateData(this.dpt.tempItemContentList, (i) => {
        //     if (i.answerObj) {
        //         i.sortIndex = index++;
        //     }
        // })

        const obj = {
          data: this.submitForm,
          timestamp: Date.now()
        };
        this.editDamageReport(obj).then(res => {});
      });
    },

    getTemplateData(i) {
      if (i.answerObj) {
        this.submitForm.push(i.answerObj);
      }
    },

    sortTemplateData() {
      clearTimeout(this.sortTimer);
      this.sortTimer = setTimeout(() => {
        this.templateIndexBase = 1;
        this.findTemplateData(this.dpt.tempItemContentList, i => {
          if (i.sortIndex) {
            i.sortIndex = this.templateIndexBase++;
            return i.sortIndex;
          }
        });
      }, 100);
    },

    findTemplateData(List, func) {
      for (let i of List) {
        func(i);

        if (i.questionType == '11' || i.questionType == '12') {
          for (let x of i.itemList) {
            if (x.children.length) {
              this.findTemplateData(x.children, func);
            }
          }
        }
      }
    },

    closeDialog() {
      this.close();
    }

  },

  async created() {
    this.submitForm = new Array(this.dpt.tempItemContentList?.length);
    this.userIsView = this.userView; // this.getDictListByDictType({ dictType: 'faq_purchasing_channels' }).then((res) => {
    //     this.purchasingDir = res.data.data
    // })
    // this.getDictListByDictType({ dictType: 'faq_check_conclusion_status' }).then((res) => {
    //     this.checkConclusionDir = res.data.data
    // })
    // this.getDictListByDictType({ dictType: 'faq_damage_report_status' }).then((res) => {
    //     this.damageReportDir = res.data.data
    // })
    // this.getDictListByDictType({ dictType: 'faq_warranty_type' }).then((res) => {
    //     this.warrantyTypeDir = res.data.data
    // })
    // this.$eventBus.on('saveData', (data) => {
    //     console.log(this.submitForm,data, 'this.submitForm');
    //     if (data.clear) {
    //         this.submitForm[data.index] = null
    //         // console.log('nullll');
    //     } else {
    //         this.submitForm[data.index] = data.answerObj
    //         // console.log(this.submitForm, 'this.submitForm');
    //     }
    // })
    // debugger

    await this.damageR({
      languageType: this.dpt.languageType
    }).then(res => {
      this.damageRTextL = res.data.data;
    });
  },

  mounted() {
    //[SHOP]添加用户首次进入时间
    // console.log(this.dpt,'dddd');
    if (this.dpt.status == '1') {
      this.addUserFistEntryTime({
        id: this.dpt.id
      });
    } // console.log(this.functionTemple,'2323');
    // this.$eventBus.on('saveData', (data) => {
    //     this.submitForm[data.index] = data.answerObj   
    // })
    // 

  },

  beforeDestroy() {// this.$eventBus.off('saveData')
    // console.log('2');
  }

};