import { mapGetters, mapState } from "vuex";
export default {
  props: {
    questionRecordSelected: Object,
    scoreSelected: Object,
    scoreOption: Array,
    updateTicketList: Function,
    show: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:show"],

  data() {
    return {
      scoreData_new: {
        recordQuestionId: '',
        suggest: '',
        scoreRelationDTOList: []
      },
      scoreRelationList: [],
      scorePanel: {
        show: false,
        level: 0,
        oldLevel: 0,
        suggest: "",
        suggestChecked: false,
        optionChecked: []
      }
    };
  },

  computed: {},
  watch: {
    show: {
      handler(nv, ov) {
        this.scorePanel.show = nv;
      },

      immediate: true
    },
    scoreSelected: {
      handler(nv, ov) {
        if (!nv) {
          return;
        }

        this.scorePanel.level = nv.level;
        this.scorePanel.suggest = nv.suggest;
        this.scorePanel.optionChecked = nv.scoreOptionIdArray;
      },

      immediate: true
    }
  },
  components: {},
  computed: { ...mapState({
      questionRecordId: 'questionRecordId'
    }),
    ...mapGetters({
      score: "getScore"
    }),

    scoreActive() {
      let scoreActive = {};

      for (const score of this.score || []) {
        if (score.level === this.scorePanel.level) {
          scoreActive = score;
        }
      }

      return scoreActive;
    },

    scoreOptionActive() {
      let optionContentArray = [];

      for (let option of this.scoreActive.scoreOption || []) {
        if (this.scorePanel.optionChecked.indexOf(option.id) >= 0) {
          optionContentArray.push(option.content);
        }
      }

      return optionContentArray.join();
    }

  },

  mounted() {
    for (let score of this.scoreOption) {
      score.level = 5;
    } // setTimeout(()=>{
    //   console.log(this.scoreOption,'scoreOption')
    // },1000)

  },

  methods: {
    close() {
      this.scorePanel.show = false;
      this.$emit("update:show", this.scorePanel.show);
    },

    clickScore() {
      this.$nextTick(() => {// if (this.scorePanel.level === this.scorePanel.oldLevel) {
        //   this.scorePanel.level = 0;
        // }
        // this.scorePanel.oldLevel = this.scorePanel.level;
        // console.log(this.scoreOption,'scoreOptionscoreOption');
      });
    },

    // 用户取消评价
    async clickChatAgain() {
      if (this.questionRecordSelected.request_score == 1) {} else {// this.updateIsSolvedById({ id: this.questionRecordSelected.id, isSolved: 0})
        // this.cancelScore(this.questionRecordSelected.id)
      } // const res = this.$axios.post('/java/front/usersendFAQAppraise', {
      //   questionRecordId: this.questionRecordSelected.id,
      //   status: 1
      // })
      // console.log('用户取消',res);


      this.close();
    },

    changeQuestionRecord(questionId, questionRecordId, change) {
      this.$axios.post("/api/user/KnowledgeBase/changeQuestionRecord", {
        id: questionRecordId,
        question_id: questionId,
        change: change
      });
    },

    async clickSubmitScore() {
      // console.log(this.questionRecordSelected, 'questionRecordSelected')
      if (this.questionRecordSelected.request_score == 1) {
        await this.getScoreQuestionRecord(this.questionRecordSelected.id).then(res => {
          this.scoreData_new = res.data.data;
          this.scoreData_new.scoreRelationDTOList = this.scoreData_new.scoreRelationList;
          delete this.scoreData_new.scoreRelationList;
        });
      } else {
        for (let score of this.scoreOption) {
          let obj = {
            scoreQuestionOptionId: score.id,
            score: 0
          };
          this.scoreData_new.scoreRelationDTOList.push(obj);
        }

        this.scoreData_new.recordQuestionId = this.questionRecordSelected.id;
        this.scoreData_new.customerId = this.questionRecordSelected.customer_id;
      } //放入建议


      this.scoreData_new.suggest = this.scorePanel.suggest; //放入评分
      // console.log(this.scoreOption, 'this.scoreData_new.suggest');
      // console.log(this.scoreData_new, 'this.scoreData_new.suggest');

      for (let score of this.scoreOption) {
        for (let sList of this.scoreData_new.scoreRelationDTOList) {
          if (score.id == sList.scoreQuestionOptionId) {
            if (score.level) {
              sList.score = score.level;
            } else {
              sList.score = 0;
            }

            break;
          }
        }
      } //提交评价


      this.updateScoreQuestionRecord(this.scoreData_new).then(() => {
        this.updateIsSolvedById({
          id: this.questionRecordSelected.id,
          isSolved: 1
        });
        this.updateTicketList();
      }); // console.log(this.scoreData_new, 'this.scoreData_new.suggest');
      // // 提交评分
      // this.addScore();
      // // 问题已解决
      // this.questionRecordSelected.is_solved = 1;
      // this.changeQuestionRecord(
      //   this.questionRecordSelected.question_id,
      //   this.questionRecordSelected.id,
      //   {
      //     is_solved: this.questionRecordSelected.is_solved,
      //   }
      // );
      // 用户提交评价
      // const res = this.$axios.post('/java/front/usersendFAQAppraise', {
      //   questionRecordId: this.questionRecordSelected.id,
      //   scoreId: this.scorePanel.level,
      //   scoreOptionIdArray: [],
      //   suggest: this.scorePanel.suggest,
      //   status: 0
      // })
      // 关闭评分面板

      this.close();
    },

    async addScore() {
      const res = await this.$axios.post("/api/user/ChatBase/addScore", {
        question_record_id: this.questionRecordSelected.id,
        score_id: this.scoreActive.id,
        score_option_id_array: this.scorePanel.optionChecked,
        suggest: this.scorePanel.suggest
      });

      if (res.status !== 200 || res.data.errno) {
        ElNotification({
          title: "Error",
          type: "error",
          message: "Some reason cause the failure of submission, please try again!"
        });
      }

      this.scorePanel.level = 0;
      this.scorePanel.oldLevel = 0;
      this.scorePanel.suggest = "";
      this.scorePanel.suggestChecked = false;
      this.scorePanel.optionChecked = [];
      ElNotification({
        title: this._t("successful"),
        type: "success",
        message: this._t("submitSuccessful")
      });
    },

    async getScoreQuestionRecord(id) {
      const res = await this.$axios.post(`/javaplus/user/scoreQuestionRecord/getScoreQuestionRecord/${id}`);

      if (res.data.code != 200) {
        this.notify({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //修改评价数据(评价提交)
    async updateScoreQuestionRecord(params) {
      const res = await this.$axios.post('/javaplus/user/scoreQuestionRecord/updateScoreQuestionRecord', params);

      if (res.data.code != 200) {
        this.notify({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //取消工单评分
    async cancelScore(params) {
      const res = await this.$axios.post('/javaplus/user/scoreQuestionRecord/cancelScore/' + params);

      if (res.data.code != 200) {
        this.notify({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //根据工单id更新工单是否已解决
    async updateIsSolvedById(params) {
      const res = await this.$axios.post('/javaplus/user/recordQuestion/updateIsSolvedById', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    }

  },

  unmounted() {
    for (let score of this.scoreOption) {
      score.level = 0;
    }
  }

};