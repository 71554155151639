import templateStatusShow from './templateStatusShow.vue';
export default {
  components: {
    templateStatusShow
  },
  props: {
    isCheckBox: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: () => {
        return '';
      }
    },
    languageType: {
      type: String,
      default: () => {
        return '';
      }
    },
    statusName: {
      type: String,
      default: () => {
        return '';
      }
    },
    listIndex: {
      type: Number,
      default: null
    },
    dataInfos: {
      type: Object,
      default: {}
    },
    damageRTextL: {
      type: Object,
      default: () => {
        return {};
      }
    },
    sortTemplateData: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      sortIndex: 'index',
      selectsIdList: [],
      childreComponentList: [],
      //单选
      answerImgView: [],
      //多选
      answerArrayCheckBox: {},
      answerArray: [],
      //用户是否填写
      select: '',
      selects: {},
      imgArray: {},
      idString: '',
      selectsIndex: [],
      selectIndex: null,
      dataInfo: {},
      answerObj: {
        id: null,
        contentItemId: "",
        url: "",
        content: ""
      },
      itemListObj: {}
    };
  },

  watch: {
    'dataInfo.sortIndex': {
      handler(nv, ov) {
        this.sortTemplateData();
      },

      deep: true
    } // dataInfo:{
    //     handler(nvl){
    //         nvl= JSON.parse(JSON.stringify(nvl))
    //         clearTimeout(this.timer)
    //         this.timer=setTimeout(()=>{
    //             this.$event.emit('saveEditData',nvl,this.dpt.name,this.listIndex-1)
    //         },500)
    //     },
    //     deep:true
    // }

  },
  computed: {},

  created() {
    this.dataInfos.sortIndex = 'index';
    this.dataInfos.answerObj = {};
    this.dataInfo = this.dataInfos; // this.sortTemplateData()

    if (this.dataInfo.isEssential) {
      this.dataInfo.isEssential = true;
    } else {
      this.dataInfo.isEssential = false;
    }

    this.dataInfo.checkList = [];
    this.dataInfo.radio = null;

    for (let i of this.dataInfo.itemList) {
      this.imgArray[i.id] = [];

      for (let imgItems of i.damageReportContentItemImageList) {
        this.imgArray[i.id].push(this.replacePath(imgItems.imageUrl));
      }
    }

    this.initItemListObj(this.dataInfo.itemList, this.itemListObj); // if (!this.dataInfo.itemList.length) {
    //     let firstObj = {
    //         optionName: ''
    //     }
    //     this.dataInfo.itemList.push(firstObj)
    // }

    if (this.status) {
      if (this.dataInfo.answerVo.contentItemId && this.dataInfo.answerVo.contentItemId != 'null') {
        if (this.isCheckBox) {
          let selList = JSON.parse(this.dataInfo.answerVo.contentItemId).split(';').slice(0, -1);

          for (let i of selList) {
            for (let x in this.dataInfo.itemList) {
              if (Number(i) === this.dataInfo.itemList[x].id) {
                // let obj = {
                //     optionName: this.dataInfo.itemList[x].optionName
                // }
                this.selectsIndex[x] = true;
                this.selectsIdList.push(this.dataInfo.itemList[x].id); // this.answerImgView.push(this.dataInfo.itemList[x].damageReportContentItemImageList)

                this.selects[this.dataInfo.itemList[x].optionName] = this.dataInfo.itemList[x].damageReportContentItemImageList;

                for (let y of this.dataInfo.itemList) {
                  this.answerArrayCheckBox[y.optionName] = [];

                  for (let z of y.damageReportContentItemImageList) {
                    this.answerArrayCheckBox[y.optionName].push(this.replacePath(z.imageUrl));
                  }
                }

                break;
              }
            }
          }
        } else {
          for (let i of this.dataInfo.itemList) {
            if (this.dataInfo.answerVo.contentItemId === String(i.id)) {
              this.select = i.optionName;
              this.selectsIdList[0] = i.id;
              this.selectIndex = i.id; // for(let i of i.damageReportContentItemImageList){
              //     i.imageUrl = this.replacePath(i.imageUrl)
              // }

              this.answerImgView = _.cloneDeep(i.damageReportContentItemImageList);

              for (let i of this.answerImgView) {
                i.imageUrl = this.replacePath(i.imageUrl);
                this.answerArray.push(i.imageUrl);
              } // for (let i of this.answerImgView) {
              //     this.answerArray.push(this.replacePath(i.imageUrl))
              // }


              return;
            }
          }
        }
      }
    } // if (!this.isAdmin) {
    //     if (this.isView) {
    //         if (this.dataInfo.answerVo.contentItemId && this.dataInfo.answerVo.contentItemId != 'null') {
    //             if (this.isCheckBox) {
    //                 let selList = JSON.parse(this.dataInfo.answerVo.contentItemId).split(';').slice(0, -1)
    //                 for (let i of selList) {
    //                     for (let x in this.dataInfo.itemList) {
    //                         if (Number(i) === this.dataInfo.itemList[x].id) {
    //                             let obj = {
    //                                 optionName: this.dataInfo.itemList[x].optionName
    //                             }
    //                             this.selects.push(obj)
    //                             break;
    //                         }
    //                     }
    //                 }
    //                 // console.log(this.selectsIndex, 'selList111');
    //             } else {
    //                 for (let i of this.dataInfo.itemList) {
    //                     if (this.dataInfo.answerVo.contentItemId === String(i.id)) {
    //                         this.select = i.optionName
    //                         // this.selectDamageReportContentItemImageList = i.selectDamageReportContentItemImageList
    //                         // console.log(this.selectIndex, 'this.selectIndex');
    //                         return
    //                     }
    //                 }
    //                 // console.log(this.selectIndex, 'selList222');
    //                 // this.dpt.tempItemContentList[this.listIndex - 1].answerVo.contentItemId
    //             }
    //             // console.log('23333');
    //         }
    //     } else {
    //         //判断用户是否填写 未填写不必回显
    //         if (this.dataInfo.answerVo.contentItemId && this.dataInfo.answerVo.contentItemId != 'null') {
    //             if (this.isCheckBox) {
    //                 let selList = JSON.parse(this.dataInfo.answerVo.contentItemId).split(';').slice(0, -1)
    //                 for (let i of selList) {
    //                     for (let x in this.dataInfo.itemList) {
    //                         if (Number(i) === this.dataInfo.itemList[x].id) {
    //                             this.selectsIndex[x] = true
    //                             break;
    //                         }
    //                     }
    //                 }
    //                 // console.log(selectsIndex, 'selList111');
    //             } else {
    //                 for (let i of this.dataInfo.itemList) {
    //                     if (this.dataInfo.answerVo.contentItemId === String(i.id)) {
    //                         this.selectIndex = i.id
    //                         // console.log(this.selectIndex, 'this.selectIndex');
    //                         return
    //                     }
    //                 }
    //                 // console.log(selectIndex, 'selList222');
    //                 // this.dpt.tempItemContentList[this.listIndex - 1].answerVo.contentItemId
    //             }
    //         }
    //     }
    // }

  },

  beforeUpdate() {// }
  },

  unmounted() {
    delete this.dataInfo.answerObj;
    delete this.dataInfo.sortIndex;
    this.sortTemplateData();
  },

  mounted() {
    if (this.status == '3' || this.status == '1' && !this.isView) {
      this.autoSave();
    }
  },

  methods: {
    //根据状态返回不同的class
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    },

    replacePath(path) {
      return path.replace('/ipfs/', '/static/');
    },

    //点击选择选项
    clickOption(index, item) {
      //判断是否有关联题
      // if (item.children.length) {
      //     for(let i of this.childreComponentList){
      //     }
      //     this.childreComponentList.push(item.children)
      // }
      //判断是否有关联题
      // if (this.isView) {
      if (this.isCheckBox) {
        this.idString = '';
        this.selectsIdList = [];
        this.selectsIndex[index] = !this.selectsIndex[index];

        for (let i in this.selectsIndex) {
          if (this.selectsIndex[i]) {
            this.idString += this.dataInfo.itemList[i].id + ';'; // if(this.dataInfo.itemList[i].id=='2451'){
            //     debugger
            // }
            // this.selectsIdList[i] = this.dataInfo.itemList[i].id

            this.selectsIdList.push(this.dataInfo.itemList[i].id);
          }
        }

        if (this.idString) {
          this.answerObj.id = this.dataInfo.answerVo.id;
          this.answerObj.contentItemId = JSON.stringify(this.idString);
          this.dataInfo.answerObj = this.answerObj;
        } else {
          //10.9 更改
          // this.submitForm[this.listIndex - 1] = null
          this.answerObj.contentItemId = JSON.stringify(this.idString);
          this.dataInfo.answerObj = this.answerObj;
        }
      } else {
        this.selectsIdList[0] = item.id;
        this.selectIndex = item.id;
        this.answerObj.id = this.dataInfo.answerVo.id;
        this.answerObj.contentItemId = this.selectIndex;
        this.dataInfo.answerObj = this.answerObj; ///单选判断关联题

        this.childreComponentList = [];

        if (item.children.length) {
          this.childreComponentList.push(item.children);
        } // console.log(this.submitForm,'this.submitForm');
        // this.$eventBus.emit('saveData', { answerObj: this.answerObj, index: this.listIndex - 1 })
        // this.$emit('update:submitForm',)

      } // }

    },

    //重新填报进来 先回显答案 然后自动存储一次答案到总页面
    autoSave() {
      if (this.isCheckBox) {
        this.idString = '';

        for (let i in this.selectsIndex) {
          if (this.selectsIndex[i]) {
            this.idString += this.dataInfo.itemList[i].id + ';';
          }
        }

        this.answerObj.id = this.dataInfo.answerVo.id;
        this.answerObj.contentItemId = JSON.stringify(this.idString); // this.$eventBus.emit('saveData', { answerObj: this.answerObj, index: this.listIndex - 1 })

        this.dataInfo.answerObj = this.answerObj;
      } else {
        this.answerObj.id = this.dataInfo.answerVo.id;
        this.answerObj.contentItemId = this.selectIndex; // this.$eventBus.emit('saveData', { answerObj: this.answerObj, index: this.listIndex - 1 })

        this.dataInfo.answerObj = this.answerObj;
      } // console.log('emitemitemit');

    },

    initItemListObj(List, obj) {
      for (let i of List) {
        // if(i.id=='2451'){
        //     debugger
        // }
        obj[i.id] = i;
      }
    }

  }
};