import FilterPanel2 from './FilterPanel.vue';
export default {
  name: 'ProductionFilter',
  components: {
    FilterPanel2
  },
  props: {
    baseUrl: {
      type: String,
      required: false,

      default() {
        return '';
      }

    },
    typeAll: {
      type: Boolean,

      default() {
        return false;
      }

    },
    productionAll: {
      type: Boolean,

      default() {
        return false;
      }

    },
    showAll: {
      type: Boolean,

      default() {
        return false;
      }

    },
    productionCount: {
      type: Object,

      default() {
        return undefined;
      }

    },
    productionEnableStatusShow: {
      type: [Boolean, Number],
      default: true
    },
    typeSelectedShow: {
      type: [Boolean, Number],
      default: true
    },
    productionArrayShow: {
      type: [Boolean, Number],
      default: true
    },
    productionRelationArrayShow: {
      type: [Boolean, Number],
      default: true
    },
    planBatchNumberShow: {
      type: Boolean,

      default() {
        return false;
      }

    },
    planBatchNumberSelected: {
      type: String,
      required: false,
      default: ''
    },
    dateShow: {
      type: Boolean,

      default() {
        return false;
      }

    },
    dateSelected: {
      type: Date,
      required: false,
      default: null
    },
    isContract: {
      type: Boolean,
      required: false,
      default: false
    },
    isWorkType: {
      type: Boolean,
      required: false,
      default: false
    },
    productSelection: {
      // 默认选中产品项
      type: Array,
      required: false,

      default() {
        return [];
      }

    },
    productSelectionAndType: {
      // 默认选中产品项的id 会同时勾选类型
      type: Array,
      required: false,
      default: []
    },
    localStorageKey: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      typeSelected: [],
      productionSelected: [],
      showSelected: [],
      productionStatusSelected: [1],
      planBatchNumberSelectedBk: undefined,
      dateSelectedBk: undefined,
      emitTimer: {},
      productionStatusOptions: [{
        key: 1,
        name: '启用'
      }, {
        key: 0,
        name: '停用'
      }],
      contractTypeOptions: ['外协合同', '外协加工包料合同', '采购合同', '返修合同', '赔偿合同', '非正常合同'],
      contractTypeSelected: ['外协合同', '外协加工包料合同', '采购合同', '返修合同', '赔偿合同'],
      workTimeTypeOptions: ['内部', '外协'],
      workTimeTypeSelected: ['内部', '外协'],
      updateTimer: {},
      productionType: [],
      productionById: {},
      productionChildById: {},
      checkProductAll: undefined
    };
  },

  computed: {
    typeOptions() {
      const options = [];

      for (const _type of this.productionType) {
        options.push(_type);
      }

      return options;
    },

    productionOptions() {
      const options = [];

      for (const type of this.typeSelected) {
        for (const id in this.productionById) {
          const production = this.productionById[id];

          if (production.type == JSON.parse(type).name) {
            if (this.productionStatusSelected.indexOf(this.productionById[id].enable) != -1) {
              options.push(production);
            }
          }
        }
      }

      options.sort((a, b) => {
        return a.name.localeCompare(b.name, 'zh');
      });
      return _.uniqBy(options);
    },

    showOptions() {
      const options = [];

      for (const production of this.productionSelected) {
        if (this.productionChildById[production.id] !== undefined) {
          for (const cid of this.productionChildById[production.id]) {
            if (options.findIndex(option => {
              return option.id == cid;
            }) < 0) {
              if (this.productionById[cid] && this.productionStatusSelected.indexOf(this.productionById[cid].enable) != -1) {
                options.push(this.productionById[cid]);
              }
            }
          }
        }
      }

      options.sort((a, b) => {
        return a.name.localeCompare(b.name, 'zh');
      });
      return options;
    },

    changeValue() {
      const value = {};
      value.typeSelected = _.cloneDeep(this.typeSelected);
      value.productionSelected = _.cloneDeep(_.map(this.productionSelected.map(item => JSON.parse(item)), 'id'));
      value.showSelected = _.cloneDeep(this.showSelected);
      value.productionStatusSelected = _.cloneDeep(this.productionStatusSelected);
      value.contractTypeSelected = _.cloneDeep(this.contractTypeSelected);
      value.workTimeTypeSelected = _.cloneDeep(this.workTimeTypeSelected);
      return value;
    }

  },
  watch: {
    productSelectionAndType: {
      handler(nv, ov) {
        if (!_.isEqual(nv, ov)) {
          this.initAutoSelect();
        }
      },

      deep: true
    },
    typeOptions: {
      handler(nv, ov) {
        // debugger
        if (this.typeAll) {
          this.typeSelected = nv;
        } // if (_.isEmpty(this.typeSelected)) {
        //     for (let Category of this.CategorySelection) {
        //         for (const option of this.typeOptions) {
        //               if (option.name == Category) {
        //                 this.typeSelected.push(option)
        //                 break
        //               }
        //         }
        //     }
        // }

      }

    },
    productionOptions: {
      handler(nv, ov) {
        if (this.productionAll) {
          this.productionSelected = nv;
        }
      }

    },
    showOptions: {
      handler(nv, ov) {
        if (this.showAll) {
          this.showSelected = nv;
        }
      }

    },
    typeSelected: {
      handler(nv, ov) {
        this.emitChange();
      },

      deep: true,
      immediate: true
    },
    productionSelected: {
      handler(nv, ov) {
        if (!_.isEqual(_.map(nv, 'id'), _.map(ov, 'id'))) {
          this.$emit('update:productSelectionAndType', _.map(nv.map(item => JSON.parse(item)), 'id'));
        }
      },

      immediate: false
    },
    showSelected: {
      handler(nv, ov) {
        this.emitChange();
      },

      immediate: true
    },
    productionStatusSelected: {
      handler(nv, ov) {
        this.emitChange();
      }

    },
    contractTypeSelected: {
      handler(nv, ov) {
        this.emitChange();
      }

    },
    workTimeTypeSelected: {
      handler(nv, ov) {
        this.emitChange();
      }

    },

    planBatchNumberSelectedBk(val) {
      this.$emit('update:planBatchNumberSelected', val);
    },

    dateSelectedBk(val) {
      this.$emit('update:dateSelected', val);
    },

    checkProductAll(val) {
      this.$emit('checkProductAll', val);
    },

    productionCount: {
      handler(nv, ov) {
        for (const id in this.productionById) {
          const production = this.productionById[id];
          production.$count = this.productionCount[id] || 0;
        }
      },

      deep: true
    }
  },

  async mounted() {
    // setInterval(()=>{
    //   debugger
    //   this.typeSelected
    // },5000)
    // 获取基本信息
    await this.update();
  },

  methods: {
    initAutoSelect() {
      if (this.productionById.length) {
        let productSelectList = [];

        for (let i of this.productSelectionAndType) {
          for (let x of this.productionById) {
            if (i == x.id) {
              productSelectList.push(x);
              break;
            }
          }
        }

        let productSelectType = _.uniq(_.map(productSelectList, 'type'));

        if (this.typeSelected.length < productSelectType.length) {
          this.typeSelected = [];
        }

        if (this.typeSelected.length < productSelectType.length) {
          for (let selectType of productSelectType) {
            for (const option of this.typeOptions) {
              if (option.name == selectType) {
                this.typeSelected.push(JSON.stringify(option));
                break;
              }
            }
          }
        }

        this.$nextTick(() => {
          this.productionSelected = _.uniqBy(productSelectList, 'id').map(item => JSON.stringify(item));
        });
      }
    },

    isInitOk() {
      if (this.productionType.length > 0 && Object.keys(this.productionById).length > 0 && Object.keys(this.productionChildById).length > 0) {
        return true;
      } else {
        return false;
      }
    },

    update() {
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(async () => {
        await this.getProductionType();
        await this.getProductionById();
        await this.getProductionChildById();
        this.initAutoSelect();
      }, 300);
    },

    async getProductionType() {
      const res = await this.$axios.post(`/javaplus/admin/filter/api/getProductionType`);

      if (res.status !== 200 || res.data.errno) {
        return;
      }

      this.productionType = res.data.data;
    },

    async getProductionById() {
      var res = await this.$axios.post(`/javaplus/admin/filter/BOM/getProductionObjectById`, {});

      if (res.status !== 200 || res.data.errno) {
        return;
      }

      this.productionById = _.map(res.data.data, val => {
        const production = _.pick(val, ['id', 'name', 'type', 'enable']);

        if (this.productionCount) {
          production.$count = this.productionCount[val.id] || 0;
        }

        return production;
      });
    },

    checkProductionAll(selection, row) {
      this.checkProductAll = selection;
    },

    async getProductionChildById() {
      var res = await this.$axios.post(`/javaplus/admin/filter/plan/getproductionAllChildByid`, {});

      if (res.status !== 200 || res.data.errno) {
        return;
      }

      this.productionChildById = res.data.data;
    },

    emitChange() {
      clearTimeout(this.emitTimer);
      this.emitTimer = setTimeout(() => {
        this.$emit('change', this.changeValue);
      }, 300);
    }

  }
};