import { ElNotification } from 'element-plus';
import ProductionFilter2 from './erp_filter_com/ProductionFilter.vue';
export default {
  data() {
    return {
      ruleForm: {
        problemPhenomenon: '空问题现象'
      },
      rules: {
        problemPhenomenon: [{
          required: true,
          message: '请输入问题现象（中文）',
          trigger: 'blur'
        }]
      },
      productSelectionAndType: [],
      baseUrl: '/filter',
      //存储所点击的sop树信息
      sopClickData: {},
      loading: false,
      svg: `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `,
      treeListData: [],
      questionListData: [],
      value: '中文',
      testIndex: 0,
      orderNo: 1,
      options: [{
        value: '中文',
        label: '中文'
      }, {
        value: '英文',
        label: '英文'
      }],
      editObj: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      questionDefaultProps: {
        label: 'label'
      },
      isEdit: false,
      questionObj: {},
      idInPid: {},
      questionOrderNo: null,
      // 问题编号
      questionTitle: '',
      // 问题标题
      answerList: [],
      // 答案列表
      directoryId: null,
      questionId: null,
      pIdByTree: null,
      questionData: null,
      updateTimer: {},
      refresh: false,
      enterPressed: false,
      parentNodeIds: [],
      currentNodeIds: [],
      hightLightNode: null
    };
  },

  components: {
    ProductionFilter2
  },
  computed: {
    questionList() {
      const questionList = [];

      for (const question of this.questionListData) {
        question.label = question.title;
        const answerList = question.answerList;

        for (const answer of answerList) {
          this.idInPid[answer.id] = answer.question_id;
          answer.label = answer.content;
        }

        questionList.push(question);
      }

      return questionList;
    }

  },
  watch: {
    // productSelectionAndType:{
    //   handler(ov,nv){
    //   },
    //   deep:true
    // },
    answerList(val) {
      this.scrollToBottom();
    }

  },

  mounted() {
    this.getAllDirectoryList();
    this.update();
  },

  methods: {
    // checkRuleForm(){
    //   this.$refs.validate((valid)=>{
    //   })
    // },
    // validatorM(rule, value, callback) {
    //   if (value) {
    //     this.editWorkGuidelines()
    //   } else {
    //     callback('请输入问题现象（中文）')
    //   }
    // },
    changeProduction(filterData) {// filterData.productionSelected
    },

    //创建一个便于生成orderNo的数 区分同级和子级()
    initOrderNumber(pid, listData) {
      let max = 0;

      for (let i of listData) {
        if (i.pid == pid) {
          if (i.orderNo > max) {
            max = i.orderNo;
          }
        }
      }

      return max + 1; // console.log(sopTreeDate,'sopTreeDate')
    },

    tree2list(tree) {
      // console.log(tree,'tree')
      const list = [];
      const stack = [...tree]; // console.log(stack);

      while (stack.length) {
        const node = stack.pop();
        const children = node.children;

        if (children) {
          stack.push(...children);
        }

        list.push(node);
      }

      return list;
    },

    // 让答案列表的滚动条保持在底部
    scrollToBottom() {// this.$nextTick(() => {
      //   this.$refs.scrollRef.scrollTop = this.$refs.scrollRef.scrollHeight
      // })
    },

    update() {
      this.updateTimer && clearTimeout(this.updateTimer);
      this.refresh = true;
      this.updateTimer = setTimeout(() => {
        this.getAllAnswerListByQuestionId();
        this.refresh = false;
      }, 1000);
    },

    // 获取所有目录树形结构
    async getAllDirectoryList() {
      const res = await this.$axios.post('/javaplus/admin/sopDirectory/getSopTreeList');

      if (res.data.code !== 200) {
        return;
      }

      this.treeListData = res.data.data;

      if (_.isEmpty(res.data.data)) {
        return;
      }

      this.updateColumnData();
    },

    //点击按钮增加或修改目录
    async clickSaveOrUpdateDirectory() {
      this.orderNo = this.initOrderNumber(this.sopClickData.pid, this.tree2list(this.treeListData)); //10.17 便利找到最大值
      // treeListData
      // this.directoryId

      const data = {
        name: '新增目录',
        pid: this.pIdByTree,
        sumNum: 0,
        orderNo: this.orderNo
      };
      const key = this.$refs.treeList.getCurrentKey();

      if (key === null) {
        ElNotification({
          title: '提示',
          message: '请选择目录',
          type: 'warning',
          duration: 1500
        });
        return;
      } // '/java/admin/saveOrUpdateDirectory'
      // 


      const res = await this.$axios.post('/javaplus/admin/sopDirectory/saveOrUpdateDirectory', {
        orderNo: data.orderNo,
        name: data.name,
        pid: data.pid,
        createPersonId: this.directoryId
      });

      if (res.data.code !== 200 || res.data.errno) {
        ElNotification({
          title: '失败',
          message: res.data.message,
          type: 'warning',
          duration: 1500
        });
        return;
      }

      ElNotification({
        title: '成功',
        message: '目录新增成功',
        type: 'success',
        duration: 1500
      });
      this.getAllDirectoryList();
      setTimeout(() => {
        this.hightLightNode = res.data.data.id;
      }, 1000);
    },

    // 点击增加子目录按钮
    async clickSaveOrUpdateDirectoryOfChildren() {
      this.orderNo = this.initOrderNumber(this.sopClickData.id, this.tree2list(this.treeListData));

      if (this.directoryId == 0) {
        ElNotification({
          title: '提示',
          message: '不能操作<span style="color: red;font-weight: bold">未分配问题</span>目录节点',
          type: 'warning',
          duration: 2000,
          dangerouslyUseHTMLString: true
        });
        return;
      }

      const data = {
        name: '新增子目录',
        sumNum: 0,
        orderNo: this.orderNo
      };

      if (this.directoryId === null || this.directoryId == undefined) {
        ElNotification({
          title: '提示',
          message: '请选择目录',
          type: 'warning',
          duration: 1500
        });
        return;
      }

      const res = await this.$axios.post('/javaplus/admin/sopDirectory/saveOrUpdateDirectory', {
        orderNo: data.orderNo,
        name: data.name,
        pid: this.directoryId
      });

      if (res.data.code !== 200 || res.data.errno) {
        ElNotification({
          title: '失败',
          message: res.data.message,
          type: 'warning',
          duration: 2000
        });
        return;
      }

      ElNotification({
        title: '成功',
        message: '子目录增加成功',
        type: 'success',
        duration: 1500
      });
      this.$refs.treeBox.scrollTop = this.$refs.treeBox.scrollHeight;
      this.getAllDirectoryList();
      this.updateColumnData();
      this.currentNodeIds.push(this.directoryId);
      setTimeout(() => {
        this.hightLightNode = res.data.data.id;
      }, 1000);
    },

    // 通过点击树形目录，根据目录id获取问题列表
    clickColumnData(data, node) {
      this.sopClickData = data; // console.log(, 'data8898')

      this.questionId = null;
      this.questionOrderNo = null;
      this.questionTitle = null;
      this.answerList = [];
      this.parentNodeIds = data.pid;
      this.directoryId = data.id;
      this.pIdByTree = data.pid;
      this.ruleForm.problemPhenomenon = '空问题现象';
      this.productSelectionAndType = [];
      this.updateColumnData();
    },

    // 根据目录id获取问题列表
    async updateColumnData() {
      if (this.directoryId == undefined) {
        return;
      }

      const res = await this.$axios.get('/java/admin/getAllQuestionListByDirectoryId?sopDirectoryId=' + this.directoryId);

      if (res.status !== 200 || res.data.errno) {
        ElNotification({
          title: '失败',
          message: res.data.message,
          type: 'error',
          duration: 1500
        });
        return;
      }

      this.questionListData = res.data.data;
    },

    // 点击删除树形目录
    clickDeleteByDirectory() {
      if (this.directoryId == 0) {
        ElNotification({
          title: '错误',
          message: '不能删除<span style="color: red;font-weight: blod;">未分配问题</span>目录节点！',
          type: 'error',
          duration: 1500,
          dangerouslyUseHTMLString: true
        });
        return;
      }

      this.$confirm('此操作将永久删除该目录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // /admin/sopDirectory/deleteByDirectoryId/{sopDirectoryId}
        // + this.directoryId
        const res = await this.$axios.post(`/javaplus/admin/sopDirectory/deleteByDirectoryId/${this.directoryId}`);

        if (res.data.code != 200 || res.data.errno) {
          ElNotification({
            title: '错误',
            message: res.data.message,
            type: 'error',
            duration: 4000
          });
          return;
        }

        ElNotification({
          title: '成功',
          message: res.data.message,
          type: 'success',
          duration: 1500
        });
        this.getAllDirectoryList();
      }).catch(() => {});
      this.updateColumnData();

      if (this.parentNodeIds != null) {
        this.currentNodeIds.push(this.parentNodeIds);
      }
    },

    // 双击修改节点标题
    startEditing(data) {
      this.editObj = {};
      this.editObj[data.id] = true;
      this.$nextTick(() => {
        this.$refs.editInput.$refs.input.focus();
      });
    },

    // 光标离开时
    handleBlur(event, data) {
      if (!this.enterPressed) {
        this.endEditing(data);
      }

      this.enterPressed = false; // 重置 enterPressed 状态
    },

    // 点击回车键
    async endEditing(data) {
      this.enterPressed = true;
      this.editObj[data.id] = false;
      const res = await this.$axios.post('/javaplus/admin/sopDirectory/saveOrUpdateDirectory', {
        orderNo: data.orderNo,
        name: data.name,
        pid: data.pid,
        id: data.id
      });

      if (res.data.code !== 200 || res.data.errno) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'warning',
          duration: 1500
        });
        return;
      }

      ElNotification({
        title: '成功',
        message: res.data.msg,
        type: 'success',
        duration: 1500
      });
      this.updateColumnData();
    },

    // 点击问题列表
    clickQuestionList(data, node, event) {
      this.questionOrderNo = data.order_no;
      this.questionTitle = data.title;
      this.answerList = data.answerList;
      this.questionData = data;
      this.ruleForm.problemPhenomenon = data.problem_phenomenon;
      this.productSelectionAndType = _.cloneDeep(data.questionProductionList);
      this.questionId = null;
      this.$nextTick(() => {
        this.questionId = data.id;
      }); // questionProductionList
    },

    // 获取答案列表
    async getAllAnswerListByQuestionId() {
      const res = await this.$axios.get('/java/admin/getAllAnswerListByQuestionId?sopQuestionId=' + this.questionId);

      if (res.data.code !== 200 || res.data.errno) {
        return;
      }

      this.answerList = res.data.data;
    },

    // 点击新增问题按钮
    async clickAddQuestion() {
      const data = {
        orderNo: 1,
        title: '无标题问题',
        answerList: []
      };

      if (!this.questionOrderNo) {
        ElNotification({
          title: '提示',
          message: '请输入问题序号',
          type: 'warning',
          duration: 1500
        });
        return;
      }

      if (this.directoryId == null) {
        ElNotification({
          title: '提示',
          message: '请选择需要新增问题的目录',
          type: 'warning',
          duration: 1500
        });
        return;
      } // 问题新增或修改


      const res = await this.$axios.post('/java/admin/saveOrUpdateQuestion', {
        orderNo: this.questionOrderNo,
        // 排序号
        title: this.questionTitle || '无标题问题',
        directoryId: this.directoryId,
        // 目录编号
        answerList: data.answerList,
        // 答案列表
        problemPhenomenon: this.ruleForm.problemPhenomenon,
        productionIds: this.productSelectionAndType || []
      });
      this.updateColumnData();

      if (res.data.code != 200 || res.data.errno) {
        ElNotification({
          title: '错误',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      this.getAllDirectoryList();
      ElNotification({
        title: '成功',
        message: '问题新增成功',
        type: 'success',
        duration: 1500
      });
      this.updateColumnData();

      if (this.parentNodeIds != null) {
        this.currentNodeIds.push(this.parentNodeIds);
      }
    },

    // 点击删除问题按钮
    clickDeleteQuestion() {
      if (this.questionId == null || this.questionId == undefined) {
        ElNotification({
          title: '提示',
          message: '请选择需要操作的问题目录节点',
          type: 'warning',
          duration: 1500
        });
        return;
      }

      this.$confirm('此操作将永久删除该问题及答案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // 删除问题
        const res = await this.$axios.get('/java/admin/deleteByQuestionIds?sopQuestionIds=' + this.questionId);

        if (res.data.code != 200 || res.data.errno) {
          ElNotification({
            title: '失败',
            message: res.data.msg,
            type: 'error',
            duration: 1500
          });
          return;
        }

        ElNotification({
          title: '成功',
          message: '删除问题(答案)成功',
          type: 'success',
          duration: 1000
        });
        this.questionId = null;
        this.questionOrderNo = null;
        this.questionTitle = null;
        this.answerList = [];
        this.updateColumnData();
        this.getAllDirectoryList();
      }).catch(() => {});
    },

    // 保存修改问题(答案)按钮
    async clickUpdateQuestion() {
      if (this.directoryId == null) {
        ElNotification({
          title: '提示',
          message: '请选择需要保存问题的目录',
          type: 'warning',
          duration: 1500
        });
        return;
      }

      if (!this.questionOrderNo) {
        ElNotification({
          title: '提示',
          message: '请选择需要保存问题的序号',
          type: 'warning',
          duration: 1500
        });
        return;
      }

      const data = {
        id: this.questionId,
        // 问题id
        orderNo: this.questionOrderNo,
        title: this.questionTitle || '无标题问题',
        directoryId: this.directoryId,
        answerList: this.answerList,
        problemPhenomenon: this.ruleForm.problemPhenomenon,
        productionIds: this.productSelectionAndType
      };
      const res = await this.$axios.post('/java/admin/saveOrUpdateQuestion', {
        id: this.questionId,
        // 问题id
        orderNo: this.questionOrderNo,
        title: this.questionTitle,
        directoryId: this.directoryId,
        answerList: this.answerList,
        problemPhenomenon: this.ruleForm.problemPhenomenon,
        productionIds: this.productSelectionAndType || []
      });
      this.updateColumnData();

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      ElNotification({
        title: '成功',
        message: '问题(答案)保存成功',
        type: 'success',
        duration: 1500
      });
    },

    // 新增答案按钮
    async clickAddAnswer() {
      if (this.questionId == null || this.questionId == undefined) {
        ElNotification({
          title: '提示',
          message: '请选择需要新增答案的问题节点',
          type: 'warning',
          duration: 2000
        });
        return;
      }

      this.clickUpdateQuestion();
      const answer = [];
      answer.push({
        orderNo: 1,
        language: 'CN',
        content: ''
      });
      const res = await this.$axios.post('/java/admin/saveOrUpdateQuestion', {
        id: this.questionId,
        // 问题id
        orderNo: this.questionOrderNo,
        title: this.questionTitle,
        directoryId: this.directoryId,
        answerList: answer
      });
      this.update();
    },

    // 点击删除答案按钮
    clickDeleteAnswer(items) {
      this.$confirm('此操作将永久删除该答案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // 删除答案接口
        const res = await this.$axios.get('/java/admin/deleteByAnswerIds?sopAnswerIds=' + items.id);
        this.update();
        ElNotification({
          title: '成功',
          message: '删除答案成功',
          type: 'success',
          duration: 1500
        });
      }).catch(() => {});
    },

    // 点击返回上一页
    clickReturn() {
      this.loading = true;
      setTimeout(() => {
        this.$router.push({
          path: '/admin/chat'
        });
      }, 100);
    }

  }
};