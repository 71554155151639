import "core-js/modules/es.array.includes.js";
// import { ElLoading } from 'element-plus'
import bus from 'vue3-eventbus';
import Modal from '@/control/Modal.vue';
import Editor from '@/control/Editor.vue';
import QuestionTypeFilter from '@/control/Filter/QuestionTypeFilter.vue';
import MixedFilter from '@/control/Filter/MixedFilter.vue';
import FilterPanel from '@/control/FilterPanel.vue';
import FilterPanel2 from '@/control/FilterPanel2.vue';
import { mapGetters, mapState } from 'vuex';
import Catalogue from '../Sop/Control/Catalogue.vue';
import Assignment from '../Sop/Control/Assignment.vue';
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
import { ref } from 'vue';
import templatePop_1 from '@/control/templatePop_1.vue';
import templateTicket from '@/control/templateTicket.vue';
import tips from '@/control/templateTicket.vue'; // import templateDemoViewHeader from '@/control/templateDemoViewHeader.vue'

import templateDemo from '@/control/templateDemo.vue';
export default {
  setup(props, context) {
    const isUserApply = ref(0);
    const tagTitle = ref(null);
    const timer = ref({});
    const isShow = ref(true);
    const refreshQuestionData = ref(false);
    const onLineUser = ref([]);
    const userId = ref('');
    const questionRecordList = ref([]);
    const isRefresh = ref(false); // bus.on('init', (messageData) => {
    //   userId.value = messageData.userId
    // })
    // bus.on('event-userNewMsgUnreadCount', (messageData) => {
    //   tagTitle.value = messageData.data
    //   const title = 'wwt-faq'
    //   if (tagTitle.value) {
    //     clearInterval(timer.value)
    //     timer.value = setInterval(() => {
    //       isShow.value = !isShow.value
    //       if (isShow.value) {
    //         document.title = title + `[${tagTitle.value}message]`
    //       } else {
    //         document.title = title
    //       }
    //     }, 1000)
    //   }
    //   if (messageData.questionRecordId) {
    //     refreshQuestionData.value = true
    //   }
    // });
    // bus.on('event-userFinishApply', (messageData) => {
    //   if (messageData.questionRecordId == localStorage.getItem("questionRecordId")) {
    //     isUserApply.value = 3;
    //   }
    // });
    // bus.on('event-userCancelApply', (messageData) => {
    //   if (messageData.questionRecordId == localStorage.getItem("questionRecordId")) {
    //     isUserApply.value = 0;
    //   }
    // });
    // bus.on('event-userNewMsgAdminUnreadCount', (messageData) => {
    //   for (const data of questionRecordList.value) {
    //     for (const item of data.item) {
    //       if (Number(messageData.questionRecordId) == item.id) {
    //         item.admin_unread = messageData.data
    //       }
    //     }
    //   }
    //   isRefresh.value = true
    // });
    // bus.on('event-userOnlineData', (messageData) => {
    //   onLineUser.value = messageData.data
    //   for (const recordList of questionRecordList.value) {
    //     recordList.isOnLine = false
    //   }
    //   if (!_.isEmpty(onLineUser.value)) {
    //     for (const data of onLineUser.value) {
    //       for (const recordList of questionRecordList.value) {
    //         if (JSON.parse(recordList.user).name == data.userName) {
    //           recordList.isOnLine = true
    //           break
    //         }
    //       }
    //     }
    //   }
    // });

    return {
      isUserApply,
      refreshQuestionData,
      onLineUser,
      userId,
      questionRecordList,
      isRefresh
    };
  },

  components: {
    Modal,
    Editor,
    QuestionTypeFilter,
    MixedFilter,
    Catalogue,
    FilterPanel,
    FilterPanel2,
    Assignment,
    templatePop_1,
    templateTicket,
    templateDemo,
    tips // templateDemoViewHeader

  },

  data() {
    return {
      popInfo: '选择基础信息',
      tipsShow: false,
      tipsTitle: '',
      tipsContent: '',
      //订单信息加载功能
      orderTableLoading: false,
      tempTableLoading: false,
      loadingMessage: false,
      //updateQuestionListObj
      // 存储重新
      //存储展开数组
      questionListItemCopy: [],
      //创建一个可维护的工单数据对象
      questionListObj: {},
      //发送填报卡存储的数据
      sendContentTicket: "",
      //questionList的上一次滚动条高度
      questionListScrollTop: 0,
      getOnLineIdListTimer: null,
      wsOpenTimer: null,
      wsOpen: false,
      //
      wsCloseConnectTimes: 0,
      wsCloseConnectTimer: null,
      wsCloseConnect: false,
      reLinkTimer: null,
      reLinkWebSocket: false,
      //设置多少此ws断开后重新刷新页面
      // wsCloseConnectTimes:0,
      //ws断掉重连的timer
      // reLinkTimer:null,
      //存储一下上次点击的sopdata 用户匿名用户变为登录用户的更新
      sopClickData: null,
      //pageTotalUnred 控制页面总共的消息未读数量 ws 传递
      pageTotalUnred: 0,
      pageTotalUnredShow: false,
      //存储一下最原始的工单的id信息
      origQuestionId: null,
      withdrawShow: false,
      //报损单记录分页相关
      // total, sizes, prev, pager, next
      templpage: 1,
      templpageSize: 60,
      templpageSizes: [60, 100, 200, 300],
      templtotal: 1,
      templcurrentPage: 1,
      damageRTextL: {},
      //toolTipShow 控制标题弹窗
      toolTipShow: false,
      //当客服主动点击 ticket列表的时候应该把ticket锁住不能动 防止消息变更产生影响
      isTicketListChangeNow: false,
      //推出登录的弹窗显示
      dialogLogout: false,
      //目前客服的个人信息
      personInfoServer: {},
      //控制header上下工作
      workStatusVal: 1,
      workStatusOption: [{
        value: 0,
        label: '下工'
      }, {
        value: 1,
        label: '上工'
      }, {
        value: 2,
        label: '退出登录'
      }],
      //控制websocket open 方法Timer
      webSocketOpenTimer: null,
      //判断ws是否断开需要重新连接
      webSocketing_new: false,
      //
      //控制聊天框 图片的index
      chatImgIndex: 0,
      //控制聊天页面的scroll
      ticketScrollData: {
        scrollHeight_old: null,
        scrollTop_now: null
      },
      //控制是否还要查询表单
      isQueryRecord: false,
      //控制ticket列表分页
      pageCountt: 1,
      //在线用户id列表
      onLineIdList: [],
      //新websocket心跳消息timer
      heartMessageTimer: null,
      //scrollTimer 控制聊天框滚轮timer
      scrollTimer: null,
      //控制聊天页面的scroll
      chatScrollData: {
        scrollHeight_old: null,
        scrollTop_now: null
      },
      //正在聊天的用户信息的记录
      nowChatUserRecordInfo: {},
      //新消息记录循环列表
      chatRecordList_new: [],
      //周数 用于查询消息记录
      weeks: 1,
      //时间戳信息 用于查询消息记录
      dataRange: {},
      //点击分类问题时候的record记录
      recordDataF: {},
      AdminWSTimeOut_new: null,
      webSocketTimer_new: null,
      webSocketTimeOutTimer_new: null,
      newSocket: undefined,
      isSocketing: false,
      //新websocket
      webSocketUrl: `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${window.location.host}/javaplus/admin/webSocketServer`,
      //撤回报损单所需要的id
      selectChatId: null,
      //子组件需要的方法总和
      templatePopFunction: {},
      chTop: null,
      chLeft: null,
      chShow: false,
      chTicketId: null,
      chShowTimer: null,
      //判断pop是否需要清空数据
      clearPopData: true,
      //控制弹窗templatePop_1是否清空数据
      popShow_1: true,
      //所点击查看的报损单id
      ticketTempId: null,
      //按钮的数组
      btnArray: [],
      //聊天框的票数据
      tempTicketData: {},
      //templateDemeData
      templateDemeData: [],
      //控制查看或者填报报损单显示
      templateDemoShow: false,
      //报损单填报项目
      damageReportContent: {},
      //报损单填报信息
      templateData: {},
      //用户account
      userAccount: '',
      //报损单记录
      templRecordList: [// {
        //   "id": 13,
        //   "appealTime": 1720144371904,
        //   "purchasingChannels": "1",
        //   "purchasingChannelsName": "WINWING官网",
        //   "orderNum": "98111xxx0ujio",
        //   "deliveryWarehouseId": 3,
        //   "deliveryWarehouseName": "国外直邮仓",
        //   "paymentTime": "2024-06-14 17:03:00",
        //   "productionId": 57,
        //   "productionName": "JGRIP-F18",
        //   "merchandiseName": null,
        //   "sopId": 75,
        //   "sopName": "3.1.3.\t关于未上架开始销售的新产品信息询问/未上架单独销售的产品、配件",
        //   "warrantyType": "",
        //   "warrantyTypeName": "",
        //   "personId": 338,
        //   "personName": "",
        //   "damageReportTime": 1720151234883,
        //   "status": "3",
        //   "statusName": "等待补充填报"
        // },
        // {
        //   "id": 16,
        //   "appealTime": 1720144371904,
        //   "purchasingChannels": "1",
        //   "purchasingChannelsName": "WINWING官网",
        //   "orderNum": "98111xxx0ujio",
        //   "deliveryWarehouseId": 3,
        //   "deliveryWarehouseName": "国外直邮仓",
        //   "paymentTime": "2024-06-14 17:03:00",
        //   "productionId": 57,
        //   "productionName": "JGRIP-F18",
        //   "merchandiseName": null,
        //   "sopId": 75,
        //   "sopName": "3.1.3.\t关于未上架开始销售的新产品信息询问/未上架单独销售的产品、配件",
        //   "warrantyType": "",
        //   "warrantyTypeName": "",
        //   "personId": 338,
        //   "personName": "",
        //   "damageReportTime": 1720157206824,
        //   "status": "1",
        //   "statusName": "等待填报"
        // }
      ],
      dialogVisible: false,
      questionTypeSelected: [],
      questionTypeOptions: [],
      // 问题类型接口数据
      part: false,
      wsQuestionId: null,
      _: _,
      resolvedFilter: false,
      noReplyFilter: false,
      sendContent: '',
      sendAIContent: '',
      viewQuestionStepModalShow: false,
      commentModalShow: false,
      viewOrderModalShow: false,
      timer: {},
      turn: false,
      orderStatus: 0,
      questionRecordCurrent: {},
      imgArray: [],
      img: '',
      imgIndex: 0,
      imgViewerShow: false,
      commentTemp: {},
      scrollAjustById: {},
      treeListData: [],
      customerOrderListData: [],
      // 客户订单列表
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      //模板表格是否展示
      isTemplShow: false,
      isShowTable: false,
      showAI: false,
      drawer: false,
      isHidden: false,
      drawerTimer: null,
      loading: false,
      directoryId: null,
      questionDataId: null,
      // questionListById: 0,
      AiData: null,
      message: '',
      currentAccount: '',
      catalogId: null,
      questionData: [],
      questionDataIds: [0],
      loadingAIChat: false,
      refresh: false,
      loadingTextArray: [],
      updateTimer: {},
      appriseMsg: '',
      //申请评价成功后，展示内容
      isApply: 0,
      checkedSolveOptions: [],
      solveOptions: [{
        id: 1,
        label: 'resolved'
      }, {
        id: 0,
        label: 'no-reply'
      }, {
        id: 2,
        label: 'replied'
      }, {
        id: 3,
        label: '回复异常'
      }],
      // questionRecordList: [], // 问题列
      itemsPerPage: 50,
      // 每页显示条数
      currentPage: 1,
      // 页码
      isLoading: false,
      totelItem: 0,
      selectItem: null,
      drawerAssignment: false,
      assignmentQuestionId: null,
      //分派问题id
      customerServiceId: null,
      // 客服人员id
      IssueNotes: null,
      //问题备注时的id
      questionCustomerArray: [{
        id: 0,
        name: 'Unassigned problem'
      }],
      // 客服人员
      questionCustomerIdArray: [],
      startTime: undefined,
      endTime: undefined,
      clientName: undefined,
      isDragging: false,
      panelWidth: 200,
      // 初始宽度
      copyId: null,
      chatRecordList: [],
      // 聊天记录
      recordData: null,
      questionRecordByIdData: null,
      recallTimer: {},
      newChatRecordList: [],
      menuTop: 0,
      menuLeft: 0,
      logisticsInformation: [],
      logisticsInformationData: {},
      dialogTableVisible: false,
      questionId: 0,
      emergencyDegreeSelect: [],
      emergencyDegreeOption: [],
      regionOption: [{
        id: 'global',
        dictLabel: 'global'
      }, {
        id: 'cn',
        dictLabel: 'cn'
      }],
      regionSelect: [],
      schedulingSelect: [],
      schedulingOption: [{
        id: '1',
        dictLabel: '白班(8:00AM-5:00PM)'
      }, {
        id: '2',
        dictLabel: '夜班(5:00PM-00:30AM)'
      }, {
        id: '3',
        dictLabel: '深夜班(00:30PM-8:00AM)'
      }]
    };
  },

  computed: {
    //是否能够发送消息 取决于是否点击聊天窗口 和是否上下工
    canSendMessage() {
      if (Object.keys(this.nowChatUserRecordInfo).length == 0) {
        // ElMessage.error('请选择登录用户工单')
        return false;
      }

      if (this.workStatusVal) {
        return true;
      } else {
        return false;
      }
    },

    ...mapState({
      user: 'user',
      order: 'order',
      isScorePanelShow: "isScorePanelShow",
      questionRecord: 'questionRecord',
      questionRecordId: 'questionRecordId',
      // 当前聊天窗的问题id
      questionRecordUser: 'questionRecordUser'
    }),
    ...mapGetters({
      questionById: 'getQuestionById',
      questionTypeById: 'getQuestionTypeById',
      questionRecordById: 'getQuestionRecordById',
      getPersonName: 'getPersonName',
      getPersonBackgroundColor: 'getPersonBackgroundColor',
      getFilterQuestionRecordByUser: 'getFilterQuestionRecordByUser',
      getUserInfo: 'getUserInfo'
    }),

    orderByBuy() {
      var order = [];

      for (const _order of this.order) {
        if (_order.is_del == 0 && _order.status == 3 && _order.pay_status == 3 && _order.delivery_status == 3) {
          order.push(_order);
        }
      }

      return order;
    },

    itemByUser() {
      var item = [];

      for (const _order of this.orderByBuy) {
        for (const _goods of _order.order_goods) {
          _goods.prom_goods = JSON.parse(_goods.prom_goods);

          if (_goods.prom_goods.goods_no) {
            item = item.concat(_goods.prom_goods.goods_no.split('&')); // &
          }
        }
      }

      item = _.uniq(item);
      return item;
    },

    questionRecordSelected() {
      const questionRecordSelected = this.questionRecordById[this.questionRecordId] || {};
      return questionRecordSelected;
    },

    title() {
      if (this.questionRecordId == 0) {
        return '';
      }

      var record = this.questionRecordById[this.questionRecordId];

      if (record.question_id != 0 && this.questionById[record.question_id]) {
        // 知识库问题
        return this.questionById[record.question_id].title;
      } else {
        // 用户自定义问题
        return record.title;
      }
    },

    scrollPosition() {
      return this.$store.state.scrollPosition;
    } // recordData() {
    //   const storeData = localStorage.getItem('recordData')
    //   if (storeData) {
    //     const recordData = JSON.parse(storeData);
    //   }
    //   return recordData
    // },


  },
  watch: {
    //点击问题的sopdata
    sopClickData: {
      handler(nv, ov) {
        localStorage.setItem('sopClickData', JSON.stringify(this.sopClickData));
      }

    },
    //问题数列表
    catalogId: {
      handler(nv, ov) {
        localStorage.setItem('catalogId', JSON.stringify(this.catalogId));
      }

    },
    //工单ticket列表
    questionRecordList: {
      handler(nv, ov) {
        localStorage.setItem('userTicketList', JSON.stringify(this.questionRecordList));
      },

      deep: true
    },
    //点击工单的单个数据
    nowChatUserRecordInfo: {
      handler(nv, ov) {
        // console.log(nv,'nvnvnvnv')
        localStorage.setItem('nowChatUserRecordInfo', JSON.stringify(this.nowChatUserRecordInfo));
      }

    },
    //正在聊天的用户账号
    userAccount: {
      handler(nv, ov) {
        localStorage.setItem('userAccount', JSON.stringify(this.userAccount));
      }

    },
    //之前的滚轮高度
    scrollHeight: {
      handler(nv, ov) {
        localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old));
      }

    } // emergencyDegreeSelect: {
    //   handler(nv, ov) {
    //     this.update()
    //   },
    //   immediate: true
    // },
    // isRefresh: {
    //   handler(nv, ov) {
    //     if (nv == true) {
    //       this.getUserHistoriesChatRecordListByQuestionRecordId()
    //       const record = JSON.parse(localStorage.getItem('recordData'))
    //       this.getUserChatRecordListByQuestionRecordId(record)
    //       this.isRefresh = false
    //     }
    //   }
    // },
    // chatRecordList: {
    //   handler(nv, ov) {
    //     if (this.isShowTable === false) {
    //       const store = localStorage.getItem('recordData')
    //       if (store) {
    //         this.questionRecordByIdData = JSON.parse(store).is_solved
    //       }
    //       this.$nextTick(() => {
    //         this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
    //       })
    //     }
    //   },
    //   immediate: true
    // },
    // refreshQuestionData: {
    //   handler(nv, ov) {
    //     if (nv == true) {
    //       const store = localStorage.getItem('recordData')
    //       const recordData = JSON.parse(store);
    //       this.getUserChatRecordListByQuestionRecordId(recordData);
    //       this.loadingMessageQuestionData = false
    //     }
    //   }
    // },
    // checkedSolveOptions: {
    //   handler(nv, ov) {
    //     this.questionRecordList = []
    //     this.currentPage = 1
    //     this.update()
    //   },
    //   immediate: true
    // },
    // questionTypeSelected: {
    //   handler(nv, ov) {
    //     this.questionRecordList = []
    //     this.currentPage = 1
    //     this.update()
    //   },
    //   immediate: true
    // },
    // questionCustomerIdArray: {
    //   handler(nv, ov) {
    //     this.questionRecordList = []
    //     this.currentPage = 1
    //     this.update()
    //   },
    //   immediate: true
    // },
    // isUserApply: {
    //   handler(nv, ov) {
    //     if (nv == 1) {
    //       this.$store.commit('changeIsScorePanelShow', true)
    //     }
    //   },
    //   immediate: true
    // },
    // chatReocrd: {
    //   handler(nv, ov) {
    //     const chatLength = this.chatReocrd && Object.keys(this.chatReocrd).length || 0;
    //     if (chatLength) {
    //       this.scrollAjust(this.questionRecordId, chatLength);
    //     }
    //   },
    //   flush: 'post'
    // },
    // getFilterQuestionRecordByUser: {
    //   handler(nv, ov) {
    //     if (this.questionRecordId == 0 && !_.isEmpty(nv)) {
    //       this.$store.commit('changeQuestionRecordUser', nv.user)
    //       this.$store.commit('changeQuestionRecordId', nv.question_record_id)
    //     }
    //   }
    // },
    // questionRecordSelected: {
    //   handler(nv, ov) {
    //     if (nv == ov) {
    //       return;
    //     }
    //     this.questionListById = this.questionRecordId // 问题id
    //     if (this.currentAccount == this.questionRecordSelected?.user?.account) {
    //       return;
    //     }
    //     this.currentAccount = this.questionRecordSelected?.user?.account;
    //     this.getUserHisAiList()
    //     this.getOrderByUser()
    //   }
    // },
    // questionRecordId: {
    //   handler(nv, ov) {
    //     if (nv == ov) {
    //       return
    //     }
    //     this.getFAQAppraiseStatus()
    //   },
    //   immediate: true
    // },
    // AiData: {
    //   handler(nv, ov) {
    //     if (_.isEmpty(this.AiData)) {
    //       return
    //     }
    //     this.scrollToBottom()
    //   },
    //   immediate: true
    // },

  },

  created() {
    // console.log('cccc');
    //
    this.templatePopFunction = {
      closeTemplatePop: this.closeTemplatePop,
      getTemplatePopData: this.getTemplatePopData,
      returnLastStep: this.returnLastStep,
      getTicketDataSend: this.getTicketDataSend,
      returnPreView: this.returnPreView
    };
    this.initlocalStorage();
  },

  mounted() {
    //页面标题闪动
    this.initTitleUnreadMessage(); //获取目前未读消息总数

    this.getTotalUnreadNum();
    this.init_newSocket(); // console.log('23232');

    this.info().then(res => {
      if (res.data.code == 200) {
        this.personInfoServer = res.data.data;
        this.workStatusVal = res.data.data.workStatus;
        this.$store.commit('changeUser', res.data.data);
      }
    }); // this.getOnLineIdList()
    //  localStorage.setItem('nowChatUserRecordInfo', JSON.stringify(this.nowChatUserRecordInfo))
    // let obj = {
    //   str:'1',
    //   personData:'2',
    //   yestDasQsd:'3',
    //   title:'4',
    //   optionTitleBack: "2"
    // }
    // for(let i in obj){
    //   i=this.changeDataToSnakeCase(i)
    //   console.log(obj)
    // }
    //控制页面加载动画
    // this.$eventBus.on('loading', (bool) => {
    //   this.loadingMessage = bool
    // })
    //报损单的取消 返回上一步
    // this.$eventBus.on('preStep', () => {
    //   this.templateDemoShow = false
    //   this.dialogVisible = true
    // })
    // this.$eventBus.on('dialogClose', () => {
    //   // console.log('isTemplShowisTemplShowisTemplShowisTemplShow');
    //   if (this.clearPopData) {
    //     this.dialogVisible = false
    //     this.popShow_1 = false
    //     this.$nextTick(() => {
    //       this.popShow_1 = true
    //     })
    //   } else {
    //     this.clearPopData = true
    //   }
    //   // this.dialogVisible = false
    //   // console.log(this.isTemplShow);
    // })
    // //从demo中拿到数据给模板表单展示
    // this.$eventBus.on('getTempData', (data) => {
    //   // console.log('555666777');
    //   this.loadingMessage = true
    //   this.damageReportContent = []
    //   this.templateDemeData = data
    //   this.getShowTempItemById({ id: data.tempItemId }).then((res) => {
    //     this.damageReportContent = res.data.data
    //     this.$nextTick(() => {
    //       // console.log(this.damageReportContent, '发布的时候');
    //       this.clearPopData = false
    //       this.templateDemoShow = true
    //       this.dialogVisible = false
    //     })
    //     // console.log(this.damageReportContent);
    //   })
    //   //拿到聊天框中Ticketdata进行处理
    // })
    // this.$eventBus.on('getTicketData', (data) => {
    //   // console.log(this,'111222333');
    //   this.loadingMessage = false
    //   // this.popShow_1 = false
    //   // this.tempTicketData = data
    //   // this.templateDemoShow = false
    //   // this.$nextTick(()=>{
    //   //   this.popShow_1 = true
    //   // })
    //   this.popShow_1 = false
    //   this.tempTicketData = data
    //   this.templateDemoShow = false
    //   const kd = new KeyboardEvent('keydown', {
    //     key: 'Enter',         // 按键的名称
    //     code: 'Enter',        // 按键的代码
    //     keyCode: 13,          // 按键的代码（旧版兼容性）
    //     which: 13,            // 按键的代码（旧版兼容性）
    //     bubbles: true,        // 事件是否会冒泡
    //     cancelable: true      // 事件是否可以取消
    //   })
    //   this.$nextTick(async () => {
    //     // console.log(this.sendContent, 'this.sendContent121212');
    //     this.$refs.ticket.$el.querySelector('.chatBtn').children[0].innerHTML = '点击填报'
    //     this.sendContent = this.$refs.ticket.$el.innerHTML
    //     // let html = this.$refs.ticket.$el.innerHTML.querySelector('.')
    //     // console.log(this.$refs.ticket.$el.querySelector('.chatBtn').children[0].innerHTML, 'this.sendContent121212');
    //     await this.clickSendContent(kd, data)
    //     this.popShow_1 = true
    //     this.clearPopData = true
    //     this.sendContent = ''
    //     this.tempTicketData = {}
    //   })
    // })
    // //客服查看报损单中返回
    // this.$eventBus.on('returnPreView', () => {
    //   this.templateDemoShow = false
    // })

    const storeData = localStorage.getItem('recordData');

    if (storeData) {
      const recordData = JSON.parse(storeData);
      this.clickQuestionRecord(recordData);
    }

    this.getDictDataList();
    this.getQuestionTypeList();
    this.getPersonDataList();
    this.$refs.recordListRef.scrollTop = this.scrollPosition;
    document.addEventListener('click', this.hideRecallMenuOnClickOutside); // this.getUserHistoriesChatRecordListByQuestionRecordId()

    this.update();
  },

  unmounted() {
    document.removeEventListener('click', this.hideRecallMenuOnClickOutside);
    this.newSocket.close(); // this.$eventBus.off('')
  },

  methods: {
    //改变pop_1 的title
    changeTitle(val) {
      this.popInfo = val;
    },

    async clickCarryOver() {
      await this.getCarryOverList().then(async res => {
        if (!res) {
          return;
        }

        if (!res.data.data.length) {
          ElMessage.error('无未结转的工单，无法结转！');
          return;
        }

        this.tipsShow = true;
        this.tipsTitle = '结转';
        this.tipsContent = '您确定要将从未回复的工单结转到下个班次吗？';
        const confirm = await this.tipsDialogConfirm();

        if (confirm) {
          await this.carryOver().then(() => {
            this.tipsShow = false;
          });
        } else {
          this.tipsShow = false;
        }
      });
    },

    tipsDialogConfirm() {
      return new Promise((resolve, reject) => {
        this.$eventBus.on('click-tipsButton', result => {
          resolve(result);
        });
      });
    },

    clickTipsButton(result) {
      this.$eventBus.emit('click-tipsButton', result);
      this.$eventBus.all.delete('click-tipsButton');
    },

    async getCarryOverList() {
      try {
        const res = await this.$axios.post('/javaplus/admin/recordQuestion/getCarryOverList');

        if (res.data.code != 200) {
          ElMessage.error(res.data.message);
          return;
        } else {
          return res;
        }
      } catch (err) {
        ElMessage.error(err);
      }
    },

    async carryOver() {
      try {
        const res = await this.$axios.post('/javaplus/admin/recordQuestion/carryOver');

        if (res.data.code != 200) {
          ElMessage.error(res.data.message);
          return;
        } else {
          ElMessage.success(res.data.message);
          return res;
        }
      } catch (err) {}
    },

    //报损单ticket完成渲染后的回调
    callBack() {
      new Promise(async (resolve, reject) => {
        try {
          await this.addTicketTitleHover();
          await this.addTicketBodyCancel(this.chatRecordList_new);
          await this.replaceTicketBtn();
          await this.addChatBtnClick(() => {
            resolve();
          });
        } catch (err) {
          reject(err);
        }
      });
    },

    //创建一个可维护的列表数据来更新工单状态
    updateQuestionListObj(questionList, isCreate, questionListI) {
      //创建列表
      if (isCreate) {
        //先清空再循环遍历增加
        this.questionListObj = {};

        for (let i of questionList) {
          //x是具体到某个工单的数据
          for (let x of i.item) {
            //判断是否已经创建 
            if (this.questionListObj[x.id]) {
              this.questionListObj[x.id].push(i);
            } else {
              this.questionListObj[x.id] = [i];
            }
          }
        } //滞空影响


        this.questionListItemCopy = [];
      } else {
        //更新列表  折叠  要排除之前新增的影响
        // if (this.questionListItemCopy.length > 1) {
        for (let x in this.questionListItemCopy) {
          //只去除1后面的影响 
          if (x > 0) {
            this.questionListObj[this.questionListItemCopy[x].id].pop();
          } // if(this.questionListObj[x.id]){
          //   this.questionListObj[x.id].pop()
          // }

        } // } else {
        //更新列表  展开  列表展开的时候 直接数组加进去就行


        for (let x in questionListI.item) {
          if (x > 0) {
            if (this.questionListObj[questionListI.item[x].id]) {
              this.questionListObj[questionListI.item[x].id].push(questionListI);
            } else {
              this.questionListObj[questionListI.item[x].id] = [questionListI];
            }
          }
        } //滞空影响


        if (this.questionListItemCopy) {
          this.questionListItemCopy = [];
        }

        if (questionListI.item.length > 1) {
          //如果是展开那就存下楼 下次折叠是时候要用
          this.questionListItemCopy = JSON.parse(JSON.stringify(questionListI.item));
        } // }
        //先判断是否是更新
        // if (questionListI) {
        //再判断是展开还是折叠
        // }

      }
    },

    ////直接查询一次questionlist列表 用于更新消息的时候
    async quaryQuesList() {
      //直接查询一次list列表
      this.pageCountt = 1;
      this.isQueryRecord = false;
      const paramsTicket = {
        state: this.checkedSolveOptions.length == this.solveOptions.length ? [] : this.checkedSolveOptions,
        questionType: this.questionTypeSelected.length == this.questionTypeOptions.length ? [] : this.questionTypeSelected,
        personIds: this.questionCustomerIdArray.length == this.questionCustomerArray.length ? [] : this.questionCustomerIdArray,
        urgencyLevel: this.emergencyDegreeSelect.length == this.emergencyDegreeOption.length ? [] : this.emergencyDegreeSelect,
        mark: this.regionSelect.length == this.regionOption.length ? [] : this.regionSelect,
        classes: this.schedulingSelect.length == this.schedulingOption.length ? [] : this.schedulingSelect,
        clientName: this.clientName,
        directoryId: this.catalogId,
        currentPage: this.currentPage,
        pageSize: this.itemsPerPage
      };
      await this.queryRecordQuestionList(paramsTicket).then(res => {
        this.questionRecordList = this.newDataToOldData(res.data.data.list); //建立关系数据对象

        this.updateQuestionListObj(this.questionRecordList, true);
        this.initOnlineData();
      });
    },

    //请求某个人的工单列表
    async getPersonTicketList(recordId) {
      let res;

      if (recordId) {
        res = await this.$axios.post('/javaplus/admin/recordQuestion/queryRecordQuestionByPersonalId/' + recordId);
      } else {
        res = await this.$axios.post('/javaplus/admin/recordQuestion/queryRecordQuestionByPersonalId/' + JSON.parse(this.nowChatUserRecordInfo?.user).id);
      }

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      return res.data.data;
    },

    //控制打开某个工单的排序的方法
    sortQuestionRecord(recordList, orginId) {
      // let item 
      for (let i in recordList) {
        if (recordList[i].id == orginId) {
          let item = recordList[i];
          recordList.splice(i, 1);
          recordList.unshift(item);
          break;
        }
      }
    },

    //控制页面标题闪烁 未读消息提示的方法+
    initTitleUnreadMessage() {
      setInterval(() => {
        if (this.pageTotalUnredShow) {
          document.title = `vue-wwt-faq`;
        } else {
          document.title = `wwt-faq[${this.pageTotalUnred}message]`;
        }

        this.pageTotalUnredShow = !this.pageTotalUnredShow;
      }, 1500);
    },

    async getTotalUnreadNum(params) {
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/getTotalUnreadNum', params);

      if (res.data.code != 200) {
        this.notify({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
        return;
      } else {
        this.pageTotalUnred = res.data.data; // this
      }
    },

    //更新questionList 的已读未读状态 
    updateQuestListStatus(useValue, newValue) {
      useValue.unread = newValue.unread;
      useValue.unsolved = newValue.unsolved;
      useValue.last_change_time = newValue.last_change_time; // quantity: 0,
      // unread: item.totalUnreadNum,
      // user: JSON.stringify(item.personal),
      // unsolved: item.totalIsSolvedNum,
      // last_change_time: this.dataTimeFormatter(item.updateTimestamp)
    },

    //分页相关
    handleSizeChange(val) {
      this.templpageSize = val;
      this.clicktemplRecord(true);
    },

    handleCurrentChange(val) {
      this.templcurrentPage = val;
      this.clicktemplRecord(true);
    },

    //更新当前工单信息
    async updateNowTicketInfo() {
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/queryRecordQuestionByPersonalId/' + JSON.parse(this.nowChatUserRecordInfo?.user).id);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      for (let i of this.newDataToOldData(res.data.data)) {
        if (i.item[0].id == this.nowChatUserRecordInfo.id) {
          this.nowChatUserRecordInfo = i.item[0]; //并且更新聊天窗列表数据

          for (let x of this.questionRecordList) {
            if (x.item[0].id == i.item[0].id) {
              //更新工单列表已读未读状态
              this.updateQuestListStatus(x, i);
              x.item[0] = i.item[0];
              break;
            }
          }

          break;
        }
      }
    },

    //判断是否展示聊天框
    isShowChat() {
      if (Object.keys(this.nowChatUserRecordInfo).length == 0) {
        // ElMessage.error('请选择登录用户工单')
        return false;
      }

      return true;
    },

    //报损单记录分页
    loadTempTable() {},

    //[FAQ]报损单表单
    async damageR(params) {
      const res = await this.$axios.post('/javaplus/admin/form/damage', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    clickUpload() {
      if (this.workStatusVal) {// return true
      } else {
        ElMessage.error('请先上工后再进行操作'); // return
        // return false
      }
    },

    //关闭demo弹窗
    demoClose() {
      this.popShow_1 = false;
      this.templateDemoShow = false;
      this.$nextTick(async () => {
        setTimeout(() => {
          this.popShow_1 = true;
          this.clearPopData = true;
          this.tempTicketData = {};
        }, 1000);
      });
    },

    beforeDialogLogout() {
      this.info().then(res => {
        // this.workStatusVal = res.data.data.workStatus
        this.workStatusVal = res.data.data.workStatus; // done()

        this.dialogLogout = false;
      });
    },

    //初始化新websocket
    init_newSocket() {
      this.newSocket = new WebSocket(this.webSocketUrl); // console.log(this.newSocket, 'this.newSocket')

      this.newSocket.onopen = this.new_Open;
      this.newSocket.onerror = this.new_Error;
      this.newSocket.onmessage = this.new_Get;
      this.newSocket.onclose = this.new_Close; // this.webSocketing_new = true
      // setTimeout(() => {
      //   if(this.webSocketing_new){
      //     init_newSocket()
      //   }
      // }, 5000);
    },

    new_Open() {
      // clearTimeout(this.wsOpenTimer)
      // if(this.wsOpen){
      //   return
      // }
      // this.wsOpen = true
      // this.wsOpenTimer = setTimeout(() => {
      console.log('新ws客服端开启完成'); //连接成功查询一次在线列表
      // this.wsCloseConnectTimer = setTimeout(()=>{
      // this.wsCloseConnectTimes = 0,
      // },5000)

      clearTimeout(this.wsCloseConnectTimer);
      this.wsCloseConnectTimer = setTimeout(() => {
        this.wsCloseConnectTimes = 0;
      }, 20000);
      clearTimeout(this.getOnLineIdListTimer);
      this.getOnLineIdListTimer = setTimeout(() => {
        this.getOnLineIdList().then(res => {
          if (res.data.data.length) {
            this.isOnLine = true;
          }
        });
      }, 6000);
      clearTimeout(this.heartMessageTimer);
      this.heartMessageTimer = setInterval(() => {
        this.heartWebSocketOpen();
      }, 10000); // },3000)
    },

    new_Error(event) {
      console.log('ws error_new', event);
    },

    new_Close() {
      if (this.reLinkWebSocket) {
        return;
      }

      console.log('ws close_new', this.wsCloseConnectTimes);

      if (this.wsCloseConnectTimes > 10) {
        this.$router.go(0);
      }

      this.reLinkWebSocket = true;
      this.reLinkTimer = setTimeout(() => {
        this.reLinkWebSocket = false;
        this.init_newSocket();
        this.wsCloseConnectTimes = this.wsCloseConnectTimes + 1;
      }, 3000);
    },

    async new_Get(msg) {
      if (msg.data.includes('服务器错误')) {
        // this.webSocketing_new = true
        return;
      }

      console.log(msg, 'getMMMMsg');
      const data = JSON.parse(msg.data);

      if (data.msgType == 1) {
        if (this.workStatusVal) {
          this.getOnLineIdList().then(res => {
            this.onLineIdList = res.data.data;
            this.initOnlineData();
          });
        }
      } else if (data.msgType == 2) {
        this.questionListScrollTop = this.$refs.recordListRef.scrollTop; // this.pageCountt = 1
        // this.isQueryRecord = false
        // //查询ticket列表
        // const paramsTicket = {
        //   state: this.checkedSolveOptions.length == 3 ? [] : this.checkedSolveOptions,
        //   questionType: this.questionTypeSelected.length == this.questionTypeOptions.length ? [] : this.questionTypeSelected,
        //   personIds: this.questionCustomerIdArray.length == this.questionCustomerArray.length ? [] : this.questionCustomerIdArray,
        //   urgencyLevel: this.emergencyDegreeSelect.length == this.emergencyDegreeOption.length ? [] : this.emergencyDegreeSelect,
        //   clientName: this.clientName,
        //   directoryId: this.catalogId,
        //   currentPage: this.currentPage,
        //   pageSize: this.itemsPerPage,
        // }
        // this.queryRecordQuestionList(paramsTicket).then((res) => {
        //   this.questionRecordList = []
        //   this.questionRecordList = this.newDataToOldData(res.data.data.list)
        //   // localStorage.setItem('userTicketList', JSON.stringify(this.questionRecordList))
        //   this.initOnlineData()
        // })

        this.quaryQuesList(); //恢复高度
        // this.$nextTick(() => {
        //   this.$refs.recordListRef.scrollTop = this.questionListScrollTop
        // })
        //再更新一下sop数列表

        this.update();
        this.getTotalUnreadNum();
      } else if (data.msgType == 4 || data.msgType == 10) {
        if (Object.keys(this.nowChatUserRecordInfo).length == 0) {
          // ElMessage.error('请选择登录用户工单')
          return;
        } //判断是否是当前聊天窗口


        if (data.recordQuestionId == this.nowChatUserRecordInfo.id) {
          //清空未读消息
          // await this.updateRecordQuestionUnread({ recordQuestionId: this.nowChatUserRecordInfo.id }).then((res) => {
          // })
          this.queryChatRecord(true);
        } else {
          //先判断是否是当前列表的树
          if (this.questionListObj[data.recordQuestionId]) {
            for (let i of this.questionListObj[data.recordQuestionId]) {
              if (i.item.length > 1) {
                await this.getPersonTicketList().then(res => {
                  let list = this.newDataToOldData(res); //清空之前存储一下数量
                  //先清空 

                  i.item = [];

                  for (let item of list) {
                    i.item.push(item.item[0]); ////如果就是正在处理的聊天界面就去更新状态
                    // if (item.item[0].id == this.nowChatUserRecordInfo.id) {
                    //   this.nowChatUserRecordInfo = item.item[0]
                    // }
                  } //然后再拍一次顺序


                  this.sortQuestionRecord(i.item, this.origQuestionId); //根本不用更新列表 有新工单数据就会重新更新 不存在数据数量不统一的情况
                  // this.updateQuestionListObj(this.questionRecordList,false,i)
                  //排完之后更新一下已读未读状态

                  this.updateQuestListStatus(i, list[0]); //
                  //恢复高度
                  // this.$nextTick(() => {
                  //   this.$refs.recordListRef.scrollTop = this.questionListScrollTop
                  // })
                });
              } else {
                await this.getPersonTicketList(JSON.parse(i.user).id).then(res => {
                  let list = this.newDataToOldData(res);
                  i.item = [];

                  for (let item of list) {
                    ////如果就是正在处理的聊天界面就去更新状态
                    if (item.item[0].id == data.recordQuestionId) {
                      i.item.push(item.item[0]);
                      this.updateQuestListStatus(i, item);
                    }
                  } // //先清空 
                  // for (let item of list) {
                  //   if (item.item[0].id == this.nowChatUserRecordInfo.id) {
                  //     this.nowChatUserRecordInfo = item.item[0]
                  //     // setTimeout(()=>{
                  //     //   console.log(this.nowChatUserRecordInfo,'this.nowChatUserRecordInfo')
                  //     // },2000)
                  //     break;
                  //   }
                  // }

                });
              }
            }
          } else {
            //如果当前列表没有消息记录那就直接更新列表
            this.quaryQuesList();
          } //10.23
          // let isBreak = false
          // for (let x in this.questionRecordList) {
          //   if (isBreak) {
          //     break
          //   }
          //   for (let i of this.questionRecordList[x].item) {
          //     if (i.id == data.recordQuestionId) {
          //       //这时候应该判断工单列表里面的数据长度及questionRecordList.item中的长度
          //       if (this.questionRecordList[x].item.length > 1) {
          //         await this.getPersonTicketList().then((res) => {
          //           let list = this.newDataToOldData(res)
          //           //先清空 
          //           this.questionRecordList[x].item = []
          //           for (let item of list) {
          //             this.questionRecordList[x].item.push(item.item[0])
          //           }
          //           //然后再拍一次顺序
          //           this.sortQuestionRecord(this.questionRecordList[x].item, this.origQuestionId)
          //           //排完之后更新一下已读未读状态
          //           this.updateQuestListStatus(this.questionRecordList[x], list[0])
          //         })
          //       } else {
          //         this.quaryQuesList()
          //       }
          //       isBreak = true
          //       break
          //     }
          //   }
          // }
          // if (!isBreak) {
          //   this.quaryQuesList()
          // }
          //10.23

        }
      } else if (data.msgType == 3) {
        if (!this.questionListObj[data.recordQuestionId]) {
          return;
        }

        for (let i of this.questionListObj[data.recordQuestionId]) {
          if (i.item.length > 1) {
            await this.getPersonTicketList().then(res => {
              let list = this.newDataToOldData(res); //清空之前存储一下数量
              //先清空 

              i.item = [];

              for (let item of list) {
                i.item.push(item.item[0]); ////如果就是正在处理的聊天界面就去更新状态

                if (item.item[0].id == this.nowChatUserRecordInfo.id) {
                  this.nowChatUserRecordInfo = item.item[0];
                }
              } //然后再拍一次顺序


              this.sortQuestionRecord(i.item, this.origQuestionId); //根本不用更新列表 有新工单数据就会重新更新 不存在数据数量不统一的情况
              // this.updateQuestionListObj(this.questionRecordList,false,i)
              //排完之后更新一下已读未读状态

              this.updateQuestListStatus(i, list[0]); //
              //恢复高度
              // this.$nextTick(() => {
              //   this.$refs.recordListRef.scrollTop = this.questionListScrollTop
              // })
            });
          } else {
            await this.getPersonTicketList(JSON.parse(i.user).id).then(res => {
              let list = this.newDataToOldData(res);
              i.item = [];

              for (let item of list) {
                ////如果就是正在处理的聊天界面就去更新状态
                if (item.item[0].id == data.recordQuestionId) {
                  i.item.push(item.item[0]); // this.nowChatUserRecordInfo = item.item[0]

                  this.updateQuestListStatus(i, item);
                }
              } // //先清空 
              // for (let item of list) {
              //   if (item.item[0].id == this.nowChatUserRecordInfo.id) {
              //     this.nowChatUserRecordInfo = item.item[0]
              //     // setTimeout(()=>{
              //     //   console.log(this.nowChatUserRecordInfo,'this.nowChatUserRecordInfo')
              //     // },2000)
              //     break;
              //   }
              // }

            }); // await this.quaryQuesList()
            //如果就是正在处理的聊天界面就去更新状态
            // for (let i of this.questionRecordList) {
            //   if (i.item[0].id == this.nowChatUserRecordInfo.id) {
            //     this.nowChatUserRecordInfo = i.item[0]
            //     break;
            //   }
            // }
            // //恢复高度
            // this.$nextTick(() => {
            //   this.$refs.recordListRef.scrollTop = this.questionListScrollTop
            // })
            //直接查询一次list列表
            // this.pageCountt = 1
            // this.isQueryRecord = false
            // const paramsTicket = {
            //   state: this.checkedSolveOptions.length == 3 ? [] : this.checkedSolveOptions,
            //   questionType: this.questionTypeSelected.length == this.questionTypeOptions.length ? [] : this.questionTypeSelected,
            //   personIds: this.questionCustomerIdArray.length == this.questionCustomerArray.length ? [] : this.questionCustomerIdArray,
            //   urgencyLevel: this.emergencyDegreeSelect.length == this.emergencyDegreeOption.length ? [] : this.emergencyDegreeSelect,
            //   clientName: this.clientName,
            //   directoryId: this.catalogId,
            //   currentPage: this.currentPage,
            //   pageSize: this.itemsPerPage,
            // }
            // this.queryRecordQuestionList(paramsTicket).then((res) => {
            //   this.questionRecordList = this.newDataToOldData(res.data.data.list)
            //   this.initOnlineData()
            // })
          }
        } //10.23
        // this.questionListScrollTop = this.$refs.recordListRef.scrollTop
        // let isBreak = false
        // for (let x in this.questionRecordList) {
        //   if (isBreak) {
        //     break
        //   }
        //   for (let i of this.questionRecordList[x].item) {
        //     if (i.id == data.recordQuestionId) {
        //       //这时候应该判断工单列表里面的数据长度及questionRecordList.item中的长度
        //       if (this.questionRecordList[x].item.length > 1) {
        //         await this.getPersonTicketList().then((res) => {
        //           let list = this.newDataToOldData(res)
        //           //先清空 
        //           this.questionRecordList[x].item = []
        //           for (let item of list) {
        //             this.questionRecordList[x].item.push(item.item[0])
        //             ////如果就是正在处理的聊天界面就去更新状态
        //             if (item.item[0].id == this.nowChatUserRecordInfo.id) {
        //               this.nowChatUserRecordInfo = item.item[0]
        //             }
        //           }
        //           //然后再拍一次顺序
        //           this.sortQuestionRecord(this.questionRecordList[x].item, this.origQuestionId)
        //           //排完之后更新一下已读未读状态
        //           this.updateQuestListStatus(this.questionRecordList[x], list[0])
        //           //
        //           //恢复高度
        //           // this.$nextTick(() => {
        //           //   this.$refs.recordListRef.scrollTop = this.questionListScrollTop
        //           // })
        //         })
        //       } else {
        //         await this.getPersonTicketList().then((res) => {
        //           let list = this.newDataToOldData(res)
        //           this.questionRecordList[x].item = []
        //           for (let item of list) {
        //             ////如果就是正在处理的聊天界面就去更新状态
        //             if (item.item[0].id == data.recordQuestionId) {
        //               this.questionRecordList[x].item.push(item.item[0])
        //               this.nowChatUserRecordInfo = item.item[0]
        //               this.updateQuestListStatus(this.questionRecordList[x], item)
        //               break;
        //             }
        //           }
        //           // //先清空 
        //           // for (let item of list) {
        //           //   if (item.item[0].id == this.nowChatUserRecordInfo.id) {
        //           //     this.nowChatUserRecordInfo = item.item[0]
        //           //     // setTimeout(()=>{
        //           //     //   console.log(this.nowChatUserRecordInfo,'this.nowChatUserRecordInfo')
        //           //     // },2000)
        //           //     break;
        //           //   }
        //           // }
        //         })
        //         // await this.quaryQuesList()
        //         //如果就是正在处理的聊天界面就去更新状态
        //         // for (let i of this.questionRecordList) {
        //         //   if (i.item[0].id == this.nowChatUserRecordInfo.id) {
        //         //     this.nowChatUserRecordInfo = i.item[0]
        //         //     break;
        //         //   }
        //         // }
        //         // //恢复高度
        //         // this.$nextTick(() => {
        //         //   this.$refs.recordListRef.scrollTop = this.questionListScrollTop
        //         // })
        //         //直接查询一次list列表
        //         // this.pageCountt = 1
        //         // this.isQueryRecord = false
        //         // const paramsTicket = {
        //         //   state: this.checkedSolveOptions.length == 3 ? [] : this.checkedSolveOptions,
        //         //   questionType: this.questionTypeSelected.length == this.questionTypeOptions.length ? [] : this.questionTypeSelected,
        //         //   personIds: this.questionCustomerIdArray.length == this.questionCustomerArray.length ? [] : this.questionCustomerIdArray,
        //         //   urgencyLevel: this.emergencyDegreeSelect.length == this.emergencyDegreeOption.length ? [] : this.emergencyDegreeSelect,
        //         //   clientName: this.clientName,
        //         //   directoryId: this.catalogId,
        //         //   currentPage: this.currentPage,
        //         //   pageSize: this.itemsPerPage,
        //         // }
        //         // this.queryRecordQuestionList(paramsTicket).then((res) => {
        //         //   this.questionRecordList = this.newDataToOldData(res.data.data.list)
        //         //   this.initOnlineData()
        //         // })
        //       }
        //       //恢复高度
        //       // this.$nextTick(() => {
        //       //   this.$refs.recordListRef.scrollTop = this.questionListScrollTop
        //       // })
        //       isBreak = true
        //       break
        //     }
        //   }
        // }
        //10.23

      } else if (data.msgType == 8) {
        //页面总未读消息变化
        this.pageTotalUnred = data.msgContent;
      } else if (data.msgType == 9) {
        //匿名用户变为登录用户
        //  sopClickData
        //模拟点击上一次的sop树
        this.getTotalUnreadNum();
        await this.clickQuestionCatalog(this.sopClickData); //查询完成之后判断自己正处于的聊天信息是否还是匿名用户

        let nowUserName = JSON.parse(this.nowChatUserRecordInfo.user)?.name || ''; //如果是匿名用户就用 聊天窗id 去匹配

        if (nowUserName.slice(0, 4) == '匿名用户') {
          for (let item of this.questionRecordList) {
            if (item.item[0].id == this.nowChatUserRecordInfo.id) {
              this.nowChatUserRecordInfo = item.item[0];
              break;
            }
          }
        }
      } else if (data.msgType == 11) {
        this.update();
      }
    },

    //切换工作状态
    workStatusChange(e) {
      // console.log(e.target.value, 'valvalvalval');
      if (e.target.value == 0) {
        //下工
        this.changeWorkStatus().then(res => {
          this.workStatusVal = res.data.data.workStatus;
          this.update(); //清空聊天窗信息
          // this.nowChatUserRecordInfo = []
        });
      } else if (e.target.value == 1) {
        //上工
        this.changeWorkStatus().then(res => {
          this.workStatusVal = res.data.data.workStatus;
          this.update();
        });
      } else if (e.target.value == 2) {
        //退出登录
        this.dialogLogout = true; // this.logout()
      }
    },

    //退出登录确认按钮
    async clickLogout(val) {
      if (val) {
        await this.logout(); // this.ElMessage('退出成功')

        ElMessage.success('退出成功');
        this.$router.go(0); // 路由跳转
      } else {
        this.dialogLogout = false;
        this.info().then(res => {
          // this.workStatusVal = res.data.data.workStatus
          this.workStatusVal = res.data.data.workStatus;
        });
      }
    },

    //admin 退出登录
    async logout() {
      const res = await this.$axios.post('/javaplus/admin/login/logout');

      if (res.data.code != 200) {
        this.notify({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //修改用户工作状态
    async changeWorkStatus() {
      const res = await this.$axios.post('/javaplus/admin/personal/changeWorkStatus');

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      } else {
        ElNotification({
          title: '成功',
          message: res.data.message,
          type: 'success'
        });
      }

      return res;
    },

    //心跳消息
    heartWebSocketOpen() {
      if (this.newSocket.readyState == 1) {
        this.newSocket.send('ping');
        console.log('HeartMessage send');
      } else {
        clearInterval(this.heartMessageTimer);
        this.init_newSocket();
      } // console.log("WebSocket连接成功");

    },

    initOnlineData() {
      setTimeout(() => {
        if (this.questionRecordList.length) {
          for (let record of this.questionRecordList) {
            //先置灰
            record.isOnLine = false;

            for (let online of this.onLineIdList) {
              // console.log(record,'recordrecord');
              if (JSON.parse(record?.user)?.id == online) {
                record.isOnLine = true;
                break;
              }
            }
          }
        } else {
          this.initOnlineData();
        }
      }, 1000);
    },

    // sendMessage_webSocket() {
    //   if (this.newSocket.readyState == 1) {
    //     this.newSocket.send('ping')
    //   }
    // },
    //判断客服是否在线
    async getOnLineIdList(params) {
      const res = await this.$axios.post('/javaplus/admin/loginCookie/getOnLineIdList', params);

      if (res.data.code != 200) {// ElNotification({
        //   title: '错误',
        //   message: res.data.message,
        //   type: 'error',
        // });
      }

      return res;
    },

    //获取用户信息
    async info() {
      const res = await this.$axios.post('/javaplus/admin/personal/info');

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //新websocket发消息
    async sendMessage_new(params) {
      const res = await this.$axios.post('/javaplus/admin/webSocket/sendMessage', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //新数据转久数据格式
    newDataToOldData(array) {
      let newArray = [];

      for (let item of array) {
        let obj = {
          item: [{
            user_unread: item.userUnread,
            is_email: '',
            create_time: this.dataTimeFormatter(item.createTimestamp),
            admin_unread: item.adminUnread,
            urgrncy_level: item.urgencyLevel,
            area_type_id: '',
            title: item.title,
            question_id: item.questionId,
            question_type_id: item.questionTypeAreaId,
            request_score: item.scoreStatus,
            is_solved: item.isSolved,
            production_id: item.productionId,
            complete_time: this.dataTimeFormatter(item.completeTimestamp),
            step_record: "",
            comment: item.comment,
            type_title: item.typeTitle,
            id: item.id,
            personalId: item.personalId,
            directory_id: item.sopDirectoryId,
            sopDirectoryName: item.sopDirectoryName,
            productionName: item.productionName,
            customer_id: item.customerId,
            customer_Name: item.customerName,
            user: JSON.stringify(item.personal),
            sop_question_id: item.sopQuestionId,
            change_time: this.dataTimeFormatter(item.updateTimestamp),
            carryOver: item.carryOver,
            isAbnormal: item.isAbnormal,
            totalUnreadNum: item.totalUnreadNum,
            totalIsSolvedNum: item.totalIsSolvedNum,
            mark: item.mark
          }],
          quantity: 0,
          unread: item.totalUnreadNum,
          user: JSON.stringify(item.personal),
          unsolved: item.totalIsSolvedNum,
          last_change_time: this.dataTimeFormatter(item.updateTimestamp)
        };
        newArray.push(obj);
      }

      return newArray;
    },

    userNewChatRecordToOld(array) {
      let newArray = [];

      for (let item of array) {
        let obj = {
          id: item.id,
          question_record_id: item.recordQuestionId,
          time: this.getLocalTime(item.timestamp),
          user: item.personal,
          person_id: item.personalId,
          content: item.content,
          delete: item.isDelete,
          messageType: item.messageType,
          new_content: item.content
        };
        newArray.push(obj);
      }

      return newArray;
    },

    async initlocalStorage() {
      if (localStorage.getItem('userTicketList')) {
        this.questionRecordList = JSON.parse(localStorage.getItem('userTicketList')); // console.log(this.questionRecordList, 'this.questionRecordListthis.questionRecordList');
      }

      if (localStorage.getItem('nowChatUserRecordInfo')) {
        this.nowChatUserRecordInfo = JSON.parse(localStorage.getItem('nowChatUserRecordInfo'));
      } //查询一次消息记录


      await this.queryChatRecord(true); // if (localStorage.getItem('chatRecordList_new')) {
      //   this.chatRecordList_new = JSON.parse(localStorage.getItem('chatRecordList_new'))
      // }
      // this.dealCharRecord()

      if (localStorage.getItem('userTicketList')) {
        this.questionRecordList = JSON.parse(localStorage.getItem('userTicketList')); // console.log(this.questionRecordList, 'this.questionRecordListthis.questionRecordList');
      }

      if (localStorage.getItem('catalogId')) {
        this.catalogId = JSON.parse(localStorage.getItem('catalogId')); // console.log(this.questionRecordList, 'this.questionRecordListthis.questionRecordList');
      }

      if (localStorage.getItem('scrollHeight')) {
        this.chatScrollData.scrollHeight_old = JSON.parse(localStorage.getItem('scrollHeight')); // console.log(this.questionRecordList, 'this.questionRecordListthis.questionRecordList');
      }

      if (localStorage.getItem('userAccount')) {
        this.userAccount = JSON.parse(localStorage.getItem('userAccount'));
      }

      if (localStorage.getItem('sopClickData')) {
        this.sopClickData = JSON.parse(localStorage.getItem('sopClickData'));
      } // localStorage.setItem('sopClickData', JSON.stringify(this.sopClickData))
      // localStorage.setItem('userAccount', JSON.stringify(this.userAccount))
      // console.log(this.questionRecordList, 'this.questionRecordList');
      // console.log(this.chatRecordList_new, 'this.chatRecordList_new');
      // console.log(this.nowChatUserRecordInfo, 'this.nowChatUserRecordInfo');

    },

    //切换聊天框 用于查询第一页的聊天记录 或 更新
    queryChatRecord(isFirst) {
      //加载中
      // this.loadingMessage = true
      const params = {
        recordQuestionId: this.nowChatUserRecordInfo.id // startTimestamp: this.dataRange.startTimestamp,
        // endTimestamp: this.dataRange.endTimestamp,

      };
      this.queryByRecordQuestionIdAndTimestamp(params).then(res => {
        if (!res.data.data.recordChatList.length) {
          this.chatRecordList_new = [];
          this.loadingMessage = false;
        } else {
          this.$nextTick(() => {
            //让报损单更新
            this.chatRecordList_new = [];
            this.$nextTick(() => {
              this.chatRecordList_new.unshift(...this.userNewChatRecordToOld(res.data.data.recordChatList));
              this.dealCharRecord();
              this.$nextTick(() => {
                setTimeout(() => {
                  const el = this.$refs.chat;
                  let height = this.chatScrollData.scrollHeight_old;
                  this.chatScrollData.scrollHeight_old = el.scrollHeight; // localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))

                  el.scrollTop = el.scrollHeight; //加载结束 返回

                  this.loadingMessage = false;
                  this.loadingMessage = false;
                }, 100);
              });
            });
          });
        }
      });
    },

    // async getSopTreeList(params){
    // const res = await this.axios.post('/javaplus/user/admin/sopDirectory/getSopTreeList', params)
    // if (res.data.code != 200) {
    // this.notify({
    // title: '错误',
    // message: res.data.message,
    // type: 'error',
    // });
    // }
    // return res
    // },
    //更新工单备注
    async updateRecordQuestionComment(params) {
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/updateRecordQuestionComment', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      ElNotification({
        title: '成功',
        message: '更新成功',
        type: 'success',
        duration: 1500
      });
      localStorage.setItem('userTicketList', JSON.stringify(this.questionRecordList));
      return res;
    },

    //更新工单分派客服
    async updateRecordQuestionCustomerId(params) {
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/updateRecordQuestionCustomerId', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      ElNotification({
        title: '成功',
        message: '分派成功',
        type: 'success',
        duration: 1500
      });
      return res;
    },

    //更新工单sop目录id
    async updateRecordQuestionSopDirectoryId(params) {
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/updateRecordQuestionSopDirectoryId', params);

      if (res.data.code !== 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      ElNotification({
        title: '成功',
        message: res.data.msg,
        type: 'success',
        duration: 1500
      }); // localStorage.setItem('userTicketList', JSON.stringify(this.questionRecordList))

      return res;
    },

    //根据工单id和日期范围查询聊天记录
    async queryByRecordQuestionIdAndTimestamp(params) {
      const res = await this.$axios.post('/javaplus/admin/recordChat/queryByRecordQuestionIdAndTimestamp', params);

      if (res.data.code !== 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      return res;
    },

    //根据工单id更新对应未读数为0
    async updateRecordQuestionUnread(params) {
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/updateRecordQuestionUnread', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //聊天框滚轮滚动事件
    chatScroll() {
      return;
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        const el = this.$refs.chat;

        if (el.scrollTop == 0) {
          //注释
          // this.dataRange = this.getWeekTimestamps(this.weeks++)
          // const params = {
          //   recordQuestionId: this.nowChatUserRecordInfo.id,
          //   startTimestamp: this.dataRange.startTimestamp,
          //   endTimestamp: this.dataRange.endTimestamp,
          // }
          // this.queryByRecordQuestionIdAndTimestamp(params).then((res) => {
          //   if (res.data.data.length) {
          //     this.chatRecordList_new.unshift(...this.userNewChatRecordToOld(res.data.data))
          //     this.dealCharRecord()
          //     this.$nextTick(() => {
          //       // const el =dou  getElementsByClassName('.chatdiv')
          //       setTimeout(() => {
          //         const el = this.$refs.chat
          //         let height = this.chatScrollData.scrollHeight_old
          //         this.chatScrollData.scrollHeight_old = el.scrollHeight
          //         localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))
          //         el.scrollTop = el.scrollHeight - height
          //       }, 50)
          //     })
          //   } else {
          //     ElMessage('已经到底了')
          //   }
          // })
          //注释
          this.queryChatRecord(false);
        } else {}
      }, 100); // console
    },

    getWeekTimestamps(weeksAgo) {
      // console.log(weeksAgo,'weeksAgoweeksAgoweeksAgo')
      const now = new Date(); // 当前时间

      const startOfWeek = new Date(now); // 起始时间（当前时间）

      const endOfWeek = new Date(now); // 结束时间（当前时间）

      const midTime = new Date(); // 中转时间
      // 获取当前日期是星期几 (0是周日, 1是周一, ..., 6是周六)

      const dayOfWeek = now.getDay(); // 计算到本周一的日期差值
      // const diffToMonday = (dayOfWeek + 6) % 7;
      // 设置 startOfWeek 为本周的周一
      // midTime.setDate();
      // 计算过去的第几周的开始时间

      startOfWeek.setDate(midTime.getDate() - weeksAgo * 30);
      endOfWeek.setDate(midTime.getDate() - (weeksAgo - 1) * 30);
      startOfWeek.setHours(0, 0, 0, 0);
      endOfWeek.setHours(23, 59, 59, 999);
      return {
        startTimestamp: startOfWeek.getTime(),
        endTimestamp: endOfWeek.getTime()
      };
    },

    async queryRecordQuestionList(params) {
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/queryRecordQuestionList', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //对消息记录的进行处理方法
    dealCharRecord() {
      const data = this.chatRecordList_new.map(data => {
        return { ...data // time: this.$moment.utc(data.time).local().format('YYYY-MM-DD HH:mm:ss')
          // time: this.dataTimeFormatter(data.timestamp)

        };
      });
      this.chatRecordList_new = data;
      this.initPreviewImg(this.chatRecordList_new);
      this.$nextTick(() => {
        this.addChatImgClick(); // this.addTicketTitleHover()
        // this.addChatBtnClick()
        // this.addTicketBodyCancel(this.chatRecordList_new)
        // this.replaceTicketBtn()
      });
    },

    //根据工单id更新工单是否已解决
    async updateIsSolvedById(params) {
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/updateIsSolvedById', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      } else {
        ElNotification({
          title: '成功',
          message: res.data.message,
          type: 'success'
        });
        return res;
      }
    },

    //根据状态返回报损单状态文本
    getTempTextByStatus(status) {
      if (status == '1') {
        return '待填报';
      } else if (status == '2') {
        return '待审核';
      } else if (status == '3') {
        return '待补充填报';
      } else if (status == '4') {
        return '审核完成';
      } else if (status == '5') {
        return '作废';
      }
    },

    //点击查看报损单记录
    viewTemplateRecord(row) {
      this.loadingMessage = true; // console.log(row,'row');

      this.isShowTable = false;
      this.isTemplShow = false;
      this.$nextTick(() => {
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        this.getShowDamageReportById({
          id: row.id
        }).then(res => {
          this.damageReportContent = res.data.data;
          this.$nextTick(() => {
            this.templateDemoShow = true;
            this.loadingMessage = false;
          }); // this.$nextTick(() => {
          //   this.$nextTick(()=>{
          //       this.templateDemoShow = true
          //       this.loadingMessage = false                      
          //              // this.templateDemoShow = true
          //   })
          //   // this.templateDemoShow = true
          //   // this.loadingMessage = false
          // })
        });
      }); // this.getShowDamageReportById({ id: row.id }).then((res) => {
      //       this.damageReportContent = res.data.data
      //       this.$nextTick(() => {
      //         this.$nextTick(()=>{
      //             this.templateDemoShow = true
      //             this.loadingMessage = false                      
      //                    // this.templateDemoShow = true
      //         })
      //         // this.templateDemoShow = true
      //         // this.loadingMessage = false
      //       })
      // })
    },

    //时间戳转化为 年月日 时分秒 格式化
    dataTimeFormatter(timeStamp) {
      if (timeStamp === 'null' || !timeStamp) {
        return 'null';
      }

      timeStamp = Number(timeStamp);
      let time = new Date(timeStamp);
      let y = time.getFullYear(); // 年份

      let M = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      ; // 月份（注意月份是从0开始的，所以需要+1）

      let d = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate(); // 日期

      let h = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours(); // 小时

      let m = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes(); // 分钟

      let s = time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds(); // 秒数

      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },

    //点击消息的回调
    chatClicked(chat) {
      // this.withdrawShow = true
      this.selectChatId = chat.id; // console.log(chat,'iddddd');
      // chat.id
    },

    //    //客服查看报损单中返回
    returnPreView() {
      this.templateDemoShow = false;
    },

    //getTicketData 报损单demo把数据给ticket 并且发送报损单填报卡
    async getTicketDataSend(data) {
      // console.log(this,'111222333');
      this.loadingMessage = false; // this.popShow_1 = false
      // this.tempTicketData = data
      // this.templateDemoShow = false
      // this.$nextTick(()=>{
      //   this.popShow_1 = true
      // })

      this.popShow_1 = false;
      this.tempTicketData = data;
      await this.damageR({
        languageType: this.tempTicketData.languageType
      }).then(res => {
        this.damageRTextL = res.data.data;
      });
      this.templateDemoShow = false;
      const kd = new KeyboardEvent('keydown', {
        key: 'Enter',
        // 按键的名称
        code: 'Enter',
        // 按键的代码
        keyCode: 13,
        // 按键的代码（旧版兼容性）
        which: 13,
        // 按键的代码（旧版兼容性）
        bubbles: true,
        // 事件是否会冒泡
        cancelable: true // 事件是否可以取消

      });
      this.$nextTick(async () => {
        // console.log(this.sendContent, 'this.sendContent121212');
        // this.$refs.ticket.$el.querySelector('.chatBtn').children[0].innerHTML = '点击填报'
        setTimeout(async () => {
          // this.sendContent = this.$refs.ticket.$el.innerHTML
          this.sendContentTicket = {
            id: data.id,
            status: "1"
          }; // let html = this.$refs.ticket.$el.innerHTML.querySelector('.')
          // console.log(this.$refs.ticket.$el.querySelector('.chatBtn').children[0].innerHTML, 'this.sendContent121212');

          await this.clickSendContent(kd, data);
          this.popShow_1 = true;
          this.clearPopData = true;
          this.sendContent = '';
          this.tempTicketData = {}; //更新树列表 

          this.getAllDirectoryList(); //更新当前工单信息
          // this.updateNowTicketInfo()
        }, 1000);
      });
    },

    //进入demo选择取消或者上一步
    returnLastStep() {
      this.templateDemoShow = false;
      this.dialogVisible = true;
    },

    //拿到并且处理templatePop打包好的数据getTempData
    getTemplatePopData(data) {
      this.loadingMessage = true;
      this.damageReportContent = [];
      this.templateDemeData = data;
      this.getShowTempItemById({
        id: data.tempItemId
      }).then(res => {
        this.damageReportContent = res.data.data;
        this.$nextTick(() => {
          // console.log(this.damageReportContent, '发布的时候');
          this.clearPopData = false;
          this.templateDemoShow = true;
          this.dialogVisible = false;
        }); // console.log(this.damageReportContent);
      });
    },

    //关闭templatepop弹窗并清空消息
    closeTemplatePop() {
      if (this.clearPopData) {
        this.dialogVisible = false;
        this.popShow_1 = false;
        this.$nextTick(() => {
          this.popShow_1 = true;
        });
      } else {
        this.clearPopData = true;
      }
    },

    //撤销报损单
    chTicket() {
      this.chShow = false;
      this.loadingMessage = true;
      this.withdrawalChatRecord(this.selectChatId).then(() => {
        this.loadingMessage = false;
      }); // this.repealDamageReport({ id: Number(this.chTicketId), adminId: this.user.id }).then(() => {
      //   this.loadingMessage = false
      // })
    },

    //
    // /javaplus/admin/recordChat/deleteRecordChatById/
    async withdrawalChatRecord(id) {
      const res = await this.$axios.post('/javaplus/admin/recordChat/deleteRecordChatById/' + id);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      } else {
        ElNotification({
          title: '成功',
          message: res.data.message,
          type: 'success'
        });
      }

      return res;
    },

    async repealDamageReport(params) {
      const res = await this.$axios.post('/javaplus/afterSale/damageReport/repealDamageReport', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      } else {
        ElNotification({
          title: '成功',
          message: res.data.message,
          type: 'success'
        });
      }

      return resget;
    },

    //替换所有ticket的按钮 还有状态文本
    replaceTicketBtn() {
      let chatDivArray = document.getElementsByClassName("chatBtnDiv");
      let ticketStatus = document.getElementsByClassName("ticketTempStatus"); // console.log(ticketStatus[0].innerHTML, 'ticketStatus');

      for (let div of chatDivArray) {
        div.outerHTML = `<div class="chatBtnDiv" style="display: flex; justify-content: center;margin: 20px 0 15px 0 ;"><button class="el-button el-button--danger chatBtn" aria-disabled="false" type="button" style="background-color: rgb(223, 48, 51); width: 80px;"><!--v-if--><span class="">查看</span></button></div>`;
      }

      for (let status of ticketStatus) {
        if (status.innerHTML == '等待填报') {
          status.innerHTML = "等待用户填报";
        } else if (status.innerHTML == '等待补充填报') {
          status.innerHTML = "等待用户补充填报";
        }
      }
    },

    //[FAQ]添加报损单聊天记录ID
    async addChatRecordId(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/addChatRecordId', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //点击弹窗关闭按钮的回调 
    diaClose() {
      this.$refs.templatePop.cancel();

      if (this.clearPopData) {} else {
        this.clearPopData = true;
      }
    },

    //根据报损单ID查询报损单详情
    async getShowDamageReportById(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getShowDamageReportById', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //给每个图片绑定点击方法
    addChatImgClick() {
      const it = this;
      var chatImgArray = document.getElementsByClassName("chatImg"); // console.log(chatImgArray, 'chatImgArray');

      for (var img of chatImgArray) {
        img.onclick = e => {
          // console.log(e.target.dataset.index, 'e.target.dataset.index');
          var index = e.target.dataset.index;
          it.imgIndex = Number(index);
          it.imgViewerShow = true;
        };
      }
    },

    //给每一个报损单的标题增加悬浮窗
    addTicketTitleHover() {
      const toolTips = document.querySelector('.toolTips');
      var ticketTitleH = document.getElementsByClassName("ticketTitle"); // console.log(ticketTitleH, 'ticketTitleH')

      for (let title of ticketTitleH) {
        title.addEventListener('mouseover', e => {
          // console.log(e, '2323232')
          this.toolTipShow = true;
          const rect = title.getBoundingClientRect();
          toolTips.innerText = e.target.innerText;
          toolTips.style.left = `${e.clientX - 100}px`;
          toolTips.style.top = `${e.clientY - 35}px`; // tooltip.style.display = 'block'
        });
        title.addEventListener('mouseleave', e => {
          this.toolTipShow = false;
        });
      }
    },

    //给每个按钮绑定点击方法
    addChatBtnClick(fn) {
      this.damageReportContent = [];
      const it = this;
      var chatBtnArray = document.getElementsByClassName("chatBtn"); // console.log(chatImgArray,'chatImgArray');

      for (var Btn of chatBtnArray) {
        Btn.onclick = e => {
          this.loadingMessage = true;

          if (e.target.tagName === 'SPAN') {
            // console.log('t',);
            this.ticketTempId = e.target.parentElement.parentElement.parentElement.querySelector('[templateid]').getAttribute('templateid');
          } else {
            // console.log('t',);
            this.ticketTempId = e.target.parentElement.parentElement.querySelector('[templateid]').getAttribute('templateid');
          }

          this.getShowDamageReportById({
            id: this.ticketTempId
          }).then(res => {
            this.damageReportContent = res.data.data;
            this.$nextTick(() => {
              this.templateDemoShow = true;
              this.loadingMessage = false;

              if (fn) {
                fn();
              }
            });
          }); // console.log(e.target.parentElement,'clickkkk');
        };
      }
    },

    //给每个ticket添加右键撤销事件
    addTicketBodyCancel(chatRecord) {
      // for(let chat of chatRecord){
      // }
      var chatTicketArray = document.getElementsByClassName("ticketBody");
      let it = this;

      for (let tkt of chatTicketArray) {
        tkt.addEventListener('contextmenu', function (event) {
          event.preventDefault(); // 阻止默认右键菜单的弹出
          // console.log(event, 'eventeventevent');regRet2
          // console.log(this.querySelector('.ticketTempStatus')?.innerHTML, 'this');

          if (this.querySelector('.ticketTempStatus')?.innerHTML == '等待用户填报') {
            // console.log(, 'eventeventevent');
            it.chTicketId = this.querySelector('.saveId').getAttribute('templateid');
            it.chTop = event.clientY;
            it.chLeft = event.clientX;
            it.chShow = true;

            if (it.chShowTimer) {
              clearTimeout(it.chShowTimer);
            }

            it.chShowTimer = setTimeout(() => {
              it.chShow = false;
              it.selectChatId = null;
            }, 2000);
          } // console.log(this.chShow);

        });
      }
    },

    // 初始化图片预览
    initPreviewImg(data) {
      // console.log(data,'datadatadata');
      //先清空一次数组
      // this.imgArray = []
      this.btnArray = []; // var index = 0
      // console.log(data,'chatRecordList');

      for (var chatId in data) {
        var chat = data[chatId]; // 搜索图片，替换为element框架<el-image>

        var reg1 = new RegExp(/<img src=['|"]([\s\S]+?)['|"]/, 'g'); //搜索按钮 给按钮加上类名 然后补上方法
        // var reg2 = new RegExp(/<button\b[^>]*>([\s\S]*?)<\/button>/gi);
        // var regDiv = new RegExp(/<div\b[^>]*\bclass\s*=\s*["']chatBtnDiv["'][^>]*>(.*?)<\/div>/gi);

        var regRet1 = reg1.exec(chat.content); // console.log(chat.content,'regRet1')
        // var regRet2 = reg2.exec(chat.content)
        // var regRet3 = regDiv.exec(chat.content)
        // console.log(regRet2,'regRet2regRet2regRet2');
        // if (_.isEmpty(regRet2) || regRet2.length <= 1) {
        // } else {
        //   var btnMatch = regRet2.slice(0, 1);
        //   // this.btn
        //   // console.log(btnMatch,'btnMatchbtnMatch');
        //   this.btnArray.push(btnMatch[0])
        //   chat.content = chat.content.replace(
        //     /<button\b([^>]*)class=['"]([^'"]*)['"]([^>]*)>/gi,
        //     '<button $1class="$2 chatBtn" $3>'
        //   );
        // }
        // console.log(this.btnArray);

        if (_.isEmpty(regRet1) || regRet1.length <= 1) {} else {
          var imgMatch = regRet1.slice(1); // console.log(imgMatch,'imgMatchimgMatch');

          for (var src of imgMatch || []) {
            this.imgArray.push(src);
            chat.content = chat.content.replace(/<img src=/, "<img class='chatImg' class='' data-index=" + this.chatImgIndex + " src=");
            this.chatImgIndex++;
          }
        }

        var reg2 = new RegExp(/<video class="ql-video" controls="true" preload="none" src=['|"]([\s\S]+?)['|"]/, 'g');
        var regRet2 = reg2.exec(chat.content);

        if (_.isEmpty(regRet2) || regRet2.length <= 1) {} else {
          var videoMatch = regRet2.slice(1);

          for (var src of videoMatch || []) {
            chat.content = chat.content.replace(/<video class="ql-video" controls="true" preload="none" src=/, '<video width="100%" x5-video-player-type="h5" x5-playsinline="true"  webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" class="ql-video" controls="true" preload="none" src=');
          }
        }
      } // console.log(this.imgArray, 'ddddata');
      // this.chatRecordList

    },

    //根据模板条目id查询报损单模板条目内容
    // async getShowTempItemById(params) 
    //   const res = await this.$axios.post('/java/afterSale/damageReport/getShowTempItemById', params)
    //    (res.data.code != 200) {
    //     .$notify({
    //        '错误',
    //       e: res.data.message,
    //       'error',
    //                 turn res
    // },
    async getShowTempItemById(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getShowTempItemById', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //[FAQ]根据账户查询报损单列表
    async getDamageReportListByAccount(params) {
      const res = await this.$axios.post('/api/getDamageReportListByAccount', params);
      this.tempTableLoading = false;

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //点击报损单
    async clicktempl() {
      // await this.getOrderByUser()
      // this.loadingMessage = true
      // console.log(this.userAccount, 'questionRecordId');
      if (Object.keys(this.nowChatUserRecordInfo).length == 0) {
        // ElMessage.error('请选择登录用户工单')
        return;
      }

      if (this.workStatusVal) {// return true
      } else {
        ElMessage.error('请先上工后再进行操作');
        return; // return false
      }

      if (this.userAccount) {
        this.$nextTick(() => {
          this.dialogVisible = true;
        });
      } else {// ElMessage.error('请选择登录用户工单')
      }
    },

    // 下载发票
    async clickDownload(row) {
      if (row.sales_id == null || row.sales_id == '') {
        ElNotification({
          title: '错误',
          message: '销售ID为空',
          type: 'error',
          duration: 1500
        });
        return;
      }

      const res = await this.$axios.post('/api/admin/Base/orderdownloadInvoice', {
        orderIdArray: [row.order_id]
      });

      if (res.data.errno != 0) {
        ElNotification({
          title: '请求失败',
          message: res.data.errmsg,
          type: 'error'
        });
        return;
      }

      const _data = Buffer.from(res.data.data, 'binary');

      const _blob = new Blob([_data], {
        type: 'application/zip'
      });

      const _url = window.URL.createObjectURL(_blob);

      const a = document.createElement('a');
      a.download = '发票.zip';
      a.href = _url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getDictDataList() {
      const res = await this.$axios.post('/javaplus/admin/sysDictData/getUrgencyLevelList');

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error',
          duration: 1500
        });
        return;
      }

      this.emergencyDegreeOption = res.data.data.map(data => {
        return { ...data,
          id: data.dictValue,
          val: data.dictLabel + '(' + data.dictValue + ')'
        };
      });
    },

    orderType(orderType) {
      if (orderType == 1) {
        return '调货';
      } else if (orderType == 2) {
        return '现货';
      } else if (orderType == 3) {
        return '预购';
      } else {
        return '未知';
      }
    },

    // 显示物流信息
    async clickShowTable(item, row) {
      const a = document.createElement('a');
      a.target = '_blank'; // shipping_method
      // FEDEX

      let url = '';

      if (row.area === 'global' && row.shipping_method.toUpperCase() == 'DHL') {
        url = `https://www.dhl.com/cn-zh/home/tracking.html?tracking-id=${item}`;
      } else if (row.area === 'global') {
        url = `https://www.fedex.com/fedextrack/?trknbr=${item}`;
      } else if (row.area === 'china') {
        url = `https://www.sf-express.com/chn/sc/waybill/waybill-detail/${item}`;
      } else {
        url = `https://t.17track.net/en#nums=${item}`;
      }

      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // const res = await this.$axios.post('/api/admin/Base/getOrderTrackingStatus', {
      //   waybillNum: item
      // })
      // if (res.data.errno != 0) {
      //   ElNotification({
      //     title: '失败',
      //     message: res.data.errmsg,
      //     type: 'error',
      //     duration: 1500
      //   })
      //   return
      // }
      // this.logisticsInformationData = res.data.data
      // for (const data of res.data.data.trjectoryInfoArray) {
      //   this.logisticsInformation = data
      // }
      // this.dialogTableVisible = true
    },

    // 全局监听点击事件
    hideRecallMenuOnClickOutside(event) {
      for (const chats of this.chatRecordList) {
        chats.showRecall = false;
      }
    },

    chatRecall(e, chat) {
      e.preventDefault();
      this.withdrawShow = true; // if (chat.person_id == 0 || chat.id !== this.newChatRecordList.last_data.id) {
      //   return
      // }

      for (const chats of this.chatRecordList_new) {
        chats.showRecall = false;
      }

      this.menuTop = e.clientY;
      this.menuLeft = e.clientX;
      chat.showRecall = true;
    },

    clickChat(chat) {
      // 判断聊天内容是否是图片标签
      const imgRegex = /<img\b[^>]*>/i;
      const containsImgTag = imgRegex.test(chat.content); // console.log(containsImgTag, 'containsImgTagcontainsImgTag');

      if (!containsImgTag) {
        return;
      } // // 记录所有图片信息,并将图片替换为element框架<el-image>


      const imgArray = []; // var index = 0
      // 搜索src

      var reg = /<img[^>]*\bsrc=['"]([^'"]*)['"]/ig;
      var match;

      while ((match = reg.exec(chat.content)) !== null) {
        // match[1]是捕获组匹配的内容，即src属性的值
        // console.log(match, 'matchmatchmatch');
        const src = match[1];
        imgArray.push(src); // chat.content = chat.content.replace(`<img src="${src}"`, `<img class='chatImg' style='width:20rem;' data-index=${index} src='${src}'`);
        // index++;
      }

      this.imgArray = imgArray;
      this.imgIndex = 0;
      this.imgViewerShow = true; // this.$nextTick(() => {
      //   this.addChatImgClick()
      // })
    },

    update() {
      this.updateTimer && clearTimeout(this.updateTimer);
      this.loadingMessage = true;
      this.isLoading = true;
      this.updateTimer = setTimeout(async () => {
        // this.queryQuestionRecordList()
        this.getAllDirectoryList();
        this.loadingMessage = false;
        this.isLoading = false;
      }, 1000);
    },

    startDrag(event) {
      event.preventDefault();
      document.addEventListener('mousemove', this.handleDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },

    handleDrag(event) {
      const container = this.$refs.dragContainer;
      const mouseX = event.pageX;
      const newWidth = mouseX - container.getBoundingClientRect().left;
      container.style.width = `${newWidth}px`;
    },

    stopDrag() {
      document.removeEventListener('mousemove', this.handleDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },

    copyContent(record) {
      this.copyId = record.id;
      const textToCopy = record.type_title;
      const el = document.createElement('textarea');
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      ElNotification({
        title: '成功',
        message: '复制成功',
        type: 'success',
        duration: 1500
      });
    },

    // 点击问题
    async clickQuestionRecord(record, index) {
      //消息加载
      this.loadingMessage = true;
      this.chatRecordList_new = [];
      this.loadingMessage = true; //回到聊天列表
      // this.clickViewQuestionStep()
      //先关闭报损单

      this.demoClose(); // this.isShowTable = false
      // this.isTemplShow = false

      this.isTicketListChangeNow = true; //c
      // const account = JSON.parse(record.user).account
      // this.userAccount = account
      //点击问题的时候 重置照片index 并且清空照片数组

      this.chatImgIndex = 0;
      this.imgArray = [];
      this.nowChatUserRecordInfo = record; // localStorage.setItem('nowChatUserRecordInfo', JSON.stringify(this.nowChatUserRecordInfo))

      if (record) {
        this.recordData = record;
      }

      this.userAccount = JSON.parse(this.nowChatUserRecordInfo?.user)?.account; // localStorage.setItem('userAccount', JSON.stringify(this.userAccount))
      // console.log(this.userAccount,'this.userAccount;')
      // this.getOrderByUser(record)
      //切换聊天框的时候直接重新查客户订单列表 和报损单记录

      this.getOrderByUser(record);
      this.clicktemplRecord(); // localStorage.setItem('recordData', JSON.stringify(this.recordData))
      // if (record) {
      //   this.getUserChatRecordListByQuestionRecordId(record)
      // }
      //   
      //拿到结构 每次点击保持滚动条高度   

      this.questionListScrollTop = this.$refs.recordListRef.scrollTop;
      this.selectItem = record.id;
      this.customerOrderListData = []; // this.getOrderByUser(record)
      // this.queryTrackingStatus()
      // /recordQuestion/queryRecordQuestionByPersonalId/{personalId}
      // '/java/front/getUserAllyQuestionRecordListByQuestionRecordId?questionRecordId=' + record.id
      // console.log(record.personalId,'recordrecordrecord')
      //清除未读消息
      // await this.updateRecordQuestionUnread({ recordQuestionId: this.nowChatUserRecordInfo.id }).then((res) => {
      // })
      // const clickedItem = res.data.data;
      // console.log(record.personalId, 'record.personalId')
      // console.log(this.questionRecordList, 'recordrecordrecord')
      // console.log(this.newDataToOldData(res.data.data), 'recordrecorressssssssssssssssss')
      // console.log(index,'index');

      if (index) {
        //当点击大于0
        //如果已经展开就要更新目前的列表 消除未读
        const res = await this.$axios.post('/javaplus/admin/recordQuestion/queryRecordQuestionByPersonalId/' + JSON.parse(this.nowChatUserRecordInfo.user).id);

        if (res.data.code != 200) {
          ElNotification({
            title: '失败',
            message: res.data.msg,
            type: 'error',
            duration: 1500
          });
          return;
        }

        if (res.data.data == null || _.isEmpty(res.data.data)) {
          ElNotification({
            title: '提示',
            message: '暂无数据',
            type: 'error',
            duration: 1500
          });
        } // console.log(this.questionRecordList[0].item,'123');
        // console.log(this.newDataToOldData(res.data.data) ,'321');
        //9.18注释


        let isBreak = false;

        for (let i of this.questionRecordList) {
          if (isBreak) {
            break;
          }

          for (let x in i.item) {
            if (i.item[x].id == this.origQuestionId) {
              i.item = [];

              for (let x of this.newDataToOldData(res.data.data)) {
                i.item.push(x.item[0]);
              }

              this.sortQuestionRecord(i.item, this.origQuestionId);
              this.updateQuestionListObj(this.questionRecordList, false, i); //10.21 更新一下工单信息的状态

              this.updateQuestListStatus(i, this.newDataToOldData(res.data.data)[0]); //恢复高度

              this.$nextTick(() => {
                this.$refs.recordListRef.scrollTop = this.questionListScrollTop;
              });
              isBreak = true;
              break;
            }
          }
        } // this.questionRecordList[0].item = []
        // for (let x of this.newDataToOldData(res.data.data)) {
        //   this.questionRecordList[0].item.push(x.item[0])
        // }
        //调换一下工单展开的顺序 
        // for (let i in this.questionRecordList[0].item) {
        //   if (this.questionRecordList[0].item[i].id == this.origQuestionId) {
        //     //删掉原始的哪一个工单的位置并且把他加到第一个
        //     let item = this.questionRecordList[0].item[i]
        //     this.questionRecordList[0].item.splice(i, 1)
        //     this.questionRecordList[0].item.unshift(item)
        //     break;
        //   }
        // }
        //10.16
        //9.18注释

      } else {
        //当点击等于0  这时候会折叠或者展开所以一定要记录没展开前的问题id
        let clickedItem; //请求某个人ticket列表 

        const res = await this.$axios.post('/javaplus/admin/recordQuestion/queryRecordQuestionByPersonalId/' + record.personalId);

        if (res.data.code != 200) {
          ElNotification({
            title: '失败',
            message: res.data.msg,
            type: 'error',
            duration: 1500
          });
          return;
        }

        if (res.data.data == null || _.isEmpty(res.data.data)) {
          ElNotification({
            title: '提示',
            message: '暂无数据',
            type: 'error',
            duration: 1500
          });
        } //拿到用户的ticket列表 加进去 并且放到数组最上面


        for (let i in this.questionRecordList) {
          // console.log(this.questionRecordList[i],'2321312312');
          //先找到你点击的聊天框
          if (this.questionRecordList[i].item[0].id == record.id) {
            // console.log('2321312312');
            //如果里面的工单信息大于1 这时候就应该折叠 成一个信息工单
            if (this.questionRecordList[i].item.length > 1) {
              //展开之后的折叠回去
              // this.questionRecordList[i].item = [this.questionRecordList[i].item[0]]
              // this.updateQuestionListObj(this.questionRecordList, false, this.questionRecordList[i])
              //10.21 不如直接查询一次
              this.quaryQuesList(); //恢复高度
              // this.$nextTick(() => {
              //   this.$refs.recordListRef.scrollTop = this.questionListScrollTop
              // })
            } else {
              //10.16 第一次展开记录存储一下聊天窗id
              this.origQuestionId = record.id; //10.16
              //记得关闭一下之前打开的
              // for (let i of this.questionRecordList) {
              //   if ((i.item.length > 1) && (i.item[0].id != record.id)) {
              //     i.item = [i.item[0]]
              //     break;
              //   }
              // }

              for (let i of this.questionRecordList) {
                if (i.item.length > 1) {
                  i.item = [i.item[0]];
                  break;
                }
              }

              this.questionRecordList[i].item = []; // this.questionRecordList[i].item.push(...this.newDataToOldData(res.data.data))
              //再把请求到的工单数据加入

              for (let x of this.newDataToOldData(res.data.data)) {
                // console.log(i.item[0]);
                this.questionRecordList[i].item.push(x.item[0]);
              } // 展开和关闭必定导致不一样 // 直接调用


              this.sortQuestionRecord(this.questionRecordList[i].item, this.origQuestionId);
              this.updateQuestionListObj(this.questionRecordList, false, this.questionRecordList[i]); //更新一下已读未读状态

              this.updateQuestListStatus(this.questionRecordList[i], this.newDataToOldData(res.data.data)[0]); //恢复高度

              this.$nextTick(() => {
                this.$refs.recordListRef.scrollTop = this.questionListScrollTop;
              }); // for (let y in this.questionRecordList[i].item) {
              //   if (this.questionRecordList[i].item[y].id == this.origQuestionId) {
              //     let item = this.questionRecordList[i].item[y]
              //     this.questionRecordList[i].item.splice(y, 1)
              //     this.questionRecordList[i].item.unshift(item)
              //     break;
              //   }
              // }
              //如果不大于一 就应该展开
              // if (Number(i)) {
              // console.log(typeof i, 'uuuuuuu');
              //先清空所有工单
              // this.questionRecordList[i].item = []
              // this.questionRecordList[i].item.push(...this.newDataToOldData(res.data.data))
              //再把请求到的工单数据加入
              // for (let x of this.newDataToOldData(res.data.data)) {
              // console.log(i.item[0]);
              // this.questionRecordList[i].item.push(x.item[0])
              // }
              //聊天框列表第一个工单只留下第一个
              // this.questionRecordList[0].item.splice(1)
              // clickedItem = this.questionRecordList[i]
              // this.questionRecordList.splice(i, 1)
              // this.questionRecordList.unshift(clickedItem)
              //调换一下工单展开的顺序 
              // for (let i in this.questionRecordList[0].item) {
              //   if (this.questionRecordList[0].item[i].id == this.origQuestionId) {
              //     let item = this.questionRecordList[0].item[i]
              //     this.questionRecordList[0].item.splice(i, 1)
              //     this.questionRecordList[0].item.unshift(item)
              //     break;
              //   }
              // }
              // } else {
              // console.log(i, 'iiiiii');
              // this.questionRecordList[i].item = []
              // this.questionRecordList[i].item.push(...this.newDataToOldData(res.data.data))
              // for (let x of this.newDataToOldData(res.data.data)) {
              //   this.questionRecordList[i].item.push(x.item[0])
              // }
              // }
            }

            break;
          } else {//如果点击的是另一个报损单头
          }
        } //10.16 更新一下工单信息的状态
        //10.16

      } //更新ticket列表之后存储一遍


      localStorage.setItem('userTicketList', JSON.stringify(this.questionRecordList)); // //查询ticket列表
      // const paramsTicket = {
      //   directoryId: this.catalogId,
      //   currentPage: this.currentPage,
      //   pageSize: this.itemsPerPage,
      // }
      // this.queryRecordQuestionList(paramsTicket).then((res) => {
      //   this.questionRecordList = []
      //   this.questionRecordList = this.newDataToOldData(res.data.data.list)
      //   this.initOnlineData()
      // })
      //注释替换
      // this.weeks = 1
      // this.dataRange = this.getWeekTimestamps(this.weeks++)
      // const params = {
      //   recordQuestionId: record.id,
      //   startTimestamp: this.dataRange.startTimestamp,
      //   endTimestamp: this.dataRange.endTimestamp,
      // }
      // //查询消息记录
      // this.queryByRecordQuestionIdAndTimestamp(params).then((res) => {
      //   this.chatRecordList_new = this.userNewChatRecordToOld(res.data.data)
      //   //对消息记录进行处理完成下面的功能
      //   this.dealCharRecord()
      //   localStorage.setItem('chatRecordList_new', JSON.stringify(this.chatRecordList_new))
      //   this.$nextTick(() => {
      //     // const el =dou  getElementsByClassName('.chatdiv')
      //     setTimeout(() => {
      //       const el = this.$refs.chat
      //       this.chatScrollData.scrollHeight_old = el.scrollHeight
      //       localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))
      //       el.scrollTop = el.scrollHeight
      //     }, 100)
      //     // el.scrollTop = el.scrollHeight
      //     // console.log(el.scrollTop, 'elelelel999');
      //     // console.log(el.scrollHeight, 'elelelel999');
      //   })
      // })
      //点击聊天框的时候先清除一次原始记录 再往里放消息
      //注释替换

      this.loadingMessage = true;
      this.queryChatRecord(true); // let clickedItem
      // for (let i in this.questionRecordList) {
      //   // console.log(this.questionRecordList[i],'2321312312');
      //   if (this.questionRecordList[i].item[0].personalId == record.personalId) {
      //     // console.log('2321312312');
      //     if (this.questionRecordList[i].item.length > 1) {
      //       this.questionRecordList[i].item = [this.questionRecordList[i].item[0]]
      //     } else {
      //       this.questionRecordList[i].item = []
      //       for (let x of this.newDataToOldData(res.data.data)) {
      //         // console.log(i.item[0]);
      //         this.questionRecordList[i].item.push(x.item[0])
      //       }
      //       clickedItem = this.questionRecordList[i]
      //       this.questionRecordList.splice(i, 1)
      //       this.questionRecordList.unshift(clickedItem)
      //     }
      //     break
      //   }
      // }
      // console.log(this.questionRecordList, 'this.questionRecordList')
      // for (let i = 0; i < this.questionRecordList.length; i++) {
      //   let foundMatch = false;
      //   for (let j = 0; j < this.questionRecordList[i].item.length; j++) {
      //     if (this.questionRecordList[i].item[j].id === record.personalId) {
      //       this.questionRecordList.splice(i, 1); // 先删除当前项
      //       this.questionRecordList.unshift(clickedItem); // 再将其插入到数组的开头
      //       foundMatch = true;
      //       break;
      //     }
      //   }
      //   if (!foundMatch) {
      //     // 如果当前项内没有与 record.id 匹配的 item.id，则将其折叠只显示第一个 item
      //     this.questionRecordList[i].item = [this.questionRecordList[i].item[0]];
      //   }
      // }
      // this.$store.commit('changeQuestionRecordUser', JSON.stringify(record.user))
      // this.$store.commit('changeQuestionRecordId', record.id)

      const currentDate = new Date().getTime(); // 记录到数据库

      var addArray = [];
      var changeArray = [];

      if (this.getFilterQuestionRecordByUser && this.getFilterQuestionRecordByUser.id) {
        changeArray.push({
          id: this.getFilterQuestionRecordByUser.id,
          person_id: this.user.id,
          user: JSON.stringify(record.user),
          question_record_id: record.id,
          change_time: currentDate
        });
      } else {
        addArray.push({
          person_id: this.user.id,
          user: JSON.stringify(record.user),
          question_record_id: record.id,
          change_time: currentDate
        });
      }

      this.loadingMessage = false; //释放占用
      // this.isTicketListChangeNow = false
    },

    // 根据问题记录编号查询聊天记录
    async getUserChatRecordListByQuestionRecordId(record) {
      // this.loadingMessage = true
      const res = await this.$axios.get(`/java/front/getUserChatRecordListByQuestionRecordId?questionRecordId=${record.id}`);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          duration: 1500,
          type: 'error'
        });
        return;
      }

      this.chatRecordList = res.data.data;
      const data = this.chatRecordList.map(data => {
        return { ...data,
          time: this.$moment.utc(data.time).local().format('YYYY-MM-DD HH:mm:ss')
        };
      });
      this.chatRecordList = data;
      this.initPreviewImg(this.chatRecordList);
      this.$nextTick(() => {
        this.replaceTicketBtn();
        this.addChatImgClick();
        this.addChatBtnClick();
        this.addTicketBodyCancel(this.chatRecordList);
      }); // setTimeout(() => {
      //   this.loadingMessage = false
      // }, 500)
    },

    // 提交搜索客户名称内容
    async submitclientName() {
      //先清空工单列表 和 聊天框
      this.pageCountt = 1;
      this.isLoading = false; // this.chatRecordList_new = []
      //10.11
      // this.nowChatUserRecordInfo = {}
      //10.11

      this.catalogId = null;
      this.questionRecordList = [];
      await this.clickQuestionCatalog({
        id: null
      }); // setTimeout(()=>{

      this.update(); // },1000)
    },

    updateTreeData() {
      this.updateTimer && clearTimeout(this.updateTimer);
      this.loadingMessage = true;
      this.updateTimer = setTimeout(async () => {
        this.loadingMessage = false;
      }, 1000);
    },

    // 阻止点击textarea框冒泡到问题列表
    stopClickPropagation(e, record) {
      this.IssueNotes = record.id;
      e.stopPropagation();
    },

    // 提交备注
    async submitContent(record) {
      const params = {
        recordQuestionId: record.id,
        comment: record.comment
      };
      this.updateRecordQuestionComment(params); // this.$store.commit('setScrollPosition', this.$refs.recordListRef.scrollTop)
      // this.questionRecordList = []
      // this.currentPage = 1
      // const res = await this.$axios.post('/java/front/adminRemarkQuestionRecord', {
      //   questionRecordId: this.IssueNotes,
      //   content: record.comment
      // })
      // if (res.data.code != 200) {
      //   ElNotification({
      //     title: '失败',
      //     message: res.data.msg,
      //     duration: 1500,
      //     type: 'error'
      //   })
      //   return
      // }
    },

    // 清空聊天记录按钮
    clearAIChatHistory() {
      ElMessageBox.confirm('确认清空聊天记录?', '提示', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        const res = await this.$axios.get('/java/front/delUserHistoriesAiChatList?questionId=' + this.questionRecordId);

        if (res.status == 200) {
          ElNotification({
            title: '成功',
            message: '聊天记录已清空',
            duration: 1500,
            type: 'success'
          });
        }

        this.getUserHisAiList();
      }).catch(() => {});
    },

    filterRecord(_recordArray) {
      for (const record of _recordArray) {
        if (this.questionDataIds.indexOf(record.directory_id) != -1) {
          return true;
        }
      }

      return false;
    },

    // 让滚动条保持在底部
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.scrollRef?.scrollTop) {
          this.$refs.scrollRef.scrollTop = this.$refs.scrollRef.scrollHeight;
        }
      });
    },

    // 获取客户订单列表
    async getOrderByUser(record) {
      this.orderTableLoading = true; // if(taccount){
      // }
      // if (!this.nowChatUserRecordInfo.length) {
      //   return
      // }

      if (!record) {
        if (!this.userAccount) {
          ElMessage.error('当前用户为匿名用户，不支持操作。');
          return;
        } else {
          this.templateDemoShow = false;
          this.isShowTable = true;
          this.isTemplShow = false;
        }
      } else {
        if (!this.userAccount) {
          return;
        }
      }

      try {
        const account = JSON.parse(this.nowChatUserRecordInfo.user).account;
        this.userAccount = account;
        const res = await this.$axios.post('/api/admin/Base/getOrderByUser', {
          account: account
        });
        this.orderTableLoading = false;

        if (res.data.errno != 0) {
          ElNotification({
            title: '失败',
            message: res.data.errmsg,
            duration: 1500,
            type: 'error'
          });
          return;
        }

        if (res.data.data === undefined) {
          return;
        }

        const formattedData = res.data.data.map(data => {
          return { ...data,
            order_create_time: this.$moment(data.order_create_time).format("YYYY-MM-DD"),
            goods_unit_price: data.goods_unit_price + data.currency_symbol,
            totalDiscountAmount: data.totalDiscountAmount + data.currency_symbol
          };
        });
        this.customerOrderListData = formattedData;
      } catch (err) {
        ElNotification({
          title: '失败',
          message: err,
          duration: 1500,
          type: 'error'
        });
      }
    },

    // async queryTrackingStatus () {
    //   const res = await this.$axios.post('sales/queryTrackingStatus', {})
    // },
    // 发送信息给AI
    async clickSendContentBySOPAI() {
      this.loadingAIChat = true;
      this.message = this.sendAIContent;
      this.sendAIContent = '';

      if (_.trim(this.message) === '') {
        this.message = '';
        this.inputWarning();
        return;
      } // ai聊天记录接口


      const res = await this.$axios.post('/java/front/sopAi', {
        type: 0,
        questionId: this.questionRecordId,
        content: this.message
      });

      if (res.data.code != 200) {
        return;
      }

      if (res.data.code == 200) {
        this.loadingAIChat = false;
      }

      this.AiData = res.data.data;
      this.scrollToBottom();
    },

    // 获取AI聊天历史记录
    async getUserHisAiList() {
      const res = await this.$axios.get('/java/front/getUserHisAiList?questionId=' + this.questionRecordId);

      if (res.data.code != 200) {
        return;
      }

      this.AiData = res.data.data;
    },

    // 警告弹窗
    inputWarning() {
      ElNotification({
        title: '警告',
        message: '输入内容不能为空',
        type: 'error',
        duration: 1500
      });
    },

    // 点击树形目录列表
    async clickQuestionCatalog(data, node, e) {
      //存储一下上次点击的sopdata 用户匿名用户变为登录用户的更新
      this.sopClickData = data; //先让工单列表 滚轮到顶

      const el = this.$refs.recordListRef;
      el.scrollTop = 0;

      if (this.clientName == undefined) {
        this.clientName = '';
      } // this.questionRecordList = []


      this.catalogId = data.id; //清空聊天记录
      // this.chatRecordList_new = []
      //清空当前工单信息
      //10.11
      // this.nowChatUserRecordInfo = {}
      //10.11
      // localStorage.setItem('catalogId', JSON.stringify(this.catalogId))
      // 根据目录编号获取所有问题列表
      // /java/front/queryQuestionRecordList
      // /javaplus/recordQuestion/queryRecordQuestionList

      const questionDataIds = [];
      this.pageCountt = 1;
      this.isLoading = false;
      const res = await this.$axios.post('/javaplus/admin/recordQuestion/queryRecordQuestionList', {
        state: this.checkedSolveOptions.length == this.solveOptions.length ? [] : this.checkedSolveOptions,
        questionType: this.questionTypeSelected.length == this.questionTypeOptions.length ? [] : this.questionTypeSelected,
        personIds: this.questionCustomerIdArray.length == this.questionCustomerArray.length ? [] : this.questionCustomerIdArray,
        urgencyLevel: this.emergencyDegreeSelect.length == this.emergencyDegreeOption.length ? [] : this.emergencyDegreeSelect,
        mark: this.regionSelect.length == this.regionOption.length ? [] : this.regionSelect,
        classes: this.schedulingSelect.length == this.schedulingOption.length ? [] : this.schedulingSelect,
        clientName: this.clientName,
        directoryId: this.catalogId,
        // clientName: this.clientName,
        // state: this.checkedSolveOptions,
        // page: this.currentPage,
        currentPage: this.currentPage,
        // size: this.itemsPerPage,
        pageSize: this.itemsPerPage // urgencyLevel: this.emergencyDegreeSelect

      });

      if (res.data.code != 200) {
        if ((res.data.data == null || _.isEmpty(res.data.data)) && res.data.errno == 100) {
          this.questionRecordList = [];
        } else {
          ElNotification({
            title: '错误',
            message: res.data.errmsg,
            type: 'error',
            duration: 1500
          });
        }
      } else {
        // this.questionRecordList = res.data.data
        // console.log(res.data.data.list,'datadata')
        this.questionRecordList = [];
        this.questionRecordList = this.newDataToOldData(res.data.data.list); //建立查询问题对象

        this.updateQuestionListObj(this.questionRecordList, true); // console.log(this.questionRecordList, 'this.questionRecordList');
        // localStorage.setItem('userTicketList', JSON.stringify(this.questionRecordList))

        this.initOnlineData();
      } // this.questionDataIds = questionDataIds;


      this.updateTreeData();
    },

    // 获取问题是否申请评价
    async getFAQAppraiseStatus() {
      if (this.selectItem == null) {
        return;
      }

      localStorage.setItem("questionRecordId", this.selectItem);
      const res = await this.$axios.get('/java/front/getFAQAppraiseStatus?questionRescordId=' + this.selectItem);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      this.isUserApply = res.data.data;
    },

    // 申请评价弹窗
    openPoppup() {
      if (this.workStatusVal) {// return true
      } else {
        ElMessage.error('请先上工后再进行操作');
        return; // return false
      }

      this.$confirm('确认需要对此聊天进行评价申请, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$axios.post('/javaplus/admin/scoreQuestionRecord/createScoreQuestionRecord', {
          recordQuestionId: this.nowChatUserRecordInfo.id
        });

        if (res.data.code != 200) {
          ElNotification({
            title: '失败',
            message: res.data.msg,
            type: 'warning',
            duration: 1500
          });
          return;
        }

        this.appriseMsg = "申请成功";
        ElNotification({
          title: '成功',
          message: '申请成功',
          type: 'success',
          duration: 3000
        }); //10.11

        this.nowChatUserRecordInfo.request_score = 1; // console.log(this.questionRecordList)
        // console.log(this.nowChatUserRecordInfo)

        for (let qus of this.questionRecordList[0].item) {
          if (this.nowChatUserRecordInfo.id == qus.id) {
            qus.request_score = 1;
            break;
          }
        } // questionRecordList
        //10.11


        this.update(); // this.getFAQAppraiseStatus()
      }).catch(() => {});
    },

    // 获取问题类型集合
    async getQuestionTypeList() {
      const res = await this.$axios.post('/java/front/getQuestionTypeList');

      if (res.data.code != 200) {
        ElNotification({
          title: '请求失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      this.questionTypeOptions = res.data.data;
    },

    // 获取客服人员 
    async getPersonDataList() {
      const res = await this.$axios.post('/javaplus/admin/personal/getCustomerList');

      if (res.data.code != 200) {
        ElNotification({
          title: '请求失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      for (const data of res.data.data) {
        this.questionCustomerArray.push(data);
      }
    },

    // 获取所有目录树形结构
    async getAllDirectoryList() {
      if (this.clientName == undefined) {
        this.clientName = '';
      }

      const params = {
        state: this.checkedSolveOptions.length == this.solveOptions.length ? [] : this.checkedSolveOptions,
        questionType: this.questionTypeSelected.length == this.questionTypeOptions.length ? [] : this.questionTypeSelected,
        personIds: this.questionCustomerIdArray.length == this.questionCustomerArray.length ? [] : this.questionCustomerIdArray,
        urgencyLevel: this.emergencyDegreeSelect.length == this.emergencyDegreeOption.length ? [] : this.emergencyDegreeSelect,
        mark: this.regionSelect.length == this.regionOption.length ? [] : this.regionSelect,
        classes: this.schedulingSelect.length == this.schedulingOption.length ? [] : this.schedulingSelect,
        clientName: this.clientName
      }; // `/java/admin/getAllDirectoryList?state=[${this.checkedSolveOptions}]&questionType=[${this.questionTypeSelected}]&personIds=[${this.questionCustomerIdArray}]&clientName=${this.clientName}`

      const res = await this.$axios.post('/javaplus/admin/sopDirectory/getSopTreeList', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      this.treeListData = res.data.data;
    },

    // 获取问题列表
    // async queryQuestionRecordList() {
    //   if (this.clientName == undefined) {
    //     this.clientName = ''
    //   }
    //   let data = {
    //     page: this.currentPage,
    //     size: this.itemsPerPage,
    //   }
    //   if (this.selectItem != null || this.selectItem != undefined) {
    //     data.questionRecordId = this.selectItem
    //   }
    //   if (this.clientName != '') {
    //     data.clientName = this.clientName
    //   }
    //   if (this.checkedSolveOptions.length > 0) {
    //     data.state = this.checkedSolveOptions
    //   }
    //   if (this.questionCustomerIdArray.length > 0) {
    //     data.personIds = this.questionCustomerIdArray
    //   }
    //   if (this.questionTypeSelected.length > 0) {
    //     data.questionType = this.questionTypeSelected
    //   }
    //   if (this.catalogId != null) {
    //     data.directoryId = this.catalogId
    //   }
    //   data.urgencyLevel = this.emergencyDegreeSelect
    //   const res = await this.$axios.post('/java/front/queryQuestionRecordList', data)
    //   if (res.data.errno != 0) {
    //     ElNotification({
    //       title: '提示',
    //       message: res.data.errmsg,
    //       type: 'warning',
    //       duration: 1500
    //     })
    //   }
    //   this.totelItem = res.data.total
    //   if (res.data.data == null || _.isEmpty(res.data.data)) {
    //     ElNotification({
    //       title: '提示',
    //       message: res.data.msg,
    //       type: 'warning'
    //     })
    //     this.questionRecordList = []
    //   } else {
    //     // 在问题列表滚动触底时进入，将新获取的数据添加在原有的问题列表中（此操作避免问题列表滚动触底后一直加载，知道将所有问题加载完的情况）
    //     if (this.isLoading) { // 问题列表滚动触底时isLoading为true
    //       this.questionRecordList = _.concat(this.questionRecordList, res.data.data)
    //     } else {
    //       this.questionRecordList = res.data.data
    //     }
    //   }
    // },
    // 滚动条触底事件
    scrollHandler() {
      // console.log(this.isQueryRecord, 'this.isQueryRecord');
      // if (this.isQueryRecord) {
      //   return;
      // }
      // console.log(this.isQueryRecord, 'this.456346456');
      const el = this.$refs.recordListRef;
      let x = el.scrollHeight;
      this.ticketScrollData.scrollTop_now = el.scrollTop_now;
      this.isQueryRecord = true; // this.isQueryRecord = true;
      //查询ticket列表

      const paramsTicket = {
        state: this.checkedSolveOptions.length == this.solveOptions.length ? [] : this.checkedSolveOptions,
        questionType: this.questionTypeSelected.length == this.questionTypeOptions.length ? [] : this.questionTypeSelected,
        personIds: this.questionCustomerIdArray.length == this.questionCustomerArray.length ? [] : this.questionCustomerIdArray,
        urgencyLevel: this.emergencyDegreeSelect.length == this.emergencyDegreeOption.length ? [] : this.emergencyDegreeSelect,
        mark: this.regionSelect.length == this.regionOption.length ? [] : this.regionSelect,
        classes: this.schedulingSelect.length == this.schedulingOption.length ? [] : this.schedulingSelect,
        clientName: this.clientName,
        directoryId: this.catalogId,
        currentPage: this.currentPage + this.pageCountt++,
        pageSize: this.itemsPerPage
      };
      this.queryRecordQuestionList(paramsTicket).then(res => {
        if (!res.data.data.list.length) {
          return;
        } else {
          this.isQueryRecord = false;
        } // this.questionRecordList = []


        this.questionRecordList.push(...this.newDataToOldData(res.data.data.list));
        this.$nextTick(() => {
          setTimeout(() => {
            // el.scrollTop = this.ticketScrollData.scrollTop_now
            el.scrollTop = x;
          }, 100);
        }); // localStorage.setItem('userTicketList', JSON.stringify(this.questionRecordList))

        this.initOnlineData();
        this.isLoading = false;
      }); // console.log('7777');
      // if (this.totelItem <= 0) {
      //   this.currentPage = 1
      //   this.isLoading = false
      // } else if (this.currentPage * this.itemsPerPage < this.totelItem) {
      //   this.isLoading = true;
      //   setTimeout(() => {
      //     this.queryQuestionRecordList()
      //     setTimeout(() => {
      //       this.isLoading = false
      //     }, 1000)
      //   }, 500)
      //   this.currentPage++
      // } else {
      //   this.isLoading = false
      // }
    },

    clickHidden() {
      this.isHidden = !this.isHidden;
    },

    // 点击客户订单按钮
    clickClientOrderList() {
      if (Object.keys(this.nowChatUserRecordInfo).length == 0) {
        // ElMessage.error('请选择登录用户工单')
        return;
      } // console.log(this.nowChatUserRecordInfo)
      //先关闭一下弹窗 如果有的话 没有也没事


      this.demoClose();
      this.updateTreeData();
      this.getOrderByUser();
    },

    //点击客户报损单列表
    clicktemplRecord(val) {
      //先清空后再查
      this.templRecordList = []; // console.log('213123128788')
      // if (!this.userAccount) {
      //   // ElMessage.error('请选择登录用户工单')
      //   return
      // }

      if (Object.keys(this.nowChatUserRecordInfo).length == 0) {
        // ElMessage.error('请选择登录用户工单')
        return;
      }

      if (val) {
        if (!this.userAccount) {
          ElMessage.error('当前用户为匿名用户，不支持操作。');
        } else {
          //10.14
          this.templateDemoShow = false; //10.14

          this.isTemplShow = true;
        } // this.message

      }

      if (!this.userAccount) {
        return;
      } //先关闭一下弹窗 如果有的话 没有也没事


      this.demoClose(); // console.log(this.userAccount,'ttttttt');
      // this.getOrderByUser()

      this.loadingMessage = true;
      this.tempTableLoading = true;
      let params = {
        // account: this.userAccount,
        account: this.userAccount,
        pageSize: this.templpageSize,
        pageNum: this.templcurrentPage
      };
      this.getDamageReportListByAccount(params).then(res => {
        // res.data.data
        // console.log(res.data.data.row,'res');
        this.templtotal = res.data.data.total;
        this.templRecordList = res.data.data.rows;
        this.loadingMessage = false;
        this.tempTableLoading = false; // console.log(res, 'this.templRecordList');
      });
    },

    //报损单记录查询
    async getDamageReportListByAccount(params) {
      try {
        const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getDamageReportListByAccount', params);

        if (res.data.code != 200) {
          ElNotification({
            title: '错误',
            message: res.data.message,
            type: 'error'
          });
        }

        return res;
      } catch (err) {
        ElNotification({
          title: '错误',
          message: err.message,
          type: 'error'
        });
        this.loadingMessage = false;
        this.tempTableLoading = false;
      }
    },

    clickShowAI() {
      this.showAI = !this.showAI;
    },

    clickSopAdmin() {
      this.$router.push({
        path: '/admin/Sop/SopAdministration'
      });
    },

    clickSopForeground() {
      this.$router.push({
        path: '/admin/Sop/SopForeground'
      });
    },

    scrollAjust(questionRecordId, chatLength) {
      if (this.scrollAjustById[0] !== questionRecordId || this.scrollAjustById[questionRecordId] !== chatLength) {
        if (this.$refs.chat) {
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        }
      }

      this.scrollAjustById[0] = questionRecordId;
      this.scrollAjustById[questionRecordId] = chatLength;
    },

    getNav(id) {
      var record = this.questionRecordById[id];
      var nav = [];
      var index = 0;

      for (const _nav of this.questionTypeById[record.question_type_id].nav || []) {
        if (index > 0) {
          nav.push(_nav.title);
        }

        index++;
      }

      return nav;
    },

    getTitle(id) {
      var record = this.questionRecordById[id];

      if (record.question_id != 0 && this.questionById[record.question_id]) {
        // 知识库问题
        return this.questionById[record.question_id].title;
      } else {
        // 用户自定义问题
        return record.title;
      }
    },

    getItemName(item) {
      var nameArray = [];

      for (const _id in item) {
        const _item = item[_id];
        nameArray.push(_item.name);
      }

      return nameArray.join();
    },

    getItemNameAndDescription(item) {
      var nameArray = [];

      for (const _id in item) {
        const _item = item[_id];

        if (_item.description) {
          nameArray.push(_item.name + '(' + _item.description + ')');
        } else {
          nameArray.push(_item.name);
        }
      }

      return nameArray.join();
    },

    clickReturnKnowledge() {
      this.$router.push({
        path: '/admin/knowledge'
      });
    },

    removeUnvalidContent(content) {
      const unvaild = '<p><br></p>';

      if (content.endsWith(unvaild)) {
        content = content.slice(0, -unvaild.length);
        content = this.removeUnvalidContent(content);
      }

      return content;
    },

    //根据问题记录编号查询历史聊天记录
    async getUserHistoriesChatRecordListByQuestionRecordId() {
      const res = await this.$axios.get('/java/front/getUserHistoriesChatRecordListByQuestionRecordId?questionRecordId=' + this.selectItem);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'warning',
          duration: 1500
        });
        return;
      }

      this.newChatRecordList = res.data.data;
    },

    // 发送消息
    clickSendContent(e, data_) {
      if (!this.workStatusVal) {
        ElMessage.error('请先上工后再进行操作');
        this.sendContent = '';
        return;
      }

      if (this.nowChatUserRecordInfo.is_solved) {
        ElMessage.error('工单已解决');
        this.sendContent = '';
        return;
      } // console.log(this.nowChatUserRecordInfo,'2131231')
      // console.log('2323');


      if (e.keyCode === 13) {
        e.preventDefault();
      }

      var sendContent = this.sendContent;

      if (!data_) {
        sendContent = this.removeUnvalidContent(sendContent);

        if (sendContent.trim() == '') {
          return;
        }
      } else {
        sendContent = JSON.stringify(this.sendContentTicket);
      } // console.log(Date.now(),'Date.now()')


      const params = {
        msgType: data_ ? 10 : 4,
        recordQuestionId: this.nowChatUserRecordInfo.id,
        msgContent: sendContent,
        timestamp: Date.now()
      };
      this.sendMessage_new(params).then(res => {
        this.updateRecordQuestionUnread({
          recordQuestionId: this.nowChatUserRecordInfo.id
        });
        this.sendContent = '';

        if (data_ && !res.data.errno) {
          let params = {
            id: data_.id,
            chatRecordId: res.data.data
          };
          this.addChatRecordId(params).then(res => {});
        } // this.updateRecordQuestionUnread({ recordQuestionId: this.nowChatUserRecordInfo.id })

      }); // this.$axios.post('/api/admin/ChatBase/sendContent', {
      //   question_record_id: this.selectItem,
      //   content: sendContent
      // }).then((res) => {
      //   if (data_ && !res.data.errno) {
      //     // [FAQ]添加报损单聊天记录ID
      //     let params = {
      //       id: data_.id,
      //       chatRecordId: res.data.data.id
      //     }
      //     this.addChatRecordId(params)
      //     ElNotification({
      //       title: '成功',
      //       message: res.data.message,
      //       type: 'success',
      //     });
      //   } else {
      //   }
      //   // this.clickQuestionRecord(this.recordData)
      //   if (res.data.errno) {
      //     ElNotification({
      //       title: '错误',
      //       message: res.data.message,
      //       type: 'error',
      //     });
      //   } else {
      //     this.sendContent = ''
      //     this.questionRecordList[0].item[0].admin_unread = 0
      //   }
      //   this.$nextTick(() => {
      //     this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
      //   })
      //   this.getUserHistoriesChatRecordListByQuestionRecordId()
      //   const record = JSON.parse(localStorage.getItem('recordData'))
      //   this.getUserChatRecordListByQuestionRecordId(record)
      // }).catch((err) => {
      //   console.log(err)
      // })
    },

    clickViewQuestionStep() {
      this.templateDemoShow = false;
      this.isShowTable = false;
      this.isTemplShow = false;
      this.$nextTick(() => {
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
      });
    },

    changeQuestionRecord(questionId, questionRecordId, change) {
      this.$axios.post('/api/admin/KnowledgeBase/changeQuestionRecord', {
        id: questionRecordId,
        question_id: questionId,
        change: change
      });
      this.clickQuestionRecord(this.recordData);
    },

    clickSolved(nowChatUserRecordInfo) {
      if (!this.workStatusVal) {
        ElMessage.error('请先上工后再进行操作');
        this.sendContent = '';
        return;
      }

      nowChatUserRecordInfo.is_solved = nowChatUserRecordInfo.is_solved ? 0 : 1; //样式改为黑框
      // this.changeQuestionRecord(questionRecord.question_id, questionRecord.id, {
      //   is_solved: questionRecord.is_solved
      // })

      const params = {
        id: nowChatUserRecordInfo.id,
        isSolved: nowChatUserRecordInfo.is_solved
      };
      this.updateIsSolvedById(params).then(() => {//遍历找
        // let isbreak = false
        // for (let item of this.questionRecordList) {
        //   if (isbreak) {
        //     break
        //   }
        //   for (let i of item.item) {
        //     if (i.id == nowChatUserRecordInfo.id) {
        //       i.is_solved = nowChatUserRecordInfo.is_solved
        //       isbreak = true
        //       break;
        //     }
        //   }
        // }
        // this.questionRecordList[0].item[0].is_solved = nowChatUserRecordInfo.is_solved
      });
    },

    getLocalTime(time) {
      var localTime = this.$moment(time).format('YYYY-MM-DD HH:mm:ss'); // var localTime = this.$moment(time).format('YYYY-MM-DD HH:mm:ss')

      return localTime;
    },

    clickComment() {
      this.commentModalShow = true;
    },

    // clickChangeQuestionRecordComment(questionRecord) {
    //   this.submitContent(questionRecord)
    //   this.changeQuestionRecord(questionRecord.question_id, questionRecord.id, {
    //     comment: this.commentTemp[questionRecord.id]
    //   })
    // },
    getUnreadQuantity(questionRecordArray) {
      var quantity = 0;

      for (const _record of questionRecordArray) {
        if (_record.admin_unread) {
          quantity++;
        }
      }

      return quantity;
    },

    getUnsolvedQuantity(questionRecordArray) {
      var quantity = 0;

      for (const _record of questionRecordArray) {
        if (!_record.is_solved) {
          quantity++;
        }
      }

      return quantity;
    },

    getColor(user, questionRecordArray) {
      var color = '#999';
      var unsolvedQuantity = this.getUnsolvedQuantity(questionRecordArray);

      if (user == this.questionRecordUser) {
        color = '#000';
      } else {
        if (unsolvedQuantity) {
          color = '#555';
        }
      }

      return color;
    },

    clickViewOrder(user) {
      this.viewOrderModalShow = true;
    },

    getOrderStatus(order) {
      var status = '';

      if (order.status == 2) {
        status = '等待审核';
      } else if (order.status == 3) {
        status = '已审核';
      } else if (order.status == 4) {
        status = '已完成';
      } else if (order.status == 5 && order.refund_status == 1) {
        status = '退款完成';
      } else if (order.status == 5 && order.refund_status != 1) {
        status = '申请退款中';
      } else if (order.status == 6) {
        status = '已作废';
      }

      return status;
    },

    getPayStatus(order) {
      var status = '';

      if (order.pay_status == 0) {
        status = '未支付';
      } else if (order.pay_status == 1 && order.refund_status != 1) {
        status = '已支付';
      } else if (order.pay_status == 1 && order.refund_status == 1) {
        status = '已退款';
      } else if (order.pay_status == 2) {
        status = '捕获中';
      } else if (order.pay_status == 3) {
        status = '授权中';
      }

      return status;
    },

    getShipStatus(order) {
      var status = '';

      if (order.delivery_status == 0) {
        status = '未发货';
      } else {
        status = '已发货';
      }

      return status;
    },

    // addChatImgClick() {
    //   const it = this
    //   var chatImgArray = document.getElementsByClassName('chatImg')
    //   for (var img of chatImgArray) {
    //     img.onclick = (e) => {
    //       var index = e.target.dataset.index
    //       it.imgIndex = Number(index)
    //       it.imgViewerShow = true
    //     }
    //   }
    // },
    async clickRecall(chat, index) {
      const res = await this.$axios.post('/javaplus/admin/recordChat/deleteRecordChatById/' + chat.id);

      if (res.data.code != 200) {
        ElNotification({
          title: '',
          message: res.data.message,
          type: 'error',
          duration: 1500
        });
        this.withdrawShow = false;
        return;
      } //撤回之后刷新一下sop树


      this.update();
      const regex = /<p\b[^>]*>.*?<\/p>/i;

      if (regex.test(chat.content)) {
        this.sendContent = chat.content;
      } // chat.clickRecall = false
      // this.clickQuestionRecord(this.recordData)
      // this.getUserHistoriesChatRecordListByQuestionRecordId()

    },

    // 点击分类按钮
    clickSortQuestion(data) {
      if (this.workStatusVal) {// return true
      } else {
        ElMessage.error('请先上工后再进行操作');
        return; // return false
      }

      if (data.is_solved == 1) {
        ElNotification({
          title: '完成提示',
          message: '该问题已解决',
          type: 'success',
          duration: 1500
        });
        return;
      }

      this.recordDataF = data; // this.questionDataId = data.id

      this.drawer = true;
    },

    // 关闭分类弹窗
    handleClose(done) {
      this.$refs.drawerRef.close();
    },

    closeAssignment(done) {
      this.$refs.drawerAssignmentRef.close();
    },

    // 确认提交分类
    async clickSubmit(done) {
      if (this.recordDataF.id == null || this.directoryId == undefined) {
        ElNotification({
          title: '错误',
          message: '请选择目录列表',
          type: 'error',
          duration: 1500
        });
        return;
      }

      this.$confirm('确定要将此问题分类到此目录下吗？').then(async () => {
        const params = {
          recordQuestionId: this.recordDataF.id,
          sopDirectoryId: this.directoryId,
          sopQuestionId: this.questionId // sopQuestionId:

        };
        await this.updateRecordQuestionSopDirectoryId(params); // 问题分类到目录
        // const res = await this.$axios.get(`/java/front/setFAQQuestionToOneDirectory?questionRescordId=${this.questionDataId}&directoryId=${this.directoryId}&questionId=${this.questionId ? this.questionId : 0}`)
        // if (res.data.code !== 200) {
        //   ElNotification({
        //     title: '失败',
        //     message: res.data.msg,
        //     type: 'error',
        //     duration: 1500
        //   })
        //   return
        // }
        // ElNotification({
        //   title: '成功',
        //   message: res.data.msg,
        //   type: 'success',
        //   duration: 1500
        // })

        this.getAllDirectoryList();
        this.updateTreeData();
        setTimeout(() => {
          this.$refs.drawerRef.close();
        }, 2000);
      });
    },

    // 点击分派按钮
    clickQuestionToCustomer(data) {
      if (data.is_solved == 1) {
        ElNotification({
          title: '完成提示',
          message: '该问题已解决',
          type: 'success',
          duration: 1500
        });
        return;
      }

      this.recordDataF.id = data.id;
      this.drawerAssignment = true;
    },

    changeCustomerService(value) {
      this.customerServiceId = value.id;
    },

    // 提交分派人员
    async clickSubmitAssignment(done) {
      if (this.customerServiceId == null) {
        ElNotification({
          title: '提示',
          message: '请选择需要分派的客服人员',
          type: 'warning',
          duration: 1500
        });
        return;
      }

      ElMessageBox.confirm('确认将此问题分派给当前客服人员?', '提示', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        // const res = await this.$axios.get(`/java/front/setFAQQuestionToCustomer?questionRescordId=${this.assignmentQuestionId}&customerId=${this.customerServiceId}`)
        // if (res.data.code != 200) {
        //   ElNotification({
        //     title: '失败',
        //     message: res.data.msg,
        //     type: 'error',
        //     duration: 1500
        //   })
        //   return
        // }
        // ElNotification({
        //   title: '成功',
        //   message: '分派成功',
        //   type: 'success',
        //   duration: 1500
        // })
        const params = {
          recordQuestionId: this.recordDataF.id,
          customerId: this.customerServiceId
        };
        this.updateRecordQuestionCustomerId(params);
        this.$refs.drawerAssignmentRef.close();
        this.update();
      }).catch(() => {});
    },

    async refreshData() {
      // 刷新数据
      const res = await this.$axios.post('/api/admin/Base/updateAllRecord', {});

      if (!res) {
        return;
      }
    },

    cancelForm() {
      this.$refs.drawerRef.close();
    },

    cancelAssignment() {
      this.$refs.drawerAssignmentRef.close();
    },

    getDirectoryIdInChild(directoryId, questionId) {
      this.directoryId = directoryId;
      this.questionId = questionId;
    }

  },

  beforeDestoryed() {// this.$eventBus.off('dialogClose')
    // this.$eventBus.off('getTempData')
    // this.$eventBus.off('preStep')
    // this.$eventBus.off('getTicketData')
    // this.$eventBus.off('loading')
  }

};