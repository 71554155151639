import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_templateInit = _resolveComponent("templateInit", true);

  const _component_templateRadio = _resolveComponent("templateRadio");

  const _component_templateTextArea = _resolveComponent("templateTextArea");

  const _component_templateUpload = _resolveComponent("templateUpload");

  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tempItemContentList, (tempData, index) => {
    return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [tempData.questionType === '11' ? (_openBlock(), _createBlock(_component_templateRadio, {
      key: 0,
      listIndex: index + 1,
      dataInfos: tempData,
      isAdmin: $props.isAdmin,
      isView: $props.isView,
      status: $props.status,
      languageType: $props.languageType,
      statusName: $props.statusName,
      damageRTextL: $props.damageRTextL,
      sortTemplateData: $props.sortTemplateData
    }, {
      default: _withCtx(({
        data
      }) => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.selectsIdList, i => {
        return _openBlock(), _createElementBlock(_Fragment, null, [data.itemListObj[i].children.length ? (_openBlock(), _createBlock(_component_templateInit, {
          key: 0,
          tempItemContentList: data.itemListObj[i].children,
          languageType: data.languageType,
          status: data.status,
          isAdmin: data.isAdmin,
          isView: data.isView,
          statusName: data.statusName,
          damageRTextL: data.damageRTextL,
          sortTemplateData: $props.sortTemplateData
        }, null, 8, ["tempItemContentList", "languageType", "status", "isAdmin", "isView", "statusName", "damageRTextL", "sortTemplateData"])) : _createCommentVNode("", true)], 64);
      }), 256))]),
      _: 2
    }, 1032, ["listIndex", "dataInfos", "isAdmin", "isView", "status", "languageType", "statusName", "damageRTextL", "sortTemplateData"])) : tempData.questionType === '12' ? (_openBlock(), _createBlock(_component_templateRadio, {
      key: 1,
      listIndex: index + 1,
      dataInfos: tempData,
      isCheckBox: true,
      isAdmin: $props.isAdmin,
      isView: $props.isView,
      status: $props.status,
      languageType: $props.languageType,
      statusName: $props.statusName,
      damageRTextL: $props.damageRTextL,
      sortTemplateData: $props.sortTemplateData
    }, {
      default: _withCtx(({
        data
      }) => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.selectsIdList, i => {
        return _openBlock(), _createElementBlock(_Fragment, null, [data.itemListObj[i].children.length ? (_openBlock(), _createBlock(_component_templateInit, {
          key: 0,
          tempItemContentList: data.itemListObj[i].children,
          languageType: data.languageType,
          status: data.status,
          isAdmin: data.isAdmin,
          isView: data.isView,
          statusName: data.statusName,
          damageRTextL: data.damageRTextL,
          sortTemplateData: $props.sortTemplateData
        }, null, 8, ["tempItemContentList", "languageType", "status", "isAdmin", "isView", "statusName", "damageRTextL", "sortTemplateData"])) : _createCommentVNode("", true)], 64);
      }), 256))]),
      _: 2
    }, 1032, ["listIndex", "dataInfos", "isAdmin", "isView", "status", "languageType", "statusName", "damageRTextL", "sortTemplateData"])) : tempData.questionType === '21' ? (_openBlock(), _createBlock(_component_templateTextArea, {
      key: 2,
      listIndex: index + 1,
      dataInfos: tempData,
      isAdmin: $props.isAdmin,
      isView: $props.isView,
      status: $props.status,
      languageType: $props.languageType,
      statusName: $props.statusName,
      damageRTextL: $props.damageRTextL,
      sortTemplateData: $props.sortTemplateData
    }, null, 8, ["listIndex", "dataInfos", "isAdmin", "isView", "status", "languageType", "statusName", "damageRTextL", "sortTemplateData"])) : tempData.questionType === '31' ? (_openBlock(), _createBlock(_component_templateUpload, {
      key: 3,
      listIndex: index + 1,
      dataInfos: tempData,
      isVideo: true,
      isAdmin: $props.isAdmin,
      status: $props.status,
      isView: $props.isView,
      languageType: $props.languageType,
      statusName: $props.statusName,
      damageRTextL: $props.damageRTextL,
      sortTemplateData: $props.sortTemplateData
    }, null, 8, ["listIndex", "dataInfos", "isAdmin", "status", "isView", "languageType", "statusName", "damageRTextL", "sortTemplateData"])) : tempData.questionType === '32' ? (_openBlock(), _createBlock(_component_templateUpload, {
      key: 4,
      listIndex: index + 1,
      dataInfos: tempData,
      isAdmin: $props.isAdmin,
      isView: $props.isView,
      status: $props.status,
      languageType: $props.languageType,
      statusName: $props.statusName,
      damageRTextL: $props.damageRTextL,
      sortTemplateData: $props.sortTemplateData
    }, null, 8, ["listIndex", "dataInfos", "isAdmin", "isView", "status", "languageType", "statusName", "damageRTextL", "sortTemplateData"])) : _createCommentVNode("", true)])]);
  }), 256);
}