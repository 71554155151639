export default {
  components: {},
  props: {
    status: {
      type: String,
      default: () => {
        return '';
      }
    },
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    languageType: {
      type: String,
      default: () => {
        return '';
      }
    },
    statusName: {
      type: String,
      default: () => {
        return '';
      }
    }
  },

  data() {
    return {
      dirTimer: null
    };
  },

  watch: {},
  computed: {},
  methods: {
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    }

  },

  created() {},

  mounted() {}

};