import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-32be84d8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  style: {
    "width": "90%",
    "margin-left": "5%"
  }
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "flex-wrap": "wrap",
    "flex-direction": "column"
  }
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0,
  style: {
    "margin": "20px 0 0 10px",
    "color": "#D9001B",
    "display": "flex"
  }
};
const _hoisted_6 = {
  style: {
    "width": "16%"
  }
};
const _hoisted_7 = {
  style: {
    "margin-left": "10px",
    "width": "80%",
    "word-wrap": "break-word"
  }
};
const _hoisted_8 = {
  key: 1,
  style: {
    "width": "90%",
    "margin-left": "5%"
  }
};
const _hoisted_9 = {
  style: {
    "display": "flex",
    "justify-content": "space-between"
  }
};
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  key: 0
};
const _hoisted_12 = {
  key: 1,
  style: {
    "color": "rgb(126,127,134)",
    "margin-left": "30px"
  }
};
const _hoisted_13 = {
  key: 1,
  style: {
    "margin": "20px 0 0 10px",
    "color": "#D9001B",
    "display": "flex"
  }
};
const _hoisted_14 = {
  style: {
    "width": "60px"
  }
};
const _hoisted_15 = {
  style: {
    "width": "80%",
    "word-wrap": "break-word"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tooltip = _resolveComponent("el-tooltip");

  const _component_el_image = _resolveComponent("el-image");

  const _component_templateStatusShow = _resolveComponent("templateStatusShow");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [($props.dpt.status == '1' || $props.dpt.status == '3') && !$props.userIsView ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass($data.dataInfo.isEssential ? 'star' : ''),
    style: {
      "font-weight": "bold",
      "margin-bottom": "10px",
      "font-size": "14px",
      "word-wrap": "break-word"
    }
  }, _toDisplayString($props.listIndex) + "." + _toDisplayString($data.dataInfo.title || '标题'), 3), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataInfo.itemList, (item, index) => {
    return _openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_tooltip, {
      class: "item",
      effect: "dark",
      content: item.optionName,
      placement: "top"
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass($props.isCheckBox ? [$data.selectsIndex[index] ? 'optionSelect' : 'option'] : [$data.selectIndex === item.id ? 'optionSelect' : 'option']),
        style: {
          "height": "22px",
          "line-height": "22px",
          "max-width": "100px",
          "overflow": "hidden",
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          "cursor": "pointer"
        },
        onClick: $event => $options.clickOption(index, item)
      }, _toDisplayString(item.optionName), 11, _hoisted_4)]),
      _: 2
    }, 1032, ["content"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.damageReportContentItemImageList, imgItems => {
      return _openBlock(), _createElementBlock(_Fragment, null, [imgItems.imageUrl ? (_openBlock(), _createBlock(_component_el_image, {
        key: 0,
        class: "avatarPreview m_0_5",
        src: $options.replacePath(imgItems.imageUrl),
        "preview-src-list": _ctx.imgArray,
        style: {},
        fit: "contain"
      }, null, 8, ["src", "preview-src-list"])) : _createCommentVNode("", true)], 64);
    }), 256))]);
  }), 256))]), $data.dataInfo.answerVo?.checkStatus === '1' && $props.dpt.status != '2' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString($props.damageRTextL.additionalRemarks) + ": ", 1), _createElementVNode("div", _hoisted_7, _toDisplayString($data.dataInfo.answerVo.checkRemark), 1)])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
    class: _normalizeClass($data.dataInfo.isEssential ? 'star' : ''),
    style: {
      "font-weight": "bold",
      "margin-bottom": "10px",
      "font-size": "14px",
      "word-wrap": "break-word"
    }
  }, _toDisplayString($props.listIndex) + "." + _toDisplayString($data.dataInfo.title || '标题'), 3), _createVNode(_component_templateStatusShow, {
    status: $props.dpt.status,
    dataInfo: $data.dataInfo,
    languageType: $props.dpt.languageType,
    dpt: $props.dpt
  }, null, 8, ["status", "dataInfo", "languageType", "dpt"])]), $props.dpt.status ? (_openBlock(), _createElementBlock("div", _hoisted_10, [$data.dataInfo.answerVo.contentItemId && $data.dataInfo.answerVo.contentItemId != 'null' ? (_openBlock(), _createElementBlock("div", _hoisted_11)) : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString($props.damageRTextL.userHasNotFilledOut), 1))])) : _createCommentVNode("", true), $data.dataInfo.answerVo?.checkStatus === '1' && $props.dpt.status != '2' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString($props.damageRTextL.additionalRemarks) + ": ", 1), _createElementVNode("div", _hoisted_15, _toDisplayString($data.dataInfo.answerVo.checkRemark), 1)])) : _createCommentVNode("", true)]))])]);
}