export default {
  components: {},
  props: {
    userAccount: {
      type: String,
      default: () => {
        return '';
      }
    },
    questionRecordId: {
      type: String,
      default: () => {
        return '';
      }
    },
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    templatePopFunction: {
      type: Object,
      default: () => {
        return {};
      }
    },
    nowChatUserRecordInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    changeTitle: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      // productIndex: 0,
      //
      loadingTextArray: [],
      refresh: false,
      //数据字典列表
      purchasingChannelsDir: [],
      warrantyTypeDir: [],
      tradeList: [],
      value: '',
      step: 1,
      radio: '1',
      form: {
        radio: '1',
        orderNum: '',
        deliveryWarehouseName: '',
        warehouseId: null,
        warrName: '',
        paymentTime: '',
        warrantyTypeName: '',
        productionId: '',
        porName: '',
        tradeId: ''
      },
      form2: {
        sop: "",
        damageReoprt: ""
      },
      orderNunList: [],
      warrantyTypeNameList: [{
        label: "在保质期内",
        value: "在保质期内"
      }, {
        label: "已过质保期",
        value: "已过质保期"
      }],
      sopTreeList: [],
      winProductList: [],
      allProductList: [],
      productList1: [],
      productList2: [],
      templateList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      options: [],
      value: '',
      rules: {
        radio: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        orderNum: [{
          required: true,
          message: '未查询到相关订单，无法进行下一步操作',
          trigger: 'blur'
        }],
        warrantyTypeName: [{
          required: true,
          message: '请选择质保情况',
          trigger: 'change'
        }],
        productionId: [{
          required: true,
          message: '请选择产品',
          trigger: 'change'
        }],
        sop: [{
          required: true,
          message: '请选择/输入SOP名称',
          trigger: 'change'
        }],
        damageReoprt: [{
          required: true,
          message: '请选择报损单',
          trigger: 'change'
        }]
      }
    };
  },

  watch: {
    productList1: {
      handler(nv, ov) {
        console.log(nv, 'dadasdasdasd');
      }

    },
    step: {
      handler(nv, ov) {
        if (nv == 2) {
          this.changeTitle('请根据以下顺序依次选择报损单');
        } else {
          this.changeTitle('选择基础信息');
        }
      }

    }
  },
  computed: {},
  methods: {
    getProblemPhenomenon(id) {
      for (let i of this.templateList) {
        if (i.id == id) {
          return i.problemPhenomenon;
        }
      }
    },

    initSopData(List) {
      for (let i of List) {
        if (i.children.length) {
          this.initSopData(i.children);
        } else {
          i.children = _.cloneDeep(i.sopQuestionList);

          for (let x of i.children) {
            x.name = x.title;
            x.isLeaf = true;
          }
        }
      }

      return List;
    },

    //时间戳转化为 年月日 时分秒 格式化
    dataTimeFormatter(timeStamp) {
      if (timeStamp === 'null' || !timeStamp) {
        return '';
      }

      timeStamp = Number(timeStamp);
      let time = new Date(timeStamp);
      let y = time.getFullYear(); // 年份

      let M = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      ; // 月份（注意月份是从0开始的，所以需要+1）

      let d = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate(); // 日期

      let h = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours(); // 小时

      let m = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes(); // 分钟

      let s = time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds(); // 秒数

      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },

    //点击质保情况
    warrChange(val) {
      this.form.warrName = val; // console.log(this.warrName);
    },

    //选取产品名称
    proChange(val, id) {
      //清除商品品名
      this.form.tradeId = '';
      this.form.porName = val;
      this.getProductionInfo(id);
    },

    //点击sop树 sopTreeChange
    sopTreeChange(val) {
      // debugger
      //先清空一次报损单
      this.form2.damageReoprt = ''; // console.log(val,'val');

      this.refresh = true;
      let params = {
        // id: val,
        productionId: this.form.productionId,
        sopId: val
      };
      this.getTempItemListBySopId(params).then(res => {
        this.templateList = res.data.data;
        this.refresh = false; // console.log(res.data.data, 'datadata');
      });
    },

    //根据sopId查询报损单模板列表
    async getTempItemListBySopId(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getTempItemListBySopId', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //递归清除sopList的children为'null'
    clearStringChildren(array) {
      for (let i in array) {
        if (array[i].children !== 'null') {
          this.clearStringChildren(array[i].children);
        } else {
          array[i].children = null;
        }
      }
    },

    //通过产品ID查询SOP列表
    async getSopListByProductionId(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getSopListByProductionId', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //根据账户获取订单基础信息[think-sales-management]
    async getOrderBasicInfo(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getOrderBasicInfo', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //[FAQ]获取产品BOM列表
    async getProductionBomList(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getProductionBomList', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //购买渠道选择改变的时候
    radioChanged(val) {
      // console.log(val,'val');
      this.form.tradeId = '';
      this.tradeList = [];

      if (val === '1') {
        this.form.productionId = '';
      } else if (val === '2') {
        this.refresh = true;
        this.form.productionId = '';
        this.getProductionBomList().then(res => {
          this.productList2 = res.data.data;
          this.refresh = false; // console.log(res, 'this.productList');
        });
      }
    },

    //选择订单编号时
    choseOrderNum(val) {
      //先清空产品名称 以及质保情况
      this.form.warrantyTypeName = '';
      this.form.productionId = ''; //清空一下商品信息

      this.tradeList = [];
      this.form.tradeId = ''; // this.form.productionName = ''

      this.productList1 = []; // console.log(val, 'val');

      for (let i in this.orderNunList) {
        // debugger
        // console.log(i,'iii');
        if (i == val) {
          // console.log(this.orderNunList[i]);
          this.form.area = this.orderNunList[i].area;
          this.form.deliveryWarehouseId = this.orderNunList[i].warehouseId;

          if (this.orderNunList[i].orderPayTimeStamp != "null") {
            this.form.paymentTime = this.dataTimeFormatter(this.orderNunList[i].orderPayTimeStamp);
          } else {
            this.form.paymentTime = this.orderNunList[i].orderPayTimeStamp;
          }

          this.form.deliveryWarehouseName = this.orderNunList[i].warehouseName;
          this.form.merchandiseName = this.orderNunList[i].goodName;
          this.productList1 = this.orderNunList[i].productionArray;
          break;
        }
      } // debugger
      // console.log(this.productList1, 'this.productList1')

    },

    //上一步
    preStep() {
      //清空一次sop 以及报损单
      if (this.step == 2) {
        this.form2.sop = '';
        this.form2.damageReoprt = '';
        this.templateList = [];
      }

      this.step--;
    },

    //下一步
    nextStep() {
      if (this.step === 1) {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.refresh = true;
            this.getSopListByProductionId({
              productionId: this.form.productionId
            }).then(res => {
              this.clearStringChildren(res.data.data);
              this.sopTreeList = _.cloneDeep(res.data.data); // this.sopNameList = _.cloneDeep(this.initSopData(res.data.data))

              this.refresh = false;
            });
            this.step++;
          } else {}
        });
      } else if (this.step === 2) {
        this.$refs.form2.validate(valid => {
          if (valid) {
            // debugger
            let data = {};

            if (this.form.radio === '2') {
              data.purchasingChannels = this.form.radio;
              data.questionRecordId = this.questionRecordId;
              data.tempItemId = this.form2.damageReoprt;
              data.account = this.userAccount;
              data.appealTime = new Date(this.nowChatUserRecordInfo.create_time).getTime();
              data.purchasingChannels = this.form.radio;
              data.productionId = this.form.productionId;
              data.sopId = this.form2.sop;
              data.personId = this.user.id;
              data.merchandiseName = '';
              data.productionName = this.form.porName;
              data.problemPhenomenon = this.getProblemPhenomenon(this.form2.damageReoprt);
              data.shopName = this.form.tradeId;
            } else {
              data.purchasingChannels = this.form.radio;
              data.questionRecordId = this.questionRecordId;
              data.tempItemId = this.form2.damageReoprt;
              data.area = this.form.area;
              data.account = this.userAccount;
              data.appealTime = new Date(this.nowChatUserRecordInfo.create_time).getTime();
              data.orderNum = this.form.orderNum;
              data.productionId = this.form.productionId;
              data.personId = this.user.id;
              data.sopId = this.form2.sop;
              data.warrantyType = this.form.warrantyTypeName;
              data.warrantyTypeName = this.form.warrName;
              data.deliveryWarehouseId = this.form.deliveryWarehouseId; // data.deliveryWarehouseName = this.form.deliveryWarehouseName

              data.paymentTime = this.form.paymentTime;
              data.merchandiseName = this.form.merchandiseName;
              data.productionName = this.form.porName;
              data.problemPhenomenon = this.getProblemPhenomenon(this.form2.damageReoprt);
              data.shopName = this.form.tradeId; // data.sopid = 
            } // debugger


            this.templatePopFunction.getTemplatePopData(data);
          } else {}
        });
      }
    },

    //取消
    cancel() {
      if (this.step === 1) {
        this.templatePopFunction.closeTemplatePop();
      } else if (this.step === 2) {
        //清空一次
        this.form2.sop = '';
        this.form2.damageReoprt = '';
        this.step--;
      }
    },

    //初始化回显数据
    initData() {
      let params = {
        account: this.userAccount
      };
      this.getOrderBasicInfo(params).then(res => {
        this.orderNunList = res.data.data;
        this.form.orderNum = Object.keys(this.orderNunList)[0];
        this.choseOrderNum(this.form.orderNum);
        this.refresh = false;
      });
    },

    //根据数据字典类型获取平台字典信息
    async getDictListByDictType(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getDictListByDictType', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //获取产品信息
    async getProductionInfo(id) {
      this.refresh = true;
      const params = {
        productionId: id
      };

      try {
        const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/getProductionInfo', params);

        if (res.data.code != 200) {
          ElNotification({
            title: '错误',
            message: res.data.message,
            type: 'error'
          });
          this.refresh = false;
        } else {
          this.tradeList = res.data.data.shopNames;
          this.refresh = false;
        }
      } catch (err) {
        ElNotification({
          title: '错误',
          message: err.message,
          type: 'error'
        });
        this.refresh = false;
      }
    }

  },

  created() {
    console.log(this.nowChatUserRecordInfo, 'nowChatUserRecordInfo');
    this.refresh = true;
    this.getDictListByDictType({
      dictType: 'faq_warranty_type'
    }).then(res => {
      this.warrantyTypeDir = res.data.data;
    });
    this.getDictListByDictType({
      dictType: 'faq_purchasing_channels'
    }).then(res => {
      this.purchasingChannelsDir = res.data.data;
    }); // 

    this.initData();
    console.log(this.nowChatUserRecordInfo, 'qweqweqw');
  },

  mounted() {}

};