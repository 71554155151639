export default {
  name: 'FilterPanel',
  components: {},
  // model: {
  //   prop: 'selected',
  //   event: 'change:selected'
  // },
  props: {
    title: {
      type: String,

      default() {
        return 'title';
      }

    },
    selected: {
      type: Array,

      default() {
        return [];
      }

    },
    selectedAll: {
      type: Boolean,

      default() {
        return false;
      }

    },
    options: {
      type: Array,

      default() {
        return [];
      }

    },
    label: {
      type: String,

      default() {
        return undefined;
      }

    },
    countKey: {
      // 数量关键字，默认为$count
      type: String,

      default() {
        return '$count';
      }

    },
    value: {
      type: String,

      default() {
        return undefined;
      }

    },
    disabled: {
      type: Boolean,

      default() {
        return false;
      }

    },
    valueKey: {
      type: String,

      default() {
        return 'id';
      }

    },
    multiple: {
      type: Boolean,

      default() {
        return true;
      }

    },
    colorEnable: {
      type: Boolean,

      default() {
        return false;
      }

    },
    colorKey: {
      type: String,

      default() {
        return 'color';
      }

    },
    isSaveSelected: {
      // 是否缓存勾选项
      type: Boolean,

      default() {
        return false;
      }

    }
  },

  data() {
    return {
      part: false,
      checkAll: false,
      checked: [],
      checkAllFirst: true
    };
  },

  computed: {},
  watch: {
    selected: {
      handler(nv, ov) {
        if (!_.isEqual(nv, ov)) {
          if (this.title != 'Product Status') {
            this.checked = _.cloneDeep(nv);
            this.changeChecked(this.checked);
          }
        }
      }

    },
    options: {
      handler: function (nv, ov) {
        if (nv.length < ov.length) {
          if (this.title != 'Product Status') {
            this.initNew();
          }
        } // this.init(true);

      }
    },
    checkAll: {
      handler(nv, ov) {
        this.$emit('checkAll', nv);
      },

      immediate: true
    }
  },

  mounted() {
    this.inintProductStatus(); // this.checked = _.cloneDeep(this.selected)
  },

  methods: {
    init(isEmitCheced) {
      const checked = [];

      for (const _selected of this.selected) {
        for (const _option of this.options) {
          if (_.isEqualWith(_selected, _option, (obj, other) => {
            if (this.value) {
              return obj === other[this.value];
            }

            if (_.isObject(obj) && _.isObject(other)) {
              // 对象
              return obj[this.valueKey] == other[this.valueKey];
            }

            return obj == other;
          })) {
            if (checked.indexOf(_option) < 0) {
              checked.push(_option);
            }
          }
        }
      }

      this.checked = checked;

      if (this.checked.length == 0) {
        this.checkAll = false;
        this.part = false;
      } else if (this.checked.length === this.options.length) {
        this.checkAll = true;
        this.part = false;
      } else {
        this.checkAll = false;
        this.part = true;
      }

      isEmitCheced && this.emitChecked();
    },

    initNew() {
      let newSelect = [];

      for (let i of this.selected) {
        for (let x of this.options) {
          if (JSON.parse(i).id == x.id) {
            newSelect.push(i);
            break;
          }
        }
      }

      this.checked = newSelect;
      this.emitChecked();
    },

    inintProductStatus() {
      if (this.title == 'Product Status') {
        this.checked = [];

        for (let i of this.options) {
          if (i.key == 1) {
            this.checked.push(JSON.stringify(i));
            this.changeChecked(this.checked);
            return;
          }
        }
      }
    },

    emitChecked() {
      let checked = this.checked; // if (this.value) {
      //   checked = _.map(this.checked, this.value);
      // }

      if (this.title == 'Product Status') {
        checked = _.map(this.checked.map(item => JSON.parse(item)), 'key');
        this.$emit('update:selected', checked);
      } else {
        this.$emit('update:selected', checked);
      }
    },

    changeCheckedAll(val) {
      if (val) {
        this.checked = [];

        for (let i of this.options) {
          this.checked.push(JSON.stringify(i));
        }
      } else {
        this.checked = [];
      }

      this.part = false;
      this.emitChecked();
    },

    changeChecked(val) {
      const _len = val.length;
      this.checkAll = _len === this.options.length && _len != 0;
      this.part = _len > 0 && _len < this.options.length;
      this.emitChecked();
    },

    changeRadio(val) {
      this.checked = [val];
      this.checkAll = false;
      this.emitChecked();
    }

  }
};