export default {
  components: {},
  props: {
    isCheckBox: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: null
    },
    dpt: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      //用户是否填写
      select: '',
      selects: [],
      imgArray: [],
      status: '',
      selectsIndex: [],
      selectIndex: null,
      // isEssential:2,
      isShow: false,
      dataInfo: {}
    };
  },

  watch: {// dataInfo:{
    //     handler(nvl){
    //         nvl= JSON.parse(JSON.stringify(nvl))
    //         clearTimeout(this.timer)
    //         this.timer=setTimeout(()=>{
    //             this.$event.emit('saveEditData',nvl,this.dpt.name,this.listIndex-1)
    //         },500)
    //     },
    //     deep:true
    // }
  },
  computed: {},

  created() {
    // console.log(this.dpt.tempItemContentList[this.listIndex - 1], 'this.d000000');
    this.dataInfo = JSON.parse(JSON.stringify(this.dpt.tempItemContentList[this.listIndex - 1]));

    if (this.dataInfo.isEssential) {
      this.dataInfo.isEssential = true;
    } else {
      this.dataInfo.isEssential = false;
    }

    this.dataInfo.checkList = [];
    this.dataInfo.radio = null;

    for (let i of this.dataInfo.itemList) {
      for (let imgItems of i.damageReportContentItemImageList) {
        this.imgArray.push(this.replacePath(imgItems.imageUrl));
      }
    } // if (!this.dataInfo.itemList.length) {
    //     let firstObj = {
    //         optionName: ''
    //     }
    //     this.dataInfo.itemList.push(firstObj)
    // }
    // if (this.dpt.status) {
    //     if (this.dataInfo.answerVo.contentItemId && this.dataInfo.answerVo.contentItemId != 'null') {
    //         if (this.isCheckBox) {
    //             let selList = JSON.parse(this.dataInfo.answerVo.contentItemId).split(';').slice(0, -1)
    //             for (let i of selList) {
    //                 for (let x in this.dataInfo.itemList) {
    //                     if (Number(i) === this.dataInfo.itemList[x].id) {
    //                         let obj = {
    //                             optionName: this.dataInfo.itemList[x].optionName
    //                         }
    //                         this.selects.push(obj)
    //                         break;
    //                     }
    //                 }
    //             }
    //             console.log(this.selectsIndex, 'selList111');
    //         } else {
    //             for (let i of this.dataInfo.itemList) {
    //                 if (this.dataInfo.answerVo.contentItemId === String(i.id)) {
    //                     this.select = i.optionName
    //                     return
    //                 }
    //             }
    //             console.log(this.selectIndex, 'selList222');
    //         }
    //     }
    // }
    // this.status = this.dpt.status

  },

  beforeUpdate() {// }
  },

  mounted() {},

  methods: {
    //根据状态返回不同的class
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    },

    replacePath(path) {
      return path.replace('/ipfs/', '/static/');
    }

  }
};